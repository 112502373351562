/* eslint-disable no-underscore-dangle */
import PQueue from 'p-queue'
import config from '../../config'
import { ApiError, RequestError } from '../../util/errors'

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const Api = {
  queue: new PQueue(),
  routes: [],
  baseUrl: process.env.REACT_APP_BACKEND_URL || window.location.origin,

  async request(route, additonalOptions = {}, data, responseType = 'json') {
    const options = {
      ...additonalOptions,
      headers: {
        ...defaultHeaders,
        ...additonalOptions.headers,
      },
      body: JSON.stringify(data),
    }

    const apiToken = config.getToken()
    if (apiToken) {
      options.headers.Authorization = `Bearer ${apiToken}`
    }

    let path = route
    if (route in this.routes) {
      path = this.route(route, options.params, options.query)
    }

    return this.queue.add(() =>
      fetch(path, options).then(
        async response => {
          const parsedResponse = await response[responseType]()

          if (response.status >= 400) {
            throw new ApiError(response.status, parsedResponse.message, parsedResponse)
          }

          return parsedResponse
        },
        error => {
          throw new RequestError(error.message, error)
        }
      )
    )
  },

  async getConfiguration(isRetry = false) {
    try {
      const frontendConfig = await this.request(`${this.baseUrl}/_config`)
      this.init(frontendConfig.data._links)
      config.init(frontendConfig.data.config)
      delete frontendConfig.data._links
      return frontendConfig.data
    } catch (ex) {
      if (ex.name === 'ApiError' && ex.status === 401) {
        config.reset()
      }

      if (isRetry === false) {
        return this.getConfiguration(true)
      }

      throw ex
    }
  },

  init(routeConfig) {
    this.routes = routeConfig
  },

  reset() {
    this.routes = {}
  },

  route(name, params = {}, query) {
    if (!this.routes || !this.routes[name]) throw new Error(`route ${name} not found`)

    let path = this.baseUrl + this.routes[name].href
    Object.keys(params).forEach(key => {
      path = path.replace(`:${key}`, params[key])
    })

    if (query) {
      if (query instanceof URLSearchParams) {
        path = `${path}?${query.toString()}`
      } else {
        const queryString = Object.keys(query)
          .map(key => `${key}=${encodeURIComponent(query[key])}`)
          .join('&')

        path = `${path}?${queryString}`
      }
    }

    return path
  },
}

const ApiHandler = {
  get(obj, prop) {
    if (prop in obj) {
      return obj[prop]
    }

    if (prop in obj.routes) {
      return (...args) => obj.request(prop, ...args)
    }

    return undefined
  },
}

export default new Proxy(Api, ApiHandler)
