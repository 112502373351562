import React from 'react'
import { Icon } from 'antd'

import styles from './index.module.css'

function OfflineOverlay() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <Icon type="loading" theme="outlined" />
        <h1>One Moment, reconnecting ...</h1>
      </div>
    </div>
  )
}

export default OfflineOverlay
