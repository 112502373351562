import React from 'react'
import PropTypes from 'prop-types'

const FormattedNumber = ({ number, fraction }) => (
  <span className="number">
    {number && Number(number).toLocaleString(undefined, { minimumFractionDigits: fraction })}
  </span>
)

FormattedNumber.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fraction: PropTypes.number,
}

FormattedNumber.defaultProps = {
  number: null,
  fraction: 0,
}

export default FormattedNumber
