import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import styles from './index.module.css'

const ConfirmationModal = ({ visible, message, handleCancel, onConfirm }) => {
  return (
    <Modal
      mask
      maskClosable
      closable
      visible={visible}
      className={styles.container}
      onCancel={handleCancel}
      width="35%"
      okText="Yes"
      okButtonProps={{ onClick: onConfirm, type: 'default', className: styles.yesButton }}
      cancelText="No"
      cancelButtonProps={{ onClick: handleCancel, className: styles.noButton }}
    >
      <div className={styles.content}>{message}</div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ConfirmationModal
