import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Breadcrumb, Icon } from 'antd'
import styles from './index.module.css'

function BreadcrumbComponent({ clientName, clientId, projectName, projectId }) {
  const path = window.location.pathname.split('/')
  path.splice(0, 1)

  // initial breadcrumb position is home & will always be displyed
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <Icon type="home" className={styles.icon} />
        HOME
      </Link>
    </Breadcrumb.Item>,
  ]

  // if the path is user
  if (path[0] === 'user') {
    const userList = (
      <Breadcrumb.Item key="users">
        <Link to="/user">USERS</Link>
      </Breadcrumb.Item>
    )
    breadcrumbItems.push(userList)
  }

  // if the path is client
  if (path[0] === 'client' || path[0] === 'project') {
    const clientList = (
      <Breadcrumb.Item key="clients">
        <Link to="/client">PROJECTS</Link>
      </Breadcrumb.Item>
    )
    breadcrumbItems.push(clientList)
  }

  // if there is a list of clients && location project list or actual project display clients name
  if (clientName && path.length > 1) {
    const clientProjects = (
      <Breadcrumb.Item key="clientProjects">
        <Link to={`/client/${clientId}/projects`}>{clientName.toUpperCase()}</Link>
      </Breadcrumb.Item>
    )
    breadcrumbItems.push(clientProjects)
  }

  // if there is a project selected, display its name in the breadcrumb
  if (projectName) {
    const currentProject = (
      <Breadcrumb.Item key="currentProject">
        <Link to={`/project/${projectId}/${path[path.length - 1]}`}>
          {projectName.toUpperCase()}
        </Link>
      </Breadcrumb.Item>
    )
    breadcrumbItems.push(currentProject)
  }

  return (
    <div className={styles.breadcrumb}>
      <Breadcrumb separator="/">{breadcrumbItems}</Breadcrumb>
    </div>
  )
}

BreadcrumbComponent.propTypes = {
  clientId: PropTypes.string,
  clientName: PropTypes.string,
  projectName: PropTypes.string,
  projectId: PropTypes.string,
}

BreadcrumbComponent.defaultProps = {
  clientId: '',
  clientName: '',
  projectName: '',
  projectId: '',
}

export default BreadcrumbComponent
