import PropTypes from 'prop-types'
import React from 'react'
import { Avatar as AntAvatar } from 'antd'

import StatusIndicator from '../StatusIndicator'
import styles from './index.module.css'

function Avatar({ src, username, showUsername, status, size, statusClassName, showHalo }) {
  return (
    <div className={styles.container}>
      {status && <StatusIndicator style={statusClassName} />}
      <AntAvatar src={src} className={styles.default} size={size}>
        {!src && username[0]}
      </AntAvatar>
      {showHalo && (
        <div className={styles.halo} style={{ width: `${size + 8}px`, height: `${size + 8}px` }} />
      )}
      {showUsername && (
        <span>
          &nbsp;&nbsp;
          <b>{username}</b>
        </span>
      )}
    </div>
  )
}

Avatar.propTypes = {
  username: PropTypes.string,
  showUsername: PropTypes.bool,
  status: PropTypes.bool,
  src: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusClassName: PropTypes.string,
  showHalo: PropTypes.bool,
}

Avatar.defaultProps = {
  username: '',
  showUsername: true,
  status: false,
  src: null,
  size: 'default',
  statusClassName: '',
  showHalo: false,
}

export default Avatar
