import { ActionTypes } from '../actions/influencerPool'
import { ActionTypes as InfluencerCategoriesActionTypes } from '../actions/influencerCategories'

const initialState = {}

export const influencerPool = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INFLUENCER_POOL_FETCH_DATA:
      return action.payload

    case InfluencerCategoriesActionTypes.INF_POOL_EDIT_INFLUENCER_CATEGORIES: {
      const { totalMatches, data } = state

      if (!data) return state

      const { userId, categories } = action.payload
      const idx = data.findIndex(user => user.userId === userId)
      const user = data[idx]
      user.categories = categories
      data.splice(idx, 1, user)
      return { totalMatches, data }
    }

    case InfluencerCategoriesActionTypes.INF_POOL_EDIT_BULK_INFLUENCER_CATEGORIES: {
      const { totalMatches, data } = state

      if (!data) return state

      action.payload.forEach(item => {
        const idx = data.findIndex(user => user.userId === item.userId)
        const user = data[idx]
        user.categories = item.categories
        data.splice(idx, 1, user)
      })

      return { totalMatches, data }
    }

    case ActionTypes.INFLUENCER_POOL_CLEAR:
      return {}

    default:
      return state
  }
}
