import { ActionTypes } from '../actions/selectedProject'

const initialState = {}
export const projectCriteria = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CRITERIA_ADDED:
    case ActionTypes.SELECT_PROJECT: {
      const criterias = {}
      const criteriaList = action.payload.criterias ? action.payload.criterias : action.payload

      criteriaList.forEach(item => {
        const name = item.criteria
        criterias[name] = { ...item }

        if (name.startsWith('AUDIENCE_')) {
          criterias[name].value1 = Number(item.value1) || 0
        } else if (name.startsWith('FOLLOWER_')) {
          const start = item.range[0].toLocaleString(undefined)
          const end = (item.range[1] + 1).toLocaleString(undefined)
          let label = `${start} - ${end} Followers`
          if (item.range[0] === 0) label = `< ${end} Followers`
          else if (item.range[1] === Number.MAX_SAFE_INTEGER) label = `> ${start} Followers`

          criterias[name].label = label
          criterias[name].value1 = Number(item.value1) || 0
          criterias[name].value2 = Number(item.value2) || 0

          criterias[name].category = item.criteria
          criterias[name].score = criterias[name].value1
        }
      })

      return criterias
    }
    case ActionTypes.DESELECT_PROJECT:
      return { ...initialState }
    default:
      return state
  }
}
