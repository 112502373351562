import { connect } from 'react-redux'
import CombinedDeepdiveDrawer from './index'

const mapStateToProps = ({ finalListCombinedDeepdive }) => ({
  deepdive: finalListCombinedDeepdive,
})

export default connect(
  mapStateToProps,
  null
)(CombinedDeepdiveDrawer)
