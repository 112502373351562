export const ActionTypes = {
  NETWORK_OFFLINE: 'NETWORK_OFFLINE',
  NETWORK_ONLINE: 'NETWORK_ONLINE',
  NETWORK_REQUEST: 'NETWORK_REQUEST',
  NETWORK_SUCCESS: 'NETWORK_SUCCESS',
  NETWORK_FAILURE: 'NETWORK_FAILURE',
}

export const setNetworkState = state => ({
  type: state ? ActionTypes.NETWORK_ONLINE : ActionTypes.NETWORK_OFFLINE,
})
