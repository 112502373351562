export const ActionTypes = {
  INFLUENCER_POOL_FETCH_DATA: 'INFLUENCER_POOL_FETCH_DATA',
  INFLUENCER_POOL_CLEAR: 'INFLUENCER_POOL_CLEAR',
}

export const fetchInfluencerPool = filtersQuery => async dispatch =>
  dispatch({
    API: {
      endpoint: 'influencerPool',
      type: ActionTypes.INFLUENCER_POOL_FETCH_DATA,
      query: filtersQuery,
    },
  })

export const addUsersToFinalList = ({ usernames, projectId }) => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'addUsersManually',
      method: 'POST',
      payload: usernames,
      params: { id: projectId || getState().selectedProject.id },
    },
  })

export const clearInfluencerPoolState = () => async dispatch =>
  dispatch({
    type: ActionTypes.INFLUENCER_POOL_CLEAR,
  })
