import * as Sentry from '@sentry/browser'
import { initializeConfiguration } from './app'

export const ActionTypes = {
  AUTHENTICATION_LOGIN: 'AUTHENTICATION_LOGIN',
  AUTHENTICATION_LOGOUT: 'AUTHENTICATION_LOGOUT',
  AUTHENTICATION_METADATA: 'AUTHENTICATION_METADATA',
}

export const tryLogin = (username, password) => async (dispatch, getState, { config }) => {
  const { payload, error } = await dispatch({
    API: {
      endpoint: 'authLogin',
      method: 'POST',
      payload: { username, password },
    },
  })

  if (error) {
    throw payload
  }

  config.setToken(payload.token)
  dispatch(initializeConfiguration())
}

export const tryGoogle = () => (dispatch, getState, { api }) => {
  window.location = api.route('authGoogle')
}

export const login = (user, ignoreInitialization) => ({
  type: ActionTypes.AUTHENTICATION_LOGIN,
  payload: user,
  ignoreInitialization,
})

export const logout = () => (dispatch, getState, { config, api }) => {
  config.reset()
  api.reset()
  dispatch({ type: ActionTypes.AUTHENTICATION_LOGOUT })
  dispatch(initializeConfiguration())
}

export const setupSentry = () => (dispatch, getState) => {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: getState().authentication.id,
      host: window.location.host,
      platform: typeof navigator === 'undefined' ? 'Unknown' : navigator.platform,
    })
  })
}

export const initializeAuthentication = (user, ignoreInitialization = false) => dispatch => {
  dispatch(login(user, ignoreInitialization))
  dispatch(setupSentry())
}

export const setUserMetadata = metadata => async dispatch =>
  dispatch({
    API: {
      endpoint: '',
      method: 'POST',
      payload: metadata,
      type: ActionTypes.AUTHENTICATION_METADATA,
    },
  })
