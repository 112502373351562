import React from 'react'
import PropTypes from 'prop-types'

import api from '../../../services/api'

import { TIME_INTERVAL, TIME_LIMIT_INACTIVE } from '../../../constants/timeTracking'

class UserTimeLog extends React.PureComponent {
  state = { timeIntervalId: null, timeMounted: null, timeInactive: null }

  async componentDidMount() {
    this.startTracking()
    window.onfocus = this.startTracking
    window.onblur = this.startInactivity
  }

  async componentWillUnmount() {
    this.stopTracking(true)
    window.onfocus = null
    window.onblur = null
  }

  startTracking = () => {
    const { timeIntervalId, timeInactive } = this.state

    if (!timeIntervalId) {
      const timeInterval = setInterval(this.userTimeTracking, TIME_INTERVAL)
      this.setState({ timeIntervalId: timeInterval, timeMounted: new Date() })
    }

    if (timeInactive) {
      this.setState({ timeInactive: null })
    }
  }

  stopTracking = (unmounting = false) => {
    clearInterval(this.state.timeIntervalId)
    this.saveTimeLog()

    if (!unmounting) {
      this.setState({ timeIntervalId: null, timeMounted: null })
    }
  }

  startInactivity = () => {
    this.setState({ timeInactive: new Date() })
  }

  getInactiveTime = () => {
    if (this.state.timeInactive) {
      return new Date() - this.state.timeInactive
    }

    return 0
  }

  userTimeTracking = () => {
    const idleTime = this.getInactiveTime()

    if (!document.hasFocus() && idleTime === 0) {
      this.startInactivity()
    }

    if (idleTime < TIME_LIMIT_INACTIVE) {
      this.saveTimeLog()
      this.setState({ timeMounted: new Date() })
    } else {
      this.stopTracking()
    }
  }

  saveTimeLog = async () => {
    const { match, pageName } = this.props
    const timeSinceMount = new Date() - this.state.timeMounted
    const timeTrackingData = {
      projectId: match.params.projectId || null,
      timeAdded: timeSinceMount > TIME_INTERVAL ? TIME_INTERVAL : timeSinceMount,
      pageName,
    }

    try {
      await api.logEdit({ method: 'PUT' }, timeTrackingData)
    } catch (e) {
      // well
    }
  }

  render() {
    return <></>
  }
}

UserTimeLog.propTypes = {
  pageName: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
}
export default UserTimeLog
