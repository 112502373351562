export const ActionTypes = {
  USER_ADD: 'USER_ADD',
  USER_CREATE: 'USER_CREATE',
  USER_EDIT: 'USER_EDIT',
  USER_DELETE: 'USER_DELETE',
}

export const loadUsers = () => async dispatch =>
  dispatch({
    API: {
      endpoint: 'userList',
      type: ActionTypes.USER_ADD,
    },
  })

export const loadUser = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'userDetail',
      params: { userId: id },
      type: ActionTypes.USER_ADD,
    },
  })

export const createUser = user => async dispatch =>
  dispatch({
    API: {
      endpoint: 'userCreate',
      method: 'POST',
      payload: user,
      type: ActionTypes.USER_CREATE,
    },
  })

export const editUser = user => async dispatch =>
  dispatch({
    API: {
      endpoint: 'userEdit',
      method: 'PUT',
      payload: user,
      params: { id: user.id },
      type: ActionTypes.USER_EDIT,
    },
  })

export const deleteUser = userId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'userDelete',
      method: 'DELETE',
      params: { id: userId },
      type: ActionTypes.USER_DELETE,
    },
  })
