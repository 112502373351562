export const ActionTypes = {
  FINAL_LIST_EDIT_INFLUENCER_CATEGORIES: 'FINAL_LIST_EDIT_INFLUENCER_CATEGORIES',
  FINAL_LIST_EDIT_BULK_INFLUENCER_CATEGORIES: 'FINAL_LIST_EDIT_BULK_INFLUENCER_CATEGORIES',
  INF_POOL_EDIT_BULK_INFLUENCER_CATEGORIES: 'INF_POOL_EDIT_BULK_INFLUENCER_CATEGORIES',
  INF_POOL_EDIT_INFLUENCER_CATEGORIES: 'INF_POOL_EDIT_INFLUENCER_CATEGORIES',
}

export const editInfluencerCategoriesInFinalList = payload => async dispatch =>
  dispatch({
    API: {
      endpoint: 'editInfluencerCategories',
      method: 'POST',
      payload,
      type: ActionTypes.FINAL_LIST_EDIT_INFLUENCER_CATEGORIES,
    },
  })

export const editInfluencerCategoriesInfPool = payload => async dispatch =>
  dispatch({
    API: {
      endpoint: 'editInfluencerCategories',
      method: 'POST',
      payload,
      type: ActionTypes.INF_POOL_EDIT_INFLUENCER_CATEGORIES,
    },
  })

export const editBulkCategoriesInFinalList = payload => async dispatch =>
  dispatch({
    API: {
      endpoint: 'editCategoriesForManyUsers',
      method: 'POST',
      payload,
      type: ActionTypes.FINAL_LIST_EDIT_BULK_INFLUENCER_CATEGORIES,
    },
  })

export const editBulkCategoriesInfPool = payload => async dispatch =>
  dispatch({
    API: {
      endpoint: 'editCategoriesForManyUsers',
      method: 'POST',
      payload,
      type: ActionTypes.INF_POOL_EDIT_BULK_INFLUENCER_CATEGORIES,
    },
  })
