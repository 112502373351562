import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import styles from './index.module.css'

const UnderlinedTitle = ({ title, type, className }) => (
  <div className={classNames(styles.header, styles[type], className)}>{title}</div>
)

UnderlinedTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
}

UnderlinedTitle.defaultProps = {
  type: null,
  className: '',
}

export default UnderlinedTitle
