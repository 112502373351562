import { connect } from 'react-redux'

import { fetchCustomerList } from '../../../actions/customerFinalList'
import { editProject } from '../../../actions/project'
import { prepFollowerLabels } from '../../../util/followerGroupsHelpers'
import {
  updateCustomerList,
  deleteAccount,
  clearCustomerListState,
  editFinalListRecord,
} from '../../../actions/customerList'
import {
  clearCombinedDeepdiveState,
  getCombinedInstagramDeepdive,
  getCombinedTiktokDeepdive,
} from '../../../actions/combinedDeepdive'
import {
  fetchTiktokCustomerAccounts,
  updateTiktokCustomerListAccount,
} from '../../../actions/customerListTiktok'
import CustomerListPage from './CustomerListPage'

const mapStateToProps = state => ({
  selectedProject: state.selectedProject,
  followerCriteria: prepFollowerLabels(state.projectCriteria),
  customerList: state.customerList,
  tiktokCustomerList: state.tiktokCustomerList,
  ListStatus: state.customerListStatus,
  roles: state.authentication.roles,
  user: state.authentication.id,
})

const mapDispatchToProps = dispatch => ({
  clearState: () => dispatch(clearCustomerListState()),
  getCustomerList: projectId => dispatch(fetchCustomerList(projectId)),
  updateCustomerList: record => dispatch(updateCustomerList(record)),
  deleteFinalAccount: accountId => dispatch(deleteAccount(accountId)),
  updateSourcesDisplay: data => dispatch(editProject(data)),
  editFinalListRecord: data => dispatch(editFinalListRecord(data)),
  fetchTiktokCustomerAccounts: projectId => dispatch(fetchTiktokCustomerAccounts(projectId)),
  getCombinedTiktokDeepdive: data => dispatch(getCombinedTiktokDeepdive(data)),
  getCombinedInstagramDeepdive: userIds => dispatch(getCombinedInstagramDeepdive(userIds)),
  clearCombinedDeepdiveState: () => dispatch(clearCombinedDeepdiveState()),
  updateTiktokCustomerListAccount: data => dispatch(updateTiktokCustomerListAccount(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerListPage)
