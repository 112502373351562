/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form, Tooltip, notification, Radio, Modal, Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { CSVLink } from 'react-csv'
import { Beforeunload } from 'react-beforeunload'

import { getGroupsCountArray } from '../../../util/followerGroupsHelpers'
import UserDetailsDrawer from '../../../components/UserDetailsDrawer'
import CombinedDeepdiveDrawer from '../../../components/CombinedDeepdiveDrawer/container'
import CustomerListTable from '../../../components/CustomerListTable'
import CustomerLink from '../../../components/CustomerLink'
import LoadingMessage from '../../../components/base/LoadingMessage/index'
import FollowerGroups from '../../../components/FollowerGroups'
import { CheckmarkGroup } from '../../../components/base/CheckmarkGroup'
import BorderedButton from '../../../components/base/buttons/BorderedButton'
import columns from './customerListCols'
import { customerListHeaders } from '../../../constants/CsvHeaders'

import styles from './CustomerListPage.module.css'
import downloadIcon from '../../../assets/images/download.svg'
import prepareFinalList from '../../../util/prepareFinalList'

const filterCustomerList = ({ accounts, checkedVotes }) => {
  const filteredList = accounts
    ? accounts.filter(account => {
        const accountDecision = account.decision || 'UNDECIDED'
        return checkedVotes.includes(accountDecision)
      })
    : []
  return filteredList
}

export const PLATFORM_TYPE = {
  tiktok: 'tiktok',
  instagram: 'instagram',
}

const PLATFORM_NAME = {
  tiktok: 'TikTok',
  instagram: 'Instagram',
}

class CustomerListPage extends React.PureComponent {
  state = {
    instagram: { checkedVotes: ['YES', 'NO', 'UNDECIDED'] },
    tiktok: { checkedVotes: ['YES', 'NO', 'UNDECIDED'] },
    currentList: [],
    currentRecord: {},
    loaded: false,
    showUserDetails: false,
    accountDisplayTime: null,
    savingDecision: false,
    commentIsDirty: false,
    showSources: false,
    confirmSourcesChange: false,
    showCombinedDeepdive: false,
    selectedPlatform: PLATFORM_TYPE.instagram,
    supportedPlatforms: [],
  }

  async componentDidMount() {
    const {
      selectedProject: { id, showSources },
      getCustomerList,
      fetchTiktokCustomerAccounts,
    } = this.props

    await getCustomerList(id)
    await fetchTiktokCustomerAccounts(id)
    this.setState({ showSources })
    document.addEventListener('keydown', this.scrollTableRecords)

    this.setState({ loaded: true })
  }

  componentDidUpdate() {
    const { accountDisplayTime } = this.state
    if (!accountDisplayTime) {
      const displayTime = new Date()
      this.setState({ accountDisplayTime: displayTime, savingDecision: false }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  async componentWillUnmount() {
    const { clearState, clearCombinedDeepdiveState } = this.props
    await clearCombinedDeepdiveState()
    clearState()
    document.removeEventListener('keydown', this.scrollTableRecords)
  }

  static getDerivedStateFromProps(props, stateObject) {
    const { customerList, tiktokCustomerList } = props
    const {
      loaded,
      instagram,
      tiktok,
      showCombinedDeepdive,
      showUserDetails,
      currentRecord,
      showSources,
      selectedPlatform,
    } = stateObject
    const newState = {
      loaded,
      instagram,
      tiktok,
      showCombinedDeepdive,
      showUserDetails,
      currentRecord,
      currentList: [],
      showSources,
    }

    newState.supportedPlatforms = [
      ...(customerList.length > 0 ? [PLATFORM_TYPE.instagram] : []),
      ...(tiktokCustomerList.length > 0 ? [PLATFORM_TYPE.tiktok] : []),
    ]

    if (newState.supportedPlatforms.length === 1) {
      const [selection] = newState.supportedPlatforms
      newState.selectedPlatform = selection
    }

    const platformList = {
      [PLATFORM_TYPE.instagram]: customerList,
      [PLATFORM_TYPE.tiktok]: tiktokCustomerList,
    }[selectedPlatform]

    if (platformList.length !== 0) {
      const preppedList = prepareFinalList(platformList, selectedPlatform)
      const filteredList = filterCustomerList({
        accounts: preppedList,
        ...stateObject[selectedPlatform],
      })
      newState.currentList = [...filteredList]
    }
    return { ...newState }
  }

  getCurrentCustomerList = () => {
    switch (this.state.selectedPlatform) {
      case PLATFORM_TYPE.instagram:
        return this.props.customerList
      case PLATFORM_TYPE.tiktok:
        return this.props.tiktokCustomerList
      default:
        return []
    }
  }

  updateVoteFilters = checkedVotesRaw => {
    const { selectedPlatform } = this.state
    const checkedVotes = checkedVotesRaw.length === 0 ? ['YES', 'NO', 'UNDECIDED'] : checkedVotesRaw

    this.setState(() => ({
      [selectedPlatform]: { checkedVotes },
    }))
  }

  closeDrawer = drawerType => {
    this.setState({
      [drawerType]: false,
    })
  }

  showDrawer = drawerType => {
    this.setState({
      [drawerType]: true,
    })
  }

  showUserDetailsDrawer = record => {
    this.setState({
      currentRecord: record,
    })
    this.showDrawer('showUserDetails')
    this.closeDrawer('showCombinedDeepdive')
  }

  closeUserDetailsDrawer = () => {
    this.setState({
      currentRecord: {},
    })
    this.closeDrawer('showUserDetails')
  }

  closeCombinedDeepdiveDrawer = () => {
    this.setState({
      currentRecord: {},
    })
    this.closeDrawer('showCombinedDeepdive')
  }

  showCombinedDeepdive = () => {
    this.showDrawer('showCombinedDeepdive')
    this.closeDrawer('showUserDetails')
  }

  calculateContainerWidth = () => {
    const { showUserDetails, showCombinedDeepdive } = this.state
    if (showUserDetails || showCombinedDeepdive) return '66.5%'
    return '100%'
  }

  commentChanged = () => {
    this.setState({ commentIsDirty: true })
  }

  saveComment = async (row, e, commentType) => {
    e.preventDefault()
    const { commentIsDirty } = this.state
    if (commentIsDirty) {
      const { editFinalListRecord, user, updateTiktokCustomerListAccount } = this.props
      const { value } = e.target

      const data = {
        field: commentType,
        value: { text: value, writtenBy: user },
        userId: row.userId,
      }

      try {
        if (this.isInstagram()) await editFinalListRecord(data)
        if (this.isTiktok()) {
          await updateTiktokCustomerListAccount(data)
        }
        notification.success({
          message: 'Comment was saved!',
          placement: 'topRight',
        })
        this.setState({ commentIsDirty: false })
      } catch (error) {
        notification.warning({
          className: styles.notification,
          message: <strong>Error occured, comment might not have been saved</strong>,
          placement: 'topRight',
        })
      }
    }
  }

  saveTopPick = async (row, e) => {
    e.preventDefault()
    const { editFinalListRecord, updateTiktokCustomerListAccount } = this.props
    const data = { field: 'topPick', value: !row.topPick, userId: row.userId }
    if (this.isInstagram()) await editFinalListRecord(data)
    if (this.isTiktok()) await updateTiktokCustomerListAccount(data)
  }

  finalCall = async (decision, row, e) => {
    if (e) {
      e.preventDefault()
      const { updateCustomerList, updateTiktokCustomerListAccount } = this.props
      const { accountDisplayTime } = this.state
      const decisionMadeTime = new Date()
      const decisionTime = decisionMadeTime - accountDisplayTime
      try {
        if (this.isInstagram()) {
          updateCustomerList({
            userId: row.userId,
            status: decision,
            round: 'customer',
            decisionTime,
          })
        }
        if (this.isTiktok()) {
          await updateTiktokCustomerListAccount({
            field: 'clientDecision',
            value: decision,
            userId: row.userId,
          })
        }
        notification.success({
          message: 'Decision was saved!',
          placement: 'topRight',
        })
      } catch (error) {
        notification.warning({
          className: styles.notification,
          message: <strong>Error occured. Decision might not have been saved</strong>,
          placement: 'topRight',
        })
      } finally {
        this.setState({ accountDisplayTime: null, savingDecision: true })
      }
    }
  }

  // Updates YES/NO/UNDECIDED totals
  calculateVoteTypes = () => {
    const voteCounts = { YES: 0, NO: 0, UNDECIDED: 0 }
    const customerList = this.getCurrentCustomerList()
    customerList.forEach(account => {
      const accountDecision = account.decision ? account.decision : 'UNDECIDED'
      voteCounts[accountDecision] += 1
    })
    return voteCounts
  }

  // on key press down and up scroll through table records
  scrollTableRecords = e => {
    const { currentList, showUserDetails } = this.state
    if (showUserDetails) {
      const { currentRecord } = this.state
      const recordIndex = currentList.indexOf(currentRecord)
      if (e.keyCode === 40 && recordIndex !== currentList.length - 1) {
        this.setState({ currentRecord: currentList[recordIndex + 1] })
      } else if (e.keyCode === 38 && recordIndex !== 0) {
        this.setState({ currentRecord: currentList[recordIndex - 1] })
      }
    }
  }

  sourceDisplay = async () => {
    const { showSources: prevShowSources } = this.state
    const {
      updateSourcesDisplay,
      selectedProject: { id },
    } = this.props
    const data = await updateSourcesDisplay({ id, showSources: !prevShowSources })
    const { showSources } = data.payload

    this.setState({ showSources, confirmSourcesChange: false })
  }

  confirmSourcesDisplay = () => {
    const { showSources } = this.state
    if (!showSources) this.setState({ confirmSourcesChange: true })
  }

  handleCancelSourceChange = () => {
    this.setState({ confirmSourcesChange: false })
  }

  getFinalListDeepdive = async () => {
    const { getCombinedInstagramDeepdive, getCombinedTiktokDeepdive } = this.props
    const { currentList } = this.state
    const userIds = currentList.map(user => user.userId)
    try {
      let data

      if (this.isInstagram()) {
        data = await getCombinedInstagramDeepdive(userIds)
      }

      if (this.isTiktok()) {
        data = await getCombinedTiktokDeepdive(userIds)
      }
      const { totalFollowers } = data.payload

      if (totalFollowers) {
        this.showCombinedDeepdive('showCombinedDeepdive')
      } else {
        notification.warning({
          className: styles.notification,
          message: <strong>User List is empty</strong>,
          placement: 'topRight',
        })
      }
    } catch (ex) {
      notification.warning({
        className: styles.notification,
        message: <strong>An error happened while calculating Combined Deepdive</strong>,
        description: (
          <div>
            <code style={{ display: 'block', padding: '20px 0' }}>{ex.message}</code>
          </div>
        ),
        placement: 'topRight',
      })
    }
  }

  getPlatformButtonType = platform => {
    return this.isSelectedPlatform(platform) ? 'primary' : 'default'
  }

  isSelectedPlatform = platform => {
    return this.state.selectedPlatform === platform
  }

  selectPlatform = async selectedPlatform => {
    const { clearCombinedDeepdiveState } = this.props

    await clearCombinedDeepdiveState()
    this.closeUserDetailsDrawer()
    this.closeCombinedDeepdiveDrawer()
    this.setState({ selectedPlatform })
  }

  isInstagram = () => {
    const { selectedPlatform } = this.state
    return selectedPlatform === PLATFORM_TYPE.instagram
  }

  isTiktok = () => {
    const { selectedPlatform } = this.state
    return selectedPlatform === PLATFORM_TYPE.tiktok
  }

  render() {
    const { loaded } = this.state
    if (!loaded) {
      return (
        <div className={styles.loading}>
          <LoadingMessage
            fullscreen
            text="Fetching info for you from the database. Hang on... "
            icon="loading"
          />
        </div>
      )
    }
    const { customerListComoView, selectedProject, followerCriteria, roles } = this.props
    const {
      currentList,
      currentRecord,
      showUserDetails,
      savingDecision,
      commentIsDirty,
      showSources,
      confirmSourcesChange,
      showCombinedDeepdive,
      supportedPlatforms,
      selectedPlatform,
    } = this.state

    const containerWidth = this.calculateContainerWidth()

    const userIsCustomer = roles.indexOf('ROLE_GUEST') !== -1
    const decisionFilters = [
      { label: 'YES', value: 'YES' },
      { label: 'NO', value: 'NO' },
      { label: 'TO DO', value: 'UNDECIDED' },
    ]

    const cols = [
      ...columns({
        customerListComoView,
        drawerOpen: showUserDetails,
        finalCall: this.finalCall,
        saveComment: this.saveComment,
        savingDecision,
        saveTopPick: this.saveTopPick,
        commentChanged: this.commentChanged,
        selectedPlatform,
      }),
    ]
    const projectName = selectedProject.name.toLowerCase().replace(/\./g, '')
    const groupsCountArr = getGroupsCountArray(followerCriteria, currentList)
    const customerListFilterTitle = 'Final Call'
    return (
      <Fragment>
        {commentIsDirty && <Beforeunload onBeforeunload={event => event.preventDefault()} />}

        <div>
          <div className={styles.headerTableContainer} style={{ width: containerWidth }}>
            <div className={styles.pageTop}>
              <div className={styles.headings}>
                <div className={styles.header}>
                  {selectedProject.name}&nbsp;-&nbsp;
                  {'Customer List'}
                </div>
                <div className={styles.subHeader}>These are your top influencers</div>
                <div className={styles.platformToggle}>
                  <ButtonGroup size='"large"'>
                    {supportedPlatforms.map(platform => (
                      <Button
                        key={platform}
                        type={this.getPlatformButtonType(PLATFORM_TYPE[platform])}
                        onClick={() => this.selectPlatform(PLATFORM_TYPE[platform])}
                      >
                        {PLATFORM_NAME[platform]}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
              <div className={styles.dashboardContainer}>
                {customerListComoView ? (
                  <CustomerLink
                    link={selectedProject.customerLink}
                    className={styles.bigPinkButton}
                  />
                ) : null}

                <Form>
                  <div
                    className={classNames(
                      styles.userConsole,
                      !customerListComoView && styles.rightMargin
                    )}
                  >
                    <div className={styles.checkboxContainer}>
                      <h4 className={styles.filtersHeader}>{customerListFilterTitle}</h4>
                      <h4 className={styles.filtersHeader}>Filters</h4>
                      <CheckmarkGroup
                        groupClassName={styles.checkmarksDecisions}
                        options={decisionFilters}
                        onChange={this.updateVoteFilters}
                      />
                    </div>
                    <div className={styles.decisionsTable}>
                      <p className={styles.yesVotes}>{String(this.calculateVoteTypes().YES)}</p>
                      <p className={styles.noVotes}>{String(this.calculateVoteTypes().NO)}</p>
                      <p>{String(this.calculateVoteTypes().UNDECIDED)}</p>
                    </div>
                  </div>
                </Form>
                {customerListComoView && (
                  <div className={styles.radioBtnContainer}>
                    <h4 className={styles.filtersHeader}>
                      Display
                      <br />
                      Influencer Sources
                    </h4>
                    <Radio.Group
                      onChange={!showSources ? this.confirmSourcesDisplay : this.sourceDisplay}
                      value={showSources}
                    >
                      <div className={styles.radioField}>
                        <div>YES</div>
                        <div className={styles.radioContainer}>
                          <Radio className={styles.radioButton} value />
                          {showSources && <div className={styles.radioPoint} />}
                        </div>
                      </div>
                      <div className={styles.radioField}>
                        <div>NO</div>
                        <div className={styles.radioContainer}>
                          <Radio className={styles.radioButton} value={false} />
                          {!showSources && <div className={styles.radioPoint} />}
                        </div>
                      </div>
                    </Radio.Group>
                    <Modal
                      title="Display sources"
                      visible={confirmSourcesChange}
                      onOk={this.sourceDisplay}
                      onCancel={this.handleCancelSourceChange}
                      cancelText="NO"
                      okText="YES"
                    >
                      <p>
                        Are you sure you want to display all influencer sources in the Customer
                        Link?
                      </p>
                    </Modal>
                  </div>
                )}
                <div className={styles.buttonsContainer}>
                  <div className={styles.buttonsSubcontainer}>
                    <div className={styles.csvFollowerContainer}>
                      <FollowerGroups groupsCountArr={groupsCountArr} />
                      <CSVLink
                        headers={customerListHeaders}
                        data={currentList}
                        filename={`${projectName}_final_list.csv`}
                      >
                        <Tooltip
                          arrowPointAtCenter
                          placement="bottomRight"
                          title="Download as .csv"
                          overlayClassName={styles.tooltip}
                          autoAdjustOverflow
                          mouseEnterDelay={0.2}
                          mouseLeaveDelay={0.2}
                        >
                          <img
                            text="Download"
                            src={downloadIcon}
                            alt="download icon"
                            className={styles.downloadIcon}
                          />
                        </Tooltip>
                      </CSVLink>
                    </div>
                    <Tooltip
                      placement="bottomRight"
                      title="View combined details & audience insights of list"
                      mouseEnterDelay={0.2}
                      mouseLeaveDelay={0.2}
                    >
                      <div>
                        <BorderedButton
                          onClick={() => this.getFinalListDeepdive()}
                          className={styles.detailsBtn}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <CustomerListTable
                cols={cols}
                filteredList={currentList}
                scroll={{
                  y: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 350,
                }}
                showDrawer={this.showUserDetailsDrawer}
                currentRecord={currentRecord}
                closeDrawer={this.closeUserDetailsDrawer}
                userIsCustomer={userIsCustomer}
              />
            </div>
          </div>
          <UserDetailsDrawer
            showSourcesToCustomer={showSources}
            visibleDrawer={showUserDetails}
            currentRecord={currentRecord}
            onClose={this.closeUserDetailsDrawer}
            userIsCustomer={userIsCustomer}
            customerList
            customerListComoView={customerListComoView}
          />
          <CombinedDeepdiveDrawer
            visibleDrawer={showCombinedDeepdive}
            onClose={this.closeCombinedDeepdiveDrawer}
          />
        </div>
      </Fragment>
    )
  }
}

CustomerListPage.propTypes = {
  updateSourcesDisplay: PropTypes.func.isRequired,
  fetchTiktokCustomerAccounts: PropTypes.func.isRequired,
  updateTiktokCustomerListAccount: PropTypes.func.isRequired,
  clearCombinedDeepdiveState: PropTypes.func.isRequired,
  getCombinedInstagramDeepdive: PropTypes.func.isRequired,
  getCombinedTiktokDeepdive: PropTypes.func.isRequired,
  editFinalListRecord: PropTypes.func.isRequired,
  customerListComoView: PropTypes.bool,
  customerList: PropTypes.array.isRequired,
  tiktokCustomerList: PropTypes.array.isRequired,
  clearState: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
  updateCustomerList: PropTypes.func,
  getCustomerList: PropTypes.func,
  followerCriteria: PropTypes.array,
  roles: PropTypes.array.isRequired,
  user: PropTypes.string.isRequired,
}

CustomerListPage.defaultProps = {
  followerCriteria: [],
  customerListComoView: false,
  selectedProject: null,
  updateCustomerList: null,
  getCustomerList: null,
}

const WrappedCustomerListPage = Form.create({})(CustomerListPage)

export default WrappedCustomerListPage
