/* eslint-disable react/display-name */

import React from 'react'
import FormattedNumber from '../../../../components/base/FormattedNumber'
import styles from '../index.module.css'

const MedianEngagement = () => {
  return {
    title: () => (
      <div className={styles.columnTitleContainer}>
        <div>Median</div>
        <div>Eng.</div>
      </div>
    ),
    dataIndex: 'medianEngagement',
    key: 'medianEngagement',
    className: styles.following,
    render: value => <FormattedNumber number={value} />,
    sorter: (first, last) => first.medianEngagement - last.medianEngagement,
  }
}

export default MedianEngagement
