import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './BlankCenteredLayout.module.css'

function BlankCenteredLayout({ children, ...props }) {
  return (
    <Fragment>
      <div className={styles.container} {...props}>
        {children}
      </div>
    </Fragment>
  )
}

BlankCenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlankCenteredLayout
