export const ActionTypes = {
  CLIENT_ADD: 'CLIENT_ADD',
  CLIENT_CREATE: 'CLIENT_CREATE',
  CLIENT_EDIT: 'CLIENT_EDIT',
  CLIENT_DELETE: 'CLIENT_DELETE',
  CLIENT_SORT_BY_NAME: 'CLIENT_SORT_BY_NAME',
  CLIENT_SORT_BY_CREATED_AT: 'CLIENT_SORT_BY_CREATED_AT',
  CLIENT_SORT_BY_UPDATED_AT: 'CLIENT_SORT_BY_UPDATED_AT',
}

export const loadClients = () => async dispatch =>
  dispatch({
    API: {
      endpoint: 'clientList',
      type: ActionTypes.CLIENT_ADD,
    },
  })

export const loadClient = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'clientDetail',
      params: { clientId: id },
      type: ActionTypes.CLIENT_ADD,
    },
  })

export const createClient = client => async dispatch =>
  dispatch({
    API: {
      endpoint: 'clientCreate',
      method: 'POST',
      payload: client,
      type: ActionTypes.CLIENT_CREATE,
      errorType: 'CLOSABLE_ERROR_NO_OVERLAY',
    },
  })

export const editClient = client => async dispatch =>
  dispatch({
    API: {
      endpoint: 'clientEdit',
      method: 'PUT',
      payload: client,
      params: { id: client.id },
      type: ActionTypes.CLIENT_EDIT,
    },
  })

export const deleteClient = clientId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'clientDelete',
      method: 'DELETE',
      params: { id: clientId },
      type: ActionTypes.CLIENT_DELETE,
    },
  })

export const sortClientsByName = () => dispatch =>
  dispatch({ type: ActionTypes.CLIENT_SORT_BY_NAME })

export const sortClientsByUpdatedAt = () => dispatch =>
  dispatch({ type: ActionTypes.CLIENT_SORT_BY_UPDATED_AT })

export const sortClientsByCreatedAt = () => dispatch =>
  dispatch({ type: ActionTypes.CLIENT_SORT_BY_CREATED_AT })
