import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import CountPhotoOverlay from '../CountPhotoOverlay/CountPhotoOverlay'
import styles from './index.module.css'

function Thumbnail({ photoInfo, shortcode, photo, className }) {
  const [cannotLoadImage, setCannotLoadImage] = useState(false)

  useEffect(() => setCannotLoadImage(false), [shortcode])

  return (
    <div className={classNames(styles.thumbnail, className)}>
      <a
        href={shortcode ? `https://www.instagram.com/p/${shortcode}` : photo}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className={styles.photoContainer}
      >
        <img
          src={photo}
          alt="follower_photo"
          className={classNames(styles.photo, cannotLoadImage && styles.photoNotLoaded)}
          onError={() => setCannotLoadImage(true)}
        />
        {cannotLoadImage && (
          <span className={styles.loadingFailedCaption}>Image not yet loaded</span>
        )}
        <CountPhotoOverlay
          display={styles.displayCountOverlay}
          likesCount={photoInfo.likeCount || 0}
          commentsCount={photoInfo.commentCount || 0}
        />
      </a>
    </div>
  )
}

Thumbnail.propTypes = {
  photoInfo: PropTypes.object.isRequired,
  shortcode: PropTypes.string,
  photo: PropTypes.string,
  className: PropTypes.string,
}

Thumbnail.defaultProps = {
  className: '',
  shortcode: null,
  photo: null,
}
export default Thumbnail
