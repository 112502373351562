import { ActionTypes } from '../actions/customerList'
import { ActionTypes as CustomerActionTypes } from '../actions/customerFinalList'

const initialState = []

export const customerList = (state = initialState, action) => {
  switch (action.type) {
    case CustomerActionTypes.CUSTOMER_FETCH_DATA:
    case ActionTypes.FETCH_DATA:
      return action.payload.data

    case ActionTypes.UPDATE: {
      const { decision } = action.payload
      const updated = state.findIndex(record => record.userId === decision.userId)
      const updatedRow = {
        ...state[updated],
        decision: decision.decision,
        decisionMetadata: {
          ...state[updated].decisionMetadata,
          comment: decision.metadata.comment,
        },
        topPick: decision.topPick,
      }

      const newState = [...state]
      newState[updated] = updatedRow

      return newState
    }

    case ActionTypes.EDIT_RECORD: {
      const { record } = action.payload
      const updated = state.findIndex(item => item.userId === record.userId)
      const updatedRow = {
        ...state[updated],
        ...record,
      }

      const newState = [...state]
      newState[updated] = updatedRow
      return newState
    }

    case ActionTypes.ADD_USERS_MANUALLY: {
      const newState = [...state]
      return [...newState, ...action.payload.usernames]
    }
    case ActionTypes.REFRESH_ACCOUNT_SOURCES:
    case ActionTypes.UPDATE_ACCOUNT_SOURCES: {
      const { sources, matchUserProjectId } = action.payload
      const updatedIdx = state.findIndex(record => record.matchUserProjectId === matchUserProjectId)
      const newState = [...state]

      newState[updatedIdx].sources = sources

      return [...newState]
    }
    case ActionTypes.CLEAR:
      return [...initialState]

    case ActionTypes.ACCOUNT_DELETE:
      return state.filter(
        account =>
          action.payload.userId !== account.userId && action.payload.userId !== account.username
      )

    default:
      return state
  }
}
