import React from 'react'
import { BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CustomizedAxisTick from '../ChartTickLabels'
import CityNamesTicks from '../ChartTickLabels/CityNamesTicks'
import { renderCustomizedLabel } from '../../../../util/methods'
import styles from '../charts.module.css'

const VerticalBarChart = ({ className, data, height, leftMargin, cities = false }) => {
  if (data.length === 0) {
    return (
      <div className={classNames(styles.noDataPlaceholder, styles.verticalBarChart)}> NO DATA </div>
    )
  }
  return (
    <BarChart
      position="insideLeft"
      layout="vertical"
      width={cities ? 320 : 230}
      height={height}
      margin={{ left: leftMargin || 30, right: 40 }}
      data={data}
      className={className}
    >
      <XAxis
        dataKey="audience"
        type="number"
        tick={
          <CustomizedAxisTick
            textStyle={{ fontSize: 12, color: 'white', fontWeight: 'bold', dy: 8, dx: 7, cities }}
          />
        }
      />
      <YAxis
        dataKey="name"
        type="category"
        tickSize={5}
        tick={
          <CityNamesTicks
            textStyle={{ fontSize: 13, color: 'white', fontWeight: 'bold', dy: 8, dx: 7 }}
          />
        }
      />
      <Bar dataKey="audience" barSize={15} fill="#f06">
        <LabelList
          dataKey="label"
          textStyle={{
            fontSize: 10,
            color: 'white',
            fontWeight: 'bold',
          }}
          transform="true"
          content={renderCustomizedLabel}
        />
      </Bar>
    </BarChart>
  )
}

VerticalBarChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  leftMargin: PropTypes.number,
  cities: PropTypes.bool,
}

VerticalBarChart.defaultProps = {
  className: '',
  leftMargin: null,
  cities: false,
  height: 200,
}
export default VerticalBarChart
