export const ActionTypes = {
  FETCH_DATA: 'TIKTOK_CUSTOMER_LIST_FETCH_DATA',
  TIKTOK_CUSTOMER_LIST_UPDATE_ACCOUNT: 'TIKTOK_CUSTOMER_LIST_UPDATE_ACCOUNT',
}

export const fetchTiktokCustomerAccounts = projectId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'tiktokCustomerList',
      type: ActionTypes.FETCH_DATA,
      params: { projectId },
    },
  })

export const updateTiktokCustomerListAccount = data => async (dispatch, getState) =>
  dispatch({
    API: {
      method: 'PUT',
      endpoint: 'tiktokFinalUpdate',
      type: ActionTypes.TIKTOK_CUSTOMER_LIST_UPDATE_ACCOUNT,
      payload: data,
      params: { projectId: getState().selectedProject.id, userId: data.userId },
    },
  })
