/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import BlankCenteredLayout from '../../layouts/BlankCenteredLayout'
import styles from './NotFound.module.css'

class ErrorPage extends PureComponent {
  render() {
    const { error } = this.props
    return (
      <BlankCenteredLayout title={error.name}>
        <div className={styles.error}>
          <h1>
            Oh no, an error happened
            <span role="img" aria-label="scared face">
              &nbsp;😫😱
            </span>
          </h1>
          <p> It is probably us, not you. </p>
          <pre>{error.message}</pre>
          <a href="javascript:window.location.reload(true)">Please reload.</a>
        </div>
      </BlankCenteredLayout>
    )
  }
}

ErrorPage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.string]).isRequired,
}

export default ErrorPage
