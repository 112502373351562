import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

import styles from './index.module.css'

const CustomerListTable = ({
  cols,
  filteredList,
  scroll,
  showDrawer,
  currentRecord,
  closeDrawer,
  userIsCustomer,
}) => {
  const getClassName = record => {
    if (record.deepdive && !record.loading) {
      if (!userIsCustomer && record.deepdive.score < 100) return styles.lowDeepdiveScore
      if (record.userId === currentRecord.userId) return styles.tableRowSelected
    }
    return styles.tableRow
  }
  return (
    <div>
      <Table
        className={styles.mainContainer}
        dataSource={filteredList}
        columns={cols}
        rowClassName={record => getClassName(record)}
        pagination={false}
        onRow={record => ({
          onClick: event => {
            event.preventDefault(event)
            if (
              !['BUTTON', 'TEXTAREA'].includes(event.target.nodeName) &&
              event.target.alt !== 'TopPick' &&
              !record.loading
            ) {
              if (currentRecord !== record) {
                showDrawer(record)
              } else {
                closeDrawer('showUserDetails')
              }
            }
          },
        })}
        rowKey={record => record.userId}
        scroll={scroll}
      />
    </div>
  )
}

CustomerListTable.propTypes = {
  cols: PropTypes.array.isRequired,
  scroll: PropTypes.object.isRequired,
  filteredList: PropTypes.array.isRequired,
  showDrawer: PropTypes.func.isRequired,
  currentRecord: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  userIsCustomer: PropTypes.bool.isRequired,
}

CustomerListTable.defaultProps = {
  currentRecord: null,
}

export default CustomerListTable
