import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'antd'
import style from './index.module.css'

const ConfirmModal = ({ visible, handleOk, handleCancel, confirmText }) => {
  return (
    <Modal
      title="WARNING"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      mask
      className={style.confirmModal}
      okText="OK"
    >
      <p>{confirmText}</p>
      <br />
      <p>Are you sure you want to do this?</p>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
}

export default ConfirmModal
