import { ActionTypes } from '../actions/network'

const initialState = {
  error: null,
  closable: true,
  overlay: false,
  message: '',
}

export const error = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.NETWORK_FAILURE:
    case 'CLOSABLE_ERROR_WITH_OVERLAY': {
      return {
        error: action.payload,
        closable: true,
        overlay: true,
        message: action.message || action.payload.statusText,
      }
    }
    case 'NOT_CLOSABLE_ERROR_WITH_OVERLAY': {
      return { error: action.payload, closable: false, overlay: true, message: action.message }
    }
    case 'CLOSABLE_ERROR_NO_OVERLAY': {
      return { error: action.payload, closable: true, overlay: false, message: action.message }
    }
    case 'RESET_SILENT_ERROR': {
      return { ...initialState }
    }
    default: {
      return { ...state }
    }
  }
}
