import avatar from './shared/avatar'
import username from './shared/username'
import followers from './shared/followers'
import finalCall from './shared/finalCallButtons'
import comment from './shared/comment'
import deleteButton from './shared/deleteButton'
import topCountryOfAudience from './shared/topCountryOfAudience'
import topGenderOfAudience from './shared/topGenderOfAudience'

import following from './instagram/following'
import engagementScore from './instagram/engagementScore'
import credibilityScore from './instagram/credibilityScore'
import categories from './instagram/categories'

import medianViews from './tiktok/medianViews'
import medianEngagement from './tiktok/medianEngagement'

export default {
  avatar,
  username,
  followers,
  following,
  engagementScore,
  credibilityScore,
  categories,
  finalCall,
  comment,
  deleteButton,
  medianViews,
  medianEngagement,
  topCountryOfAudience,
  topGenderOfAudience,
}
