import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import FormattedNumber from '../base/FormattedNumber'
import comment from '../../assets/images/comment.svg'
import heart from '../../assets/images/heart.svg'
import styles from './CountPhotoOverlay.module.css'

function CountPhotoOverlay({ likesCount, commentsCount, display }) {
  return (
    <div className={classNames(styles.countPhotoOverlay, display)}>
      <div className={styles.counts}>
        <img src={heart} alt="comment count" className={styles.icon} />
        <FormattedNumber number={likesCount} />
      </div>
      <div className={styles.counts}>
        <img src={comment} alt="comment count" className={styles.icon} />
        <FormattedNumber number={commentsCount} />
      </div>
    </div>
  )
}

CountPhotoOverlay.propTypes = {
  likesCount: PropTypes.number.isRequired,
  display: PropTypes.string.isRequired,
  commentsCount: PropTypes.number.isRequired,
}

export default CountPhotoOverlay
