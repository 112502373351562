import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import { sidebarToggle } from '../actions/app'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import styles from './BasicLayout.module.css'

const { Content } = Layout

class BasicLayout extends PureComponent {
  render() {
    const { children, toggleSidebar, sidebarCollapsed } = this.props
    const { user, client, project } = this.props

    return (
      <Fragment>
        <Layout className={styles.sidebarLayout}>
          <Sidebar
            clientId={client && client.id}
            collapsed={sidebarCollapsed}
            toggleSidebar={toggleSidebar}
            user={user}
            projectId={project && project.id}
          />
          <Layout className={sidebarCollapsed ? styles.sidebarCollapsed : styles.sidebar}>
            <Header
              clientName={client && client.name}
              clientId={client && client.id}
              projectName={project && project.name}
              projectId={project && project.id}
            />
            <Content className={styles.content}>{children}</Content>
            <Footer />
          </Layout>
        </Layout>
      </Fragment>
    )
  }
}

BasicLayout.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  client: PropTypes.object,
  project: PropTypes.object,
}

BasicLayout.defaultProps = {
  client: null,
  project: null,
}
const mapStateToProps = state => ({
  sidebarCollapsed: state.app.sidebarCollapsed,
  user: state.authentication,
  client:
    window.location.pathname.split('/')[1] === 'project'
      ? state.selectedProject.client
      : state.client.find(item => item.id === window.location.pathname.split('/')[2]),
  project: state.selectedProject,
  projectId: state.selectedProject.id,
})

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(sidebarToggle()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicLayout)
