import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import classNames from 'classnames'

import styles from './index.module.css'

class BorderedButton extends React.PureComponent {
  render() {
    const { text, loading, onClick, disabled, className, inline } = this.props

    return (
      <Button
        loading={loading}
        onClick={onClick}
        className={classNames(styles.default, className, inline && styles.inline)}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }
}

BorderedButton.propTypes = {
  text: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  inline: PropTypes.bool,
}

BorderedButton.defaultProps = {
  text: '',
  className: '',
  loading: false,
  disabled: false,
  onClick: null,
  inline: false,
}

export default BorderedButton
