import { connect } from 'react-redux'
import Sources from './index'
import { updateSources, refreshSources } from '../../../actions/finalList'

const mapDispatchToProps = dispatch => ({
  saveSources: data => dispatch(updateSources(data)),
  refreshSources: id => dispatch(refreshSources(id)),
})

export default connect(
  null,
  mapDispatchToProps
)(Sources)
