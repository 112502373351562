import { ActionTypes } from '../actions/selectedProject'

const initialState = []
export const sourceData = (state = initialState, action) => {
  const newState = [...state]

  switch (action.type) {
    case ActionTypes.DATA_CREATE: {
      const existing = newState.findIndex(item => action.payload.data.id === item.id)
      if (existing !== -1) newState[existing] = action.payload.data
      else newState.unshift(action.payload.data)
      return newState
    }
    case ActionTypes.DATA_FETCH: {
      const newData = []
      newState.forEach(item => {
        let dataItem = item
        if (dataItem.id === action.payload.id) {
          dataItem = action.payload
        }
        newData.push(dataItem)
      })
      return [...newData]
    }
    case ActionTypes.DATA_DELETE: {
      const existing = newState.findIndex(item => action.payload.id === item.id)
      if (existing !== -1) newState[existing] = action.payload
      return newState
    }
    case ActionTypes.SELECT_PROJECT: {
      const { data } = action.payload
      const currentData = []
      data.forEach(dataItem => {
        if (!dataItem.deletedAt) currentData.push(dataItem)
      })
      return [...currentData]
    }
    case ActionTypes.DESELECT_PROJECT:
      return [...initialState]

    default:
      return state
  }
}
