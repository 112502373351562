/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'

import {
  TopPickSelected,
  TopPickUnselected,
} from '../../../../components/base/IconButtonsRepository'
import Avatar from '../../../../components/base/Avatar'

import styles from '../index.module.css'

const ProfilePicture = ({ drawerOpen, customerListComoView, saveTopPick }) => {
  const starUnclickable = !customerListComoView
  const starClickable = customerListComoView
  return {
    title: '',
    dataIndex: '',
    key: 'avatar',
    width: '70px',
    render: row => (
      <div className={styles.avatarContainer}>
        <Avatar
          src={row.avatar || row.profilePicture}
          size={50}
          showHalo={row.otherProjects && Object.entries(row.otherProjects).length > 0}
        />
        {row.topPick && (
          <div
            role="button"
            tabIndex="0"
            onKeyDown={() => {}}
            className={classNames(styles.star, starUnclickable && styles.starNoPointer)}
            onClick={starClickable ? e => saveTopPick(row, e) : null}
            type="button"
          >
            {row.topPick ? <TopPickSelected /> : <TopPickUnselected />}
          </div>
        )}
      </div>
    ),
    className: drawerOpen ? styles.avatarDrawerOpen : styles.avatar,
  }
}

export default ProfilePicture
