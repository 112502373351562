import NProgress from 'nprogress'
import { ActionTypes } from '../../actions/network'
import { RequestError } from '../../util/errors'
import api from './index'

const apiNamespace = 'API'
const defaultOptions = { method: 'GET' }
const defaultTypes = [
  ActionTypes.NETWORK_REQUEST,
  ActionTypes.NETWORK_SUCCESS,
  ActionTypes.NETWORK_FAILURE,
]

// eslint-disable-next-line
export default store => next => async action => {
  if (!action[apiNamespace] || action.type) {
    return next(action)
  }

  const meta = action[apiNamespace]

  const options = { ...defaultOptions, ...meta.options }
  if (meta.method) options.method = meta.method
  if (meta.params) options.params = meta.params
  if (meta.query) options.query = meta.query

  const types = meta.types || [...defaultTypes]
  if (meta.type) types[1] = meta.type
  if (meta.errorType) types[2] = meta.errorType

  NProgress.start()
  next({ type: types[0], meta })

  try {
    const payload = await api[meta.endpoint](options, meta.payload, meta.responseType || 'json')
    return next({ type: types[1], payload: payload.data, meta })
  } catch (ex) {
    const payload = ex.name === 'TypeError' ? new RequestError(ex.message, ex) : ex
    if (!meta.meta || meta.meta.hideErrorOverlay !== true) {
      next({ type: types[2], error: true, payload, meta })
    }
    throw payload
  } finally {
    NProgress.done()
  }
}
