import React from 'react'
import PropTypes from 'prop-types'
import { Alert as AntAlert } from 'antd'

import classNames from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import BorderedButton from '../buttons/BorderedButton'
import styles from './index.module.css'

class ErrorOverlay extends React.PureComponent {
  constructor(props) {
    super(props)
    this.errorRef = React.createRef()
    this.errorMessageTop = (
      <>
        There was a problem:
        <br />
      </>
    )
    this.errorMessageBottom = (
      <>
        Try reloading the page.
        <br />
        Or contact us to fix your issue at:
        <br />
        <a href="mailto:ident@gocomo.io" className={styles.email}>
          ident@gocomo.io
        </a>{' '}
      </>
    )
  }

  componentDidMount = () => {
    this.errorOverlay = this.errorRef.current
  }

  closeErrorAlert = () => {
    const { resetSilentError } = this.props
    resetSilentError()
  }

  render() {
    const { message, error, closable, overlay } = this.props
    if (overlay) disableBodyScroll(this.errorOverlay)
    if (!overlay) enableBodyScroll(this.errorOverlay)

    if (!error && !message) {
      return <></>
    }

    return (
      <div className={styles.mainContainer}>
        <div className={overlay ? classNames(styles.overlayContainer) : ''} ref={this.errorRef} />
        <div className={styles.errorAlert}>
          <AntAlert
            message={
              <div className={styles.errorMessage}>
                <p className={styles.errorMessageText}>{this.errorMessageTop}</p>
                {message && <p className={styles.message}>{message}</p>}
                <p className={styles.errorMessageText}>{this.errorMessageBottom}</p>
                <pre>{error && `(err: ${error})`}</pre>
              </div>
            }
            type="error"
            closable={closable}
            onClose={this.closeErrorAlert}
          />
          <BorderedButton
            text="Refresh Page"
            onClick={() => window.location.reload()}
            className={styles.reloadButton}
          />
        </div>
      </div>
    )
  }
}

ErrorOverlay.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  message: PropTypes.string,
  resetSilentError: PropTypes.func,
  closable: PropTypes.bool,
  overlay: PropTypes.bool,
}

ErrorOverlay.defaultProps = {
  resetSilentError: null,
  message: '',
  closable: true,
  error: '',
  overlay: false,
}

export default ErrorOverlay
