import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import classNames from 'classnames'
import styles from './index.module.css'
import checkmarkStyles from '../Checkmark/index.module.css'

const CheckboxGroup = Checkbox.Group

export const CheckmarkGroup = React.forwardRef(function CheckmarkGroupComp(props, ref) {
  return (
    <div className={checkmarkStyles.checkmark}>
      <CheckboxGroup
        className={classNames(styles.container, props.groupClassName)}
        options={props.options}
        onChange={props.onChange}
        defaultValue={props.checkedValues}
        ref={ref}
      />
    </div>
  )
})

CheckmarkGroup.propTypes = {
  groupClassName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  checkedValues: PropTypes.array,
}

CheckmarkGroup.defaultProps = {
  checkedValues: null,
}

export default CheckmarkGroup
