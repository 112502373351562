import { ActionTypes as AuthActions } from '../actions/app'
import { ActionTypes } from '../actions/user'

const initialState = []

export const user = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.AUTHENTICATION_LOGOUT:
      return [...initialState]

    case ActionTypes.USER_CREATE:
    case ActionTypes.USER_ADD:
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload]
      }
      return [...state, action.payload]

    case ActionTypes.USER_EDIT:
      return state.map(item => {
        if (item.id !== action.payload.id) {
          return item
        }

        return { ...item, ...action.payload }
      })

    case ActionTypes.USER_DELETE:
      return state.filter(item => action.payload.id !== item.id)

    default:
      return state
  }
}
