export const ActionTypes = {
  FIRST_CHECK_ADD_MORE_ACCOUNTS: 'FIRST_CHECK_ADD_MORE_ACCOUNTS',
  FIRST_CHECK_MOVE_ACCOUNT_TO_VOTED: 'FIRST_CHECK_MOVE_ACCOUNT_TO_VOTED',
  FIRST_CHECK_ACCOUNT_DECISION: 'FIRST_CHECK_ACCOUNT_DECISION',
  FIRST_CHECK_CLEAR_STATE: 'FIRST_CHECK_CLEAR_STATE',
  FIRST_CHECK_CHANGE_DECISION: 'FIRST_CHECK_CHANGE_DECISION',
}

export const loadAccounts = (projectId, query) => async dispatch =>
  dispatch({
    API: {
      endpoint: 'matchFirst',
      type: ActionTypes.FIRST_CHECK_ADD_MORE_ACCOUNTS,
      params: { id: projectId },
      query,
    },
  })

export const moveAccountToVoted = account => {
  return {
    type: ActionTypes.FIRST_CHECK_MOVE_ACCOUNT_TO_VOTED,
    account,
  }
}

export const moveAccountToFirstCheck = account => {
  return {
    type: ActionTypes.FIRST_CHECK_CHANGE_DECISION,
    account,
  }
}

export const clearState = () => dispatch =>
  dispatch({
    type: ActionTypes.FIRST_CHECK_CLEAR_STATE,
  })

export const saveDecision = (decision, query) => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'matchDecision',
      method: 'POST',
      payload: decision,
      params: { id: getState().selectedProject.id },
      query,
      type: ActionTypes.FIRST_CHECK_ACCOUNT_DECISION,
      meta: { hideErrorOverlay: true },
    },
  })
