import { initializeAuthentication } from './authentication'

export const ActionTypes = {
  APP_INITIALIZING: 'APP_INITIALIZING',
  APP_INITIALIZED: 'APP_INITIALIZED',
  APP_INIT_ERROR: 'APP_INIT_ERROR',
  APP_TOGGLE_SIDEBAR: 'APP_TOGGLE_SIDEBAR',
}

export const initializeConfiguration = () => async (dispatch, getState, { api }) => {
  dispatch({ type: ActionTypes.APP_INITIALIZING })
  try {
    const configData = await api.getConfiguration()
    if (configData.user) {
      dispatch(initializeAuthentication(configData.user))
    }

    dispatch({
      type: ActionTypes.APP_INITIALIZED,
      payload: configData.config,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.APP_INIT_ERROR,
      payload: error,
      error: true,
    })
  }
}

export const sidebarToggle = state => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.APP_TOGGLE_SIDEBAR,
    payload: state === undefined ? !getState().app.sidebarCollapsed : state,
  })
}
