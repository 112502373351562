/* eslint-disable react/display-name */
import React from 'react'

import CategoriesColumn from '../../../../components/shared/CategoriesColumnView'

import styles from '../index.module.css'

const Categories = ({ openCategoriesModal }) => {
  return {
    key: 'categories',
    title: 'Categories',
    dataIndex: 'categories',
    className: styles.categories,
    width: '150px',
    render: (categories, record) =>
      !record.loading ? (
        <CategoriesColumn
          data={categories}
          showAll={openCategoriesModal}
          containerWidth={styles.categoriesWidth}
        />
      ) : (
        <></>
      ),
  }
}
Categories.displayName = 'Categories'

export default Categories
