import React from 'react'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'

import styles from './index.module.css'

const FollowerGroups = ({ groupsCountArr }) => {
  let groups = 'No Results'
  if (groupsCountArr.length !== 0) {
    groups = groupsCountArr.map(group => {
      return (
        <div key={group[0]} className={styles.group}>
          <span>{group[0]}</span>
          <span>{group[1].count}</span>
        </div>
      )
    })
  }

  return (
    <Tooltip
      overlayClassName={styles.tooltip}
      placement="bottomLeft"
      arrowPointAtCenter
      title={<div className={styles.container}>{groups}</div>}
    >
      <div className={styles.buttonContainer}>
        <div className={styles.button} />
      </div>
    </Tooltip>
  )
}

FollowerGroups.propTypes = {
  groupsCountArr: PropTypes.array.isRequired,
}

export default FollowerGroups
