/* eslint-disable react/display-name */

import React from 'react'

import DataDisplay from '../../../../components/DataDisplay'
import styles from '../index.module.css'

const TopCountryOfAudience = () => {
  return {
    title: () => (
      <div className={styles.columnTitleContainer}>
        <div>Top Aud.</div>
        <div>Country</div>
      </div>
    ),
    render: data => (data ? <DataDisplay weight={data.weightDisplay} code={data.code} /> : ''),
    dataIndex: 'topCountryOfAudience',
    key: 'topCountryOfAudience',
    width: '90px',
    className: styles.topCountryOfAudience,
    sorter: ({ topCountryOfAudience: first }, { topCountryOfAudience: last }) =>
      first.weight - last.weight,
  }
}

export default TopCountryOfAudience
