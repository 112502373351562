import { ActionTypes } from '../actions/selectedProject'

const initialState = []
export const briefings = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_BRIEFING: {
      let newState = [...state]
      if (!Array.isArray(newState)) newState = []
      newState.push(action.payload.briefing)
      return newState
    }
    case ActionTypes.SELECT_PROJECT:
      return [...action.payload.briefings]
    case ActionTypes.DESELECT_PROJECT:
      return [...initialState]

    default:
      return state
  }
}
