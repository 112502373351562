export const ActionTypes = {
  FETCH_DATA: 'FINAL_LIST_FETCH_DATA',
  UPDATE_DECISION: 'FINAL_LIST_UPDATE_DECISION',
  CLEAR: 'FINAL_LIST_CLEAR',
  FINAL_LIST_ADD_USERS_MANUALLY: 'FINAL_LIST_ADD_USERS_MANUALLY',
  ACCOUNT_DELETE: 'FINAL_LIST_ACCOUNT_DELETE',
  EDIT_RECORD: 'FINAL_LIST_EDIT_RECORD',
  UPDATE_ACCOUNT_SOURCES: 'FINAL_LIST_UPDATE_ACCOUNT_SOURCES',
  REFRESH_ACCOUNT_SOURCES: 'FINAL_LIST_REFRESH_ACCOUNT_SOURCES',
  REFRESH_USERS_MANUALLY: 'FINAL_LIST_REFRESH_USERS_MANUALLY',
}

export const fetchFinalAccounts = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'matchFinal',
      type: ActionTypes.FETCH_DATA,
      params: { id },
    },
  })

export const updateFinalList = record => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'matchDecision',
      method: 'POST',
      payload: record,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.UPDATE_DECISION,
    },
  })

export const addUsersManually = ({ usernames, projectId }) => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'addUsersManually',
      method: 'POST',
      payload: usernames,
      params: { id: projectId || getState().selectedProject.id },
      type: ActionTypes.FINAL_LIST_ADD_USERS_MANUALLY,
    },
  })

export const clearFinalListState = () => async dispatch =>
  dispatch({
    type: ActionTypes.CLEAR,
  })

export const editFinalListRecord = data => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'editFinalListRecord',
      method: 'POST',
      payload: data,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.EDIT_RECORD,
    },
  })

export const updateSources = data => async dispatch =>
  dispatch({
    API: {
      endpoint: 'updateSources',
      method: 'POST',
      payload: data,
      type: ActionTypes.UPDATE_ACCOUNT_SOURCES,
    },
  })

export const refreshSources = matchUserProjectId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'refreshSources',
      method: 'GET',
      params: { matchUserProjectId },
      type: ActionTypes.REFRESH_ACCOUNT_SOURCES,
    },
  })

export const deleteAccount = data => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'editFinalListRecord',
      method: 'POST',
      payload: data,
      params: { id: getState().selectedProject.id, userId: data.username || data.userId },
      type: ActionTypes.ACCOUNT_DELETE,
    },
  })

export const createRefreshJob = userIds => async (dispatch, getState) => {
  const { payload } = await dispatch({
    API: {
      endpoint: 'projectRefreshUserManually',
      method: 'POST',
      payload: userIds,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.REFRESH_USERS_MANUALLY,
    },
  })
  return payload.msg
}
