import React from 'react'
import { Route, Switch } from 'react-router-dom'

import CustomerLayout from '../layouts/CustomerLayout'
import CustomerListPage from './Project/CustomerList/CustomerListPageContainer'
import Login from './Authentication/Login'
import ErrorOverlay from '../components/base/ErrorOverlay/container'

const Index = () => (
  <CustomerLayout>
    <ErrorOverlay />
    <Switch>
      <Route exact path="/list" component={() => <CustomerListPage />} />
      <Route component={Login} />
    </Switch>
  </CustomerLayout>
)

export default Index
