import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import classNames from 'classnames'
import styles from './index.module.css'

const SelectModal = ({ visible, title, handleCancel, children, style, width, footer }) => {
  return (
    <Modal
      mask
      closable
      maskClosable
      title={title}
      visible={visible}
      onCancel={handleCancel}
      className={classNames(styles.container, style)}
      width={width}
      footer={footer}
    >
      {children}
    </Modal>
  )
}

SelectModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  handleCancel: PropTypes.func.isRequired,
  style: PropTypes.string,
  width: PropTypes.string,
  footer: PropTypes.element,
}

SelectModal.defaultProps = {
  title: '',
  style: '',
  width: '50%',
  footer: null,
}

export default SelectModal
