import './errorReporting'

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import configureStore from './store'

import './assets/styles/base.css'
import RootPage from './pages/Root'

const store = configureStore()
if (window && window.addEventListener) {
  window.addEventListener('online', () => store.dispatch({ type: 'NETWORK_ONLINE' }))
  window.addEventListener('offline', () => store.dispatch({ type: 'NETWORK_OFFLINE' }))
}

const App = () => (
  <Provider store={store}>
    <Router>
      <Route path="/" component={RootPage} />
    </Router>
  </Provider>
)

export default App
