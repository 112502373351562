export const prepFollowerLabels = criteria => {
  const followerGroups = []
  const criteriaArray = Object.entries(criteria)
  criteriaArray.forEach(item => {
    if (item[0].startsWith('FOLLOWER')) {
      followerGroups.push({ range: item[1].range, label: item[1].label, checked: item[1].checked })
    }
  })
  return followerGroups
}

export const getFollowerGroupLabel = (num, rangeLabelArr) => {
  const followerGroup = rangeLabelArr.find(group => group.range[0] <= num && num <= group.range[1])
  return followerGroup && followerGroup.label
}

// counts number of accounts for each follower group, returns an array of arrays [[label, count], etc]
export const getGroupsCountArray = (followerCriteria, finalList) => {
  const groupsCount = {}

  followerCriteria.forEach(group => {
    groupsCount[group.label] = { count: 0, range: group.range }
  })

  finalList.forEach(account => {
    if (!account.loading) {
      const group = getFollowerGroupLabel(account.followers, followerCriteria)
      groupsCount[group].count += 1
    }
  })

  const groupsCountArr = Object.entries(groupsCount).filter(group => group[1].count !== 0)

  groupsCountArr.sort((group1, group2) => {
    if (group1[1].range[1] <= group2[1].range[0]) return -1
    return 1
  })
  return groupsCountArr
}
