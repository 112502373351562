import React from 'react'
import { Icon } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.css'
import BorderedButton from '../BorderedButton'

const IconButton = ({ type, className, iconSize, onClick }) => {
  return (
    <BorderedButton
      className={classNames(styles.default, className)}
      text={
        <Icon type={type} className={classNames(styles.defaultSize, iconSize)} theme="outlined" />
      }
      onClick={onClick}
    />
  )
}
IconButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

IconButton.defaultProps = {
  className: '',
  iconSize: '',
}

export default IconButton
