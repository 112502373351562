/* eslint-disable import/prefer-default-export */
/**
 * @param {(string | number | boolean)[]} array1
 * @param {(string | number | boolean)[]} array2
 * @returns {boolean}
 */

export const samePrimitivesInArrays = (array1, array2) => {
  if (array1.length === 0 && array2.length === 0) return true

  const values = {}
  const set1 = new Set(array1)
  const set2 = new Set(array2)

  Array.from(set1).forEach(item => {
    values[item] = 1
  })
  Array.from(set2).forEach(item => {
    if (values[item]) {
      values[item] += 1
    } else {
      values[item] = 1
    }
  })

  if (Object.values(values).some(value => value === 1)) return false

  return true
}
