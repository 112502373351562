/* eslint-disable react/display-name */

import React from 'react'

import DataDisplay from '../../../../components/DataDisplay'
import styles from '../index.module.css'

const TopGenderOfAudience = () => {
  return {
    title: () => (
      <div className={styles.columnTitleContainer}>
        <div>Aud.</div>
        <div>Gender</div>
      </div>
    ),
    render: data => (data ? <DataDisplay weight={data.weightDisplay} code={data.code[0]} /> : ''),
    dataIndex: 'topGenderOfAudience',
    key: 'topGenderOfAudience',
    width: '90px',
    className: styles.topGenderOfAudience,
  }
}

export default TopGenderOfAudience
