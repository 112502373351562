export const ActionTypes = {
  PROJECT_ADD: 'PROJECT_ADD',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_EDIT: 'PROJECT_EDIT',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_COMPLETE: 'PROJECT_COMPLETE',
  CLEAR_PROJECT_STATE: 'CLEAR_PROJECT_STATE',
  PROJECT_SORT_BY_NAME: 'PROJECT_SORT_BY_NAME',
  PROJECT_SORT_BY_CREATED_AT: 'PROJECT_SORT_BY_CREATED_AT',
  PROJECT_SORT_BY_UPDATED_AT: 'PROJECT_SORT_BY_UPDATED_AT',
}

export const loadProjects = clientId => async dispatch => {
  const query = {}
  if (clientId) {
    query.clientId = clientId
  }

  return dispatch({
    API: {
      endpoint: 'projectList',
      type: ActionTypes.PROJECT_ADD,
      query,
    },
  })
}

export const loadProject = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'projectDetail',
      params: { id },
      type: ActionTypes.PROJECT_ADD,
    },
  })

export const createProject = project => async dispatch =>
  dispatch({
    API: {
      endpoint: 'projectCreate',
      method: 'POST',
      payload: project,
      type: ActionTypes.PROJECT_CREATE,
    },
  })

export const editProject = project => async dispatch =>
  dispatch({
    API: {
      endpoint: 'projectEdit',
      method: 'PUT',
      payload: project,
      params: { id: project.id },
      type: ActionTypes.PROJECT_EDIT,
    },
  })

export const deleteProject = projectId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'projectDelete',
      method: 'DELETE',
      params: { id: projectId },
      type: ActionTypes.PROJECT_DELETE,
    },
  })

export const completeProject = projectId => async dispatch => {
  return dispatch({
    API: {
      endpoint: 'projectComplete',
      method: 'PUT',
      params: { id: projectId },
      type: ActionTypes.PROJECT_COMPLETE,
    },
  })
}

export const sortProjectsByName = () => dispatch =>
  dispatch({ type: ActionTypes.PROJECT_SORT_BY_NAME })

export const sortProjectsByUpdatedAt = () => dispatch =>
  dispatch({ type: ActionTypes.PROJECT_SORT_BY_UPDATED_AT })

export const sortProjectsByCreatedAt = () => dispatch =>
  dispatch({ type: ActionTypes.PROJECT_SORT_BY_CREATED_AT })

export const clearProjectState = () => ({
  type: ActionTypes.CLEAR_PROJECT_STATE,
})
