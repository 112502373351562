import { ActionTypes } from '../actions/firstCheck'
import { ActionTypes as SelectedProjectActionTypes } from '../actions/selectedProject'

const initialState = []

export const votedAccounts = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIRST_CHECK_MOVE_ACCOUNT_TO_VOTED: {
      const newState = [...state]
      newState.unshift(action.account)
      if (newState.length > 3) {
        newState.pop()
      }
      return [...newState]
    }
    case ActionTypes.FIRST_CHECK_CHANGE_DECISION: {
      const newState = [...state]
      newState.shift()
      return [...newState]
    }
    case SelectedProjectActionTypes.DESELECT_PROJECT:
      return [...initialState]

    default:
      return state
  }
}
