import { DuplicateError, WrongHashtagFormat, WrongLocationIdFormat } from '../util/errors'

export const ActionTypes = {
  CRITERIA_ADDED: 'CRITERIA_ADDED',
  CRITERIA_SEND: 'CRITERIA_SEND',
  DATA_CREATE: 'DATA_CREATE',
  DATA_FETCH: 'DATA_FETCH',
  DATA_DELETE: 'DATA_DELETE',
  DESELECT_PROJECT: 'DESELECT_PROJECT',
  FETCH_PROGRESS: 'FETCH_PROGRESS',
  SAVE_BRIEFING: 'SAVE_BRIEFING',
  SELECT_PROJECT: 'SELECT_PROJECT',
  STATUS_QUO_FETCH: 'STATUS_QUO_FETCH',
}

export const selectProject = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'projectDetail',
      params: { id },
      type: ActionTypes.SELECT_PROJECT,
    },
  })

export const resetProject = () => async dispatch =>
  dispatch({
    type: ActionTypes.DESELECT_PROJECT,
  })

export const addBriefing = file => async (dispatch, getState, { api }) => {
  const response = await api.projectAddBriefing(
    {
      method: 'POST',
      params: { id: getState().selectedProject.id },
    },
    file
  )
  return response.data
}

export const saveBriefing = (briefingId, filename) => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'projectCreateBriefing',
      params: { id: getState().selectedProject.id, bid: briefingId },
      method: 'POST',
      payload: { name: filename },
      type: ActionTypes.SAVE_BRIEFING,
    },
  })

export const updateCriteria = values => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'projectEditCriteria',
      method: 'POST',
      params: { id: getState().selectedProject.id },
      payload: values,
      type: ActionTypes.CRITERIA_ADDED,
    },
  })

export const fetchNewData = (dataId, field, retry = false) => async (dispatch, getState) => {
  const query = { what: field, retry }

  return dispatch({
    API: {
      endpoint: 'projectFetchDataStart',
      method: 'POST',
      query,
      params: { id: getState().selectedProject.id, dataId },
      type: ActionTypes.DATA_FETCH,
    },
  })
}
export const createData = data => async (dispatch, getState) => {
  const dataPoints = getState().sourceData
  const isDuplicate = dataPoints.findIndex(
    point => point.type === data.type && point.name === data.name
  )
  if (isDuplicate !== -1) {
    throw new DuplicateError(`${data.name} already exists in this project`)
  }
  if (data.type === 'HASHTAG' && data.name.charAt(0) === '#') {
    throw new WrongHashtagFormat(`${data.name} does contain a #!`)
  }
  if (data.type === 'LOCATION' && Number.isNaN(parseInt(data.name, 10))) {
    throw new WrongLocationIdFormat(`${data.name} is not numeric`)
  }

  return dispatch({
    API: {
      endpoint: 'projectCreateData',
      method: 'POST',
      payload: data,
      params: { id: data.projectId },
      type: ActionTypes.DATA_CREATE,
    },
  })
}

export const deleteData = (dataId, field) => async (dispatch, getState) => {
  const query = { what: field }
  return dispatch({
    API: {
      endpoint: 'projectFetchDataStop',
      method: 'DELETE',
      query,
      params: { id: getState().selectedProject.id, dataId },
      type: ActionTypes.DATA_DELETE,
    },
  })
}

export const getStatusQuo = () => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'projectStatusQuo',
      method: 'GET',
      params: { id: getState().selectedProject.id },
      type: ActionTypes.STATUS_QUO_FETCH,
    },
  })

export const loadProgress = progressId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'jobStatus',
      method: 'GET',
      params: { jobId: progressId },
      type: ActionTypes.FETCH_PROGRESS,
    },
  })
