import React from 'react'
import { Layout, Divider } from 'antd'
import PropTypes from 'prop-types'

import styles from './Footer.module.css'

const { Footer } = Layout

function FooterView({ content }) {
  return (
    <Footer className={styles.container}>
      <div>
        <a href="mailto:ident@gocomo.io" className={styles.link}>
          Help
        </a>{' '}
        &nbsp;
        <a
          className={styles.link}
          href="https://gocomo.io/privacy-policies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>{' '}
        &nbsp;
        <a
          className={styles.link}
          href="https://gocomo.io/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>{' '}
        &nbsp;
      </div>
      <div className={styles.footerContent}>
        Powered by GOCOMO GmbH
        {content && (
          <>
            <Divider className={styles.divider} type="verticle" />
            {content}
          </>
        )}
      </div>
    </Footer>
  )
}
FooterView.propTypes = {
  content: PropTypes.element,
}
FooterView.defaultProps = {
  content: null,
}
export default FooterView
