import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import classNames from 'classnames'

import { ShowMore } from '../../base/IconButtonsRepository'
import TooltipTag from '../TooltipTag'
import styles from './index.module.css'

const CategoriesColumnView = ({ data, showAll, containerWidth }) => {
  return (
    <div className={classNames(styles.categoryContainer, containerWidth)}>
      {data.length ? (
        <div className={styles.categories}>
          {data.slice(0, 2).map(item => (
            <TooltipTag key={item} text={item} />
          ))}
        </div>
      ) : (
        <div className={styles.categories} />
      )}
      <Tooltip
        arrowPointAtCenter
        placement="top"
        title="Click here to view all categories & edit category selection"
        overlayClassName={styles.tooltip}
        autoAdjustOverflow
        mouseEnterDelay={0.2}
        mouseLeaveDelay={0.2}
      >
        <div className={styles.showMoreButton} onClick={showAll} role="button">
          <ShowMore />
        </div>
      </Tooltip>
    </div>
  )
}

CategoriesColumnView.propTypes = {
  data: PropTypes.array,
  showAll: PropTypes.func,
  containerWidth: PropTypes.string,
}

CategoriesColumnView.defaultProps = {
  data: [],
  showAll: null,
  containerWidth: styles.containerWidth,
}

export default CategoriesColumnView
