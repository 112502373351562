import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Checkbox } from 'antd'
import styles from './index.module.css'

export const Checkmark = React.forwardRef(function CheckmarkComp(props, ref) {
  return (
    <div className={classNames(styles.checkmark, props.className)}>
      <Checkbox
        defaultChecked={props.checked}
        ref={ref}
        onChange={props.onChange}
        className={styles.checkmark}
      >
        <p className={styles.inputStyle}>{props.label}</p>
      </Checkbox>
    </div>
  )
})

Checkmark.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

Checkmark.defaultProps = {
  checked: false,
  onChange: null,
  className: '',
  label: '',
}

export default Checkmark
