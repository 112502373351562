/* eslint-disable react/display-name */
import React from 'react'
import { Tooltip } from 'antd'

import styles from '../index.module.css'

const Username = ({ drawerOpen }) => {
  return {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '140px',
    render: username => {
      if (username.length > 14)
        return (
          <Tooltip
            arrowPointAtCenter
            placement="bottomRight"
            title={username}
            overlayClassName={styles.tooltip}
            autoAdjustOverflow
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0.2}
          >
            <div className={styles.username}>{username.substring(0, 10)}</div>
            <div className={styles.username}>
              {username.substring(10, 20)}
              {username.length > 19 ? '...' : ''}
            </div>
          </Tooltip>
        )
      return <div className={styles.username}>{username}</div>
    },
    className: drawerOpen ? styles.usernameDrawerOpen : styles.username,
  }
}

export default Username
