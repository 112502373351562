import React from 'react'
import PropTypes from 'prop-types'

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload, textStyle } = this.props
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={textStyle.dy || 0}
          dx={textStyle.dx || 0}
          textAnchor="end"
          fill={textStyle.color}
          fontSize={textStyle.fontSize}
        >
          {payload.value}
        </text>
      </g>
    )
  }
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  textStyle: PropTypes.object.isRequired,
}

CustomizedAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
}
export default CustomizedAxisTick
