import { ActionTypes } from '../actions/network'

const initialState = {
  offline: false,
  loading: false,
  error: null,
}

export const network = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.NETWORK_OFFLINE:
      return { ...state, offline: true }

    case ActionTypes.NETWORK_ONLINE:
      return { ...state, offline: false }

    case ActionTypes.NETWORK_REQUEST:
      return { ...state, loading: true, error: null }

    case ActionTypes.NETWORK_SUCCESS:
      return { ...state, loading: false, error: null }

    case ActionTypes.NETWORK_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
