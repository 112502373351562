import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'

import BasicLayout from '../layouts/BasicLayout'
import NotFound from './Error/NotFound'
import Loading from '../components/base/LoadingMessage/index'

const User = Loadable({
  loader: () => import('./User/Overview'),
  loading: Loading,
})

const Categories = Loadable({
  loader: () => import('./Categories/Overview'),
  loading: Loading,
})

const Client = Loadable({
  loader: () => import('./Client/Overview'),
  loading: Loading,
})

const Project = Loadable({
  loader: () => import('./Project/Overview'),
  loading: Loading,
})

const Main = Loadable({
  loader: () => import('./Main'),
  loading: Loading,
})

const InfluencerPool = Loadable({
  loader: () => import('./InfluencerPool/Overview'),
  loading: Loading,
})

const Index = () => (
  <BasicLayout>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/user" component={User} />
      <Route path="/categories" component={Categories} />
      <Route path="/client" component={Client} />
      <Route path="/influencer-pool" component={InfluencerPool} />
      <Route path="/project/:projectId" component={Project} />
      <Route component={NotFound} />
    </Switch>
  </BasicLayout>
)

export default Index
