import { connect } from 'react-redux'
import { logout } from '../../actions/authentication'

import UserMenu from '../../components/base/UserMenu/index'

const mapStateToProps = state => ({
  username: state.authentication.username,
  email: state.authentication.email,
})

export default connect(
  mapStateToProps,
  { logout }
)(UserMenu)
