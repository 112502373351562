import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CaretDownOutlined } from '@ant-design/icons'

import BorderedButton from '../../base/buttons/BorderedButton'
import styles from './index.module.css'

const Dropdown = ({ preselectedItem, children, menuVisible, setMenuVisibility, showMore }) => {
  const menuVisibility = menuVisible ? styles.menuVisible : styles.menuHidden

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.actionsContainer}>
        <BorderedButton
          onClick={preselectedItem.onClick}
          className={classNames(styles.preselectedAction, !showMore ? styles.noDropdown : '')}
          text={preselectedItem.text}
          disabled={preselectedItem.disabled}
        />

        {showMore ? (
          <div
            role="button"
            className={styles.dropdownTrigger}
            onClick={() => setMenuVisibility(!menuVisible)}
          >
            <CaretDownOutlined />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={classNames(styles.dropdownMenu, menuVisibility)}>{children}</div>
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.element,
  preselectedItem: PropTypes.object.isRequired,
  menuVisible: PropTypes.bool.isRequired,
  showMore: PropTypes.bool,
  setMenuVisibility: PropTypes.func.isRequired,
}

Dropdown.defaultProps = {
  children: <></>,
  showMore: false,
}

export default Dropdown
