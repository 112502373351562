import React from 'react'
import PropTypes from 'prop-types'

class CityNamesTick extends React.PureComponent {
  render() {
    const { x, y, payload, textStyle } = this.props
    const name = payload.value
    const nameLength = name.length

    let nameArray = name.split()
    if (nameLength >= 13) {
      const nameArrayTemp = name.split(' ')
      let topCityName = nameArrayTemp[0]
      let bottomCityName = ''

      nameArrayTemp.slice(1).forEach(namePart => {
        if (namePart !== 'the') {
          let nameSlice = namePart
          if (namePart === 'and' || namePart === 'And') {
            nameSlice = '&'
          }
          if (topCityName.length + nameSlice.length + 1 <= 13) {
            topCityName = topCityName.concat(' ', nameSlice)
          } else {
            bottomCityName = bottomCityName.concat(' ', nameSlice)
          }
        }
      })
      if (bottomCityName && bottomCityName.length > 14) {
        bottomCityName = `${bottomCityName.slice(0, 12)}...`
      }
      nameArray = [topCityName]
      if (bottomCityName) nameArray.push(bottomCityName.slice(1))
    }
    return (
      <g transform={`translate(${x},${y})`}>
        {nameArray.map((city, idx) => (
          <text
            key={city}
            x={-10}
            y={-5}
            dy={nameArray.length > 1 ? idx * 14 + 2 : 8}
            dx={textStyle.dx || 0}
            textAnchor="end"
            fill={textStyle.color}
            fontSize={textStyle.fontSize}
          >
            {city}
          </text>
        ))}
      </g>
    )
  }
}

CityNamesTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  textStyle: PropTypes.object.isRequired,
}

CityNamesTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
}
export default CityNamesTick
