class InternalError extends Error {
  constructor(message) {
    super()
    this.name = 'InternalError'
    this.message = message
  }
}

class RequestError extends Error {
  constructor(message, original) {
    super()
    this.name = 'RequestError'
    this.message = message
    this.originalException = original
  }
}

class ApiError extends Error {
  constructor(status, statusText, response) {
    super()
    this.name = 'ApiError'
    this.status = status
    this.statusText = statusText
    this.response = response
    this.message = `${status} - ${statusText}`
  }
}

class DuplicateError extends Error {
  constructor(message) {
    super()
    this.name = 'DuplicateError'
    this.message = message
  }
}

class WrongHashtagFormat extends Error {
  constructor(message) {
    super()
    this.name = 'WrongHashtagFormat'
    this.message = message
  }
}

class WrongLocationIdFormat extends Error {
  constructor(message) {
    super()
    this.name = 'WrongLocationIdFormat'
    this.message = message
  }
}

export {
  InternalError,
  RequestError,
  ApiError,
  DuplicateError,
  WrongHashtagFormat,
  WrongLocationIdFormat,
}
