/* eslint-disable react/display-name */
import { PLATFORM_TYPE } from './FinalListPage'
import Column from '../columnOptions'

const columns = ({
  finalCall,
  selectedPlatform,
  drawerOpen = false,
  showDeleteModal,
  savingDecision,
  saveFinalListComment,
  commentChanged,
  openCategoriesModal,
}) => {
  const isInstagram = selectedPlatform === PLATFORM_TYPE.instagram
  const isTiktok = selectedPlatform === PLATFORM_TYPE.tiktok
  const drawerClosed = !drawerOpen
  const commentOptions = {
    drawerOpen,
    commentChanged,
    saveComment: saveFinalListComment,
    commentType: 'internalComment',
    title: 'Internal Comment',
    isReadOnly: false,
  }

  if (isInstagram && drawerClosed) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.followers(),
      Column.engagementScore(),
      Column.topCountryOfAudience(),
      Column.topGenderOfAudience(),
      Column.categories({ openCategoriesModal }),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: false }),
      Column.comment(commentOptions),
      Column.deleteButton({ showDeleteModal }),
    ]
  }

  if (isInstagram && drawerOpen) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: false }),
      Column.comment(commentOptions),
    ]
  }

  if (isTiktok && drawerClosed) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.followers(),
      Column.medianViews(),
      Column.medianEngagement(),
      Column.topCountryOfAudience(),
      Column.topGenderOfAudience(),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: false }),
      Column.comment(commentOptions),
      Column.deleteButton({ showDeleteModal }),
    ]
  }

  if (isTiktok && drawerOpen) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.medianViews(),
      Column.medianEngagement(),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: false }),
      Column.comment(commentOptions),
    ]
  }
  return []
}

export default columns
