import { ActionTypes } from '../actions/firstCheck'
import { ActionTypes as SelectedProjectActionTypes } from '../actions/selectedProject'

const initialState = []

export const firstCheckAccounts = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIRST_CHECK_ADD_MORE_ACCOUNTS:
      if (Array.isArray(action.payload.data)) {
        return [...state, ...action.payload.data]
      }
      return [...state]
    case ActionTypes.FIRST_CHECK_MOVE_ACCOUNT_TO_VOTED:
      state.splice(state.indexOf(action.account), 1)
      return [...state]
    case ActionTypes.FIRST_CHECK_CHANGE_DECISION: {
      const newState = [...state]
      newState.unshift(action.account)
      return [...newState]
    }
    case ActionTypes.FIRST_CHECK_CLEAR_STATE:
      return [...initialState]
    case SelectedProjectActionTypes.DESELECT_PROJECT:
      return [...initialState]

    default:
      return state
  }
}
