/* eslint-disable react/display-name */

import React from 'react'
import FormattedNumber from '../../../../components/base/FormattedNumber'
import styles from '../index.module.css'

const MedianViews = () => {
  return {
    title: () => (
      <div className={styles.columnTitleContainer}>
        <div>Median</div>
        <div>Views</div>
      </div>
    ),
    dataIndex: 'medianViews',
    key: 'medianViews',
    className: styles.following,
    render: value => <FormattedNumber number={value} />,
    sorter: (first, last) => first.medianViews - last.medianViews,
  }
}

MedianViews.displayName = 'MedianViews'

export default MedianViews
