import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Input } from 'antd'
import BorderedButton from '../base/buttons/BorderedButton'

import styles from './index.module.css'

const { TextArea } = Input
const CustomerLinkDisplay = ({ link, className }) => {
  // Copies link into clipboard onClick
  const copyLink = () => {
    const tempInput = document.createElement('input')
    tempInput.value = link
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  }
  return (
    <>
      <TextArea
        className={classnames(styles.link, 'textarea')}
        value={link}
        autosize={{ minRows: 3, maxRows: 3 }}
      />
      <BorderedButton
        text={
          <p>
            Copy
            <br />
            Customer Link
          </p>
        }
        onClick={copyLink}
        className={className}
      />
    </>
  )
}

CustomerLinkDisplay.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
}

CustomerLinkDisplay.defaultProps = {
  className: '',
}

export default CustomerLinkDisplay
