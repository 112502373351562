import * as Sentry from '@sentry/browser'

function configSentry() {
  if (process.env.NODE_ENV === 'development' || !process.env.REACT_APP_SENTRY_DSN) {
    console.log('disabled sentry in', process.env.NODE_ENV, process.env.REACT_APP_SENTRY_DSN) // eslint-disable-line
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: process.env.NODE_ENV === 'development',
    release: process.env.REACT_APP_BUILD || 'unknown',
    environment: process.env.REACT_APP_ENVIRONMENT || 'unknown',
  })

  Sentry.configureScope(scope => {
    scope.setTag('build', process.env.REACT_APP_BUILD)
  })
}

export default configSentry()
