import { connect } from 'react-redux'
import { resetSilentError } from '../../../actions/error'
import Alert from './index'

const mapStateToProps = state => ({
  error: state.error.error,
  message: state.error.message,
  closable: state.error.closable,
  overlay: state.error.overlay,
})
const mapDispatchToProps = dispatch => ({
  resetSilentError: () => dispatch(resetSilentError()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert)
