import { ActionTypes as AuthActions } from '../actions/authentication'
import { ActionTypes } from '../actions/app'

const initialState = {
  initialized: false,
  debug: false,
  version: process.env.REACT_APP_VERSION || '@@DEV-VERSION@@',
  build: process.env.REACT_APP_COMMITHASH || '@@DEV-BUILD@@',
  sidebarCollapsed: false,
}

export const app = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.AUTHENTICATION_LOGIN:
      if (!action.ignoreInitialization) {
        return { ...initialState }
      }
      return state

    case ActionTypes.APP_INITIALIZING:
      return { ...state, initialized: false }

    case ActionTypes.APP_INITIALIZED:
      return { ...state, ...action.payload, initialized: true }

    case ActionTypes.APP_INIT_ERROR:
      return { ...state, initialized: false, error: action.payload }

    case ActionTypes.APP_TOGGLE_SIDEBAR:
      return { ...state, sidebarCollapsed: action.payload }

    default:
      return state
  }
}
