import './errorReporting'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// read token from url if customer page is requested
if (window.location.pathname === '/list') {
  const params = new URLSearchParams(window.location.search.substr(1))
  const token = params.get('token')
  if (token) {
    localStorage.setItem('accessToken', token)
  }
}

// kickstart app
const rootEl = document.getElementById('root')
ReactDOM.render(React.createElement(App, {}), rootEl)

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default // eslint-disable-line global-require
      ReactDOM.render(React.createElement(NextApp, {}), rootEl)
    })
  }
}
