/* eslint-disable react/display-name */
import React from 'react'

import FormattedNumber from '../../../../components/base/FormattedNumber'

import styles from '../index.module.css'

const Following = () => {
  return {
    title: 'Following',
    dataIndex: 'following',
    key: 'following',
    className: styles.following,
    render: value => <FormattedNumber number={value} />,
    sorter: (first, last) => first.following - last.following,
  }
}

export default Following
