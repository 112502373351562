import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { initializeConfiguration } from '../actions/app'
import Index from './Index'
import Customer from './Customer'
import Login from './Authentication/Login'
import Error from './Error/Default'
import ErrorOverlay from '../components/base/ErrorOverlay/container'
import LoadingMessage from '../components/base/LoadingMessage/index'
import OfflineOverlay from '../components/base/OfflineOverlay/index'

class RootContainer extends PureComponent {
  async componentDidMount() {
    const { fetchConfiguration } = this.props
    await fetchConfiguration()
  }

  render() {
    const { initialized, isLoggedIn, isCustomer, error, offline } = this.props

    if (error) {
      return <Error error={error} />
    }

    if (!initialized) {
      return <LoadingMessage fullscreen />
    }

    if (!isLoggedIn) {
      return <Login />
    }

    if (isCustomer) {
      return <Customer />
    }

    return (
      <Fragment>
        <ErrorOverlay />
        <Index />
        {offline && <OfflineOverlay />}
      </Fragment>
    )
  }
}

RootContainer.propTypes = {
  offline: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fetchConfiguration: PropTypes.func.isRequired,
}

RootContainer.defaultProps = {
  error: undefined,
}

const mapStateToProps = state => ({
  initialized: state.app.initialized,
  error: state.app.error,
  offline: state.network.offline,
  isLoggedIn: state.authentication.isLoggedIn === true,
  isCustomer: state.authentication.roles.indexOf('ROLE_GUEST') !== -1,
})

export default connect(
  mapStateToProps,
  { fetchConfiguration: initializeConfiguration }
)(RootContainer)
