import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Input } from 'antd'
import styles from './index.module.css'

class AddUserModal extends React.PureComponent {
  state = {
    modalText: 'Add one username per line',
    usernames: '',
  }

  collectUsernames = async e => {
    e.preventDefault()
    const { value } = e.target
    await this.setState(() => ({
      usernames: value,
    }))
  }

  saveUsernames = async usernames => {
    const { addUsersToFinalList } = this.props

    await addUsersToFinalList(usernames)
    this.setState({ usernames: '' })
  }

  validateInput = async e => {
    e.preventDefault()
    const { usernames } = this.state
    const usernameArray = usernames
      .split('\n')
      .map(user => user.toLowerCase().trim())
      .filter(user => user !== '')

    const { handleCancel } = this.props
    const validRegex = /^[a-z\d\-_.]+$/
    const invalidUsers = usernameArray.filter(user => !validRegex.test(user) || user.length > 30)
    const validUsers = usernameArray.filter(user => validRegex.test(user) && user.length <= 30)

    if (invalidUsers.length > 0) {
      Modal.confirm({
        title: 'Your list contains invalid usernames',
        content: (
          <div>
            <ul>
              {invalidUsers.map(user => (
                <li key={user}>{user}</li>
              ))}
            </ul>
            Please doublecheck if they are written correctly. <br />
            Invalid usernames will be ignored.
          </div>
        ),
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: async () => {
          await this.saveUsernames(validUsers)
          handleCancel()
        },
        onCancel: () => {},
      })
    } else {
      await this.saveUsernames(validUsers)
      handleCancel()
    }
  }

  render() {
    const { modalText, usernames } = this.state
    const { TextArea } = Input
    const { visible, handleCancel } = this.props
    return (
      <Modal
        title={
          <>
            <span>Add Accounts To Final List</span>
            <br />
            <span className={styles.creditWarning}>Each account added costs 1 credit</span>
          </>
        }
        visible={visible}
        onOk={this.validateInput}
        onCancel={handleCancel}
        mask
        className={styles.addToFinalListModal}
      >
        <TextArea
          placeholder={modalText}
          autosize={{ minRows: 4 }}
          className={styles.addToFinalListTextarea}
          onChange={this.collectUsernames}
          value={usernames}
        />
      </Modal>
    )
  }
}

AddUserModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  addUsersToFinalList: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}

export default AddUserModal
