import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Alert, Form, Icon, Input, Button } from 'antd'
import { tryLogin, logout } from '../../actions/authentication'

import AuthLayout from '../../layouts/AuthLayout'
import styles from './Login.module.css'

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { submitted: false, loginError: false }
    this.handleSubmit = this.handleSubmit.bind(this)

    const { isLoggedIn, doLogout } = this.props
    if (isLoggedIn) {
      doLogout()
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    const { form, login } = this.props
    const { submitted } = this.state
    if (submitted) {
      return
    }

    this.setState({ submitted: true })
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await login(values.userName, values.password)
        } catch (ex) {
          this.setState({ loginError: true, submitted: false })
        }
      }
    })
  }

  render() {
    const { form } = this.props
    const { submitted, loginError } = this.state

    return (
      <AuthLayout>
        <div className={styles.main}>
          {loginError && (
            <Alert message="Wrong Username or Password" type="error" className={styles.alert} />
          )}
          <Form onSubmit={this.handleSubmit} className={styles.formContainer}>
            <Form.Item>
              {form.getFieldDecorator('userName', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" />}
                  placeholder="Username"
                  className={styles.input}
                />
              )}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder="Password"
                  className={styles.input}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                loading={submitted}
                type="primary"
                htmlType="submit"
                className={styles.LoginFormButton}
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </div>
      </AuthLayout>
    )
  }
}

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
  doLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
}

const WrappedLoginForm = Form.create()(LoginForm)
const mapStateToProps = state => ({
  isLoggedIn: state.authentication.isLoggedIn === true,
})

export default connect(
  mapStateToProps,
  {
    doLogout: logout,
    login: tryLogin,
  }
)(WrappedLoginForm)
