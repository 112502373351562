export const ActionTypes = {
  FETCH_CATEGORIES_LIST: 'FETCH_CATEGORIES_LIST',
  CLEAR_CATEGORIES_LIST: 'CLEAR_CATEGORIES_LIST',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  FETCH_CATEGORIES_LIST_WITH_ASSIGN_COUNT: 'FETCH_CATEGORIES_LIST_WITH_ASSIGN_COUNT',
  CLEAR_CATEGORIES_LIST_WITH_ASSIGN_COUNT: 'CLEAR_CATEGORIES_LIST_WITH_ASSIGN_COUNT',
  CREATE_CATEGORIES: 'CREATE_CATEGORIES',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
}

export const loadCategories = () => async dispatch =>
  dispatch({
    API: {
      endpoint: 'categoryList',
      type: ActionTypes.FETCH_CATEGORIES_LIST,
    },
  })

export const loadCategoriesWithAssignCount = () => async dispatch =>
  dispatch({
    API: {
      endpoint: 'categoryListWithAssignCount',
      type: ActionTypes.FETCH_CATEGORIES_LIST_WITH_ASSIGN_COUNT,
    },
  })

export const clearCategories = () => async dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_CATEGORIES_LIST,
  })

export const clearCategoriesWithAssignCount = () => async dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_CATEGORIES_LIST_WITH_ASSIGN_COUNT,
  })

export const deleteCategories = id => async dispatch => {
  await dispatch({
    API: {
      endpoint: 'categoryDelete',
      method: 'DELETE',
      params: { id },
      type: ActionTypes.DELETE_CATEGORY,
    },
  })
  return dispatch(loadCategoriesWithAssignCount())
}

export const createCategories = (names, callback) => async dispatch => {
  await dispatch({
    API: {
      endpoint: 'categoryCreate',
      method: 'post',
      payload: names,
      type: ActionTypes.CREATE_CATEGORIES,
    },
  })
  await dispatch(loadCategoriesWithAssignCount())
  if (callback) callback()
}

export const editCategory = data => async dispatch => {
  await dispatch({
    API: {
      endpoint: 'categoryEdit',
      method: 'post',
      payload: data,
      type: ActionTypes.EDIT_CATEGORY,
    },
  })
}
