/* eslint-disable no-underscore-dangle */

import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import api from './services/api'
import apiMiddleware from './services/api/middleware'
import config from './config'
import * as reducers from './reducer'

export default function configureStore(initialStore = {}) {
  let composeEnhancers = compose
  const middlewares = [thunk.withExtraArgument({ config, api }), apiMiddleware]

  // redux browser extension & Logger
  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger({ collapsed: true }))
  }

  const rootReducer = combineReducers(reducers)
  const store = createStore(
    rootReducer,
    initialStore,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  // hot-reload support in dev
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(combineReducers(reducers))
      })
    }
  }

  return store
}
