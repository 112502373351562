import React from 'react'

import styles from './NotFound.module.css'

const NotFound = () => (
  <div className={styles.container}>
    <h1>Error 404 - Not Found</h1>
  </div>
)

export default NotFound
