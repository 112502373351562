import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import { selectProject } from '../actions/selectedProject'
import Header from './Header'
import Footer from './Footer'
import LoadingMessage from '../components/base/LoadingMessage/index'

import styles from './CustomerLayout.module.css'

const { Content } = Layout

class BasicLayout extends PureComponent {
  state = { loaded: false }

  async componentDidMount() {
    const { user, getSelectedProject } = this.props
    await getSelectedProject(user.metadata.projectId)
    this.setState({ loaded: true })
  }

  render() {
    const { children } = this.props
    const { loaded } = this.state

    if (!loaded) {
      return <LoadingMessage fullscreen />
    }

    return (
      <Fragment>
        <Layout className={styles.layout}>
          <Header isCustomer />
          <Content className={styles.content}>{children}</Content>
          <Footer />
        </Layout>
      </Fragment>
    )
  }
}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  getSelectedProject: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.authentication,
})

const mapDispatchToProps = dispatch => ({
  getSelectedProject: id => dispatch(selectProject(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicLayout)
