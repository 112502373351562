const Config = {
  screenSizes: {
    'screen-xs': {
      maxWidth: 575,
    },
    'screen-sm': {
      minWidth: 576,
      maxWidth: 767,
    },
    'screen-md': {
      minWidth: 768,
      maxWidth: 991,
    },
    'screen-lg': {
      minWidth: 992,
      maxWidth: 1199,
    },
    'screen-xl': {
      minWidth: 1200,
      maxWidth: 1599,
    },
    'screen-xxl': {
      minWidth: 1600,
    },
  },
  init(config) {
    Object.assign(this, config)
  },
  reset() {
    localStorage.removeItem('accessToken')
  },
  getToken() {
    return localStorage.getItem('accessToken')
  },
  setToken(token) {
    localStorage.setItem('accessToken', token)
  },
}

export default Config
