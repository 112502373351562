/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Icon } from 'antd'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './index.module.css'

class CollapsableSection extends React.PureComponent {
  state = {
    visible: false,
  }

  toggleOpen = () => {
    const { visible } = this.state
    this.setState({ visible: !visible })
  }

  render() {
    const { visible } = this.state
    const { title, children, containerClassName, titleClassName } = this.props

    return (
      <div className={classNames(styles.mainContainer, containerClassName)}>
        <div
          className={classNames(styles.title, titleClassName)}
          onClick={this.toggleOpen}
          role="button"
          tabIndex="0"
        >
          <Icon type={visible ? 'down' : 'right'} className={styles.trigger} />
          {title}
        </div>
        {visible && children}
      </div>
    )
  }
}

CollapsableSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.object.isRequired,
}
CollapsableSection.defaultProps = {
  titleClassName: '',
  containerClassName: '',
}
export default CollapsableSection
