import { ActionTypes } from '../actions/category'

const initialState = []

export const category = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CATEGORIES_LIST:
      return [...action.payload]

    case ActionTypes.CLEAR_CATEGORIES_LIST:
      return initialState

    case ActionTypes.FETCH_CATEGORIES_LIST_WITH_ASSIGN_COUNT:
      return [...action.payload]

    case ActionTypes.CLEAR_CATEGORIES_LIST_WITH_ASSIGN_COUNT:
      return initialState

    case ActionTypes.EDIT_CATEGORY: {
      const { id, name } = action.payload
      const idx = state.findIndex(cat => cat.id === id)
      const newState = [...state]
      const updatedCategory = newState[idx]

      updatedCategory.name = name
      newState.splice(idx, 1, updatedCategory)

      return newState
    }

    default:
      return state
  }
}
