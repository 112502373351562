/* eslint-disable no-param-reassign */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Tooltip, notification } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { CSVLink } from 'react-csv'
import { Beforeunload } from 'react-beforeunload'

import { getGroupsCountArray } from '../../../util/followerGroupsHelpers'
import UserDetailsDrawer from '../../../components/UserDetailsDrawer'
import CombinedDeepdiveDrawer from '../../../components/CombinedDeepdiveDrawer/container'
import BorderedButton from '../../../components/base/buttons/BorderedButton'
import AddUserModal from '../../../components/AddUsersModal'
import ConfirmDeleteModal from '../../../components/ConfirmActionModal'
import FinalListTable from '../../../components/FinalListTable'
import LoadingMessage from '../../../components/base/LoadingMessage/index'
import UserTimeTracking from '../../../components/base/UserTimeLog'
import FollowerGroups from '../../../components/FollowerGroups'
import { CheckmarkGroup } from '../../../components/base/CheckmarkGroup'
import CategoriesListModal from '../../../components/shared/CategoriesModal/container'
import Dropdown from '../../../components/shared/Dropdown'
import finalListColumns from './FinalListColumns'
import { finalListHeaders } from '../../../constants/CsvHeaders'

import styles from './FinalListPage.module.css'
import downloadIcon from '../../../assets/images/download.svg'
import refreshIcon from '../../../assets/images/refresh.svg'
import prepareFinalList from '../../../util/prepareFinalList'

const filterFinalList = ({ accounts, checkedVotes, firstCheckVotes }) => {
  const filteredList = accounts
    ? accounts.filter(account => {
        const accountDecision = account.decision || 'UNDECIDED'
        return (
          checkedVotes.includes(accountDecision) &&
          (firstCheckVotes ? firstCheckVotes.includes(account.prevDecision) : true)
        )
      })
    : []
  return filteredList
}

export const PLATFORM_TYPE = {
  instagram: 'instagram',
  tiktok: 'tiktok',
}

const PLATFORM_NAME = {
  tiktok: 'TikTok',
  instagram: 'Instagram',
}

class FinalListPage extends React.PureComponent {
  state = {
    instagram: { checkedVotes: ['YES', 'NO', 'UNDECIDED'], firstCheckVotes: ['YES', 'MANUAL'] },
    tiktok: { checkedVotes: ['YES', 'NO', 'UNDECIDED'] },
    currentList: [],
    currentRecord: {},
    itemToDelete: {},
    loaded: false,
    visibleAddModal: false,
    visibleDeleteModal: false,
    showUserDetails: false,
    showCombinedDeepdive: false,
    accountDisplayTime: null,
    savingDecision: false,
    commentIsDirty: false,
    showCategoriesModal: false,
    selectedRows: [],
    menuVisible: false,
    addingCategories: false,
    showBulkCategoriesModal: false,
    selectedPlatform: PLATFORM_TYPE.instagram,
  }

  async componentDidMount() {
    const {
      loadCategories,
      fetchAccounts,
      fetchTiktokAccounts,
      selectedProject: { id },
    } = this.props

    await fetchAccounts(id)
    await fetchTiktokAccounts(id)
    await loadCategories()
    document.addEventListener('keydown', this.scrollTableRecords)
    this.setState({ loaded: true })
  }

  componentDidUpdate() {
    const { accountDisplayTime } = this.state
    if (!accountDisplayTime) {
      const displayTime = new Date()
      this.setState({ accountDisplayTime: displayTime, savingDecision: false }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  async componentWillUnmount() {
    const { clearState, clearCombinedDeepdiveState } = this.props
    await clearCombinedDeepdiveState()
    await clearState()
    document.removeEventListener('keydown', this.scrollTableRecords)
  }

  static getDerivedStateFromProps(props, stateObject) {
    const { finalList, tiktokFinalList } = props
    const {
      loaded,
      instagram,
      tiktok,
      visibleAddModal,
      showUserDetails,
      showCombinedDeepdive,
      currentRecord,
      selectedPlatform,
    } = stateObject
    const newState = {
      loaded,
      instagram,
      tiktok,
      visibleAddModal,
      showCombinedDeepdive,
      showUserDetails,
      currentRecord,
      currentList: [],
      selectedPlatform,
    }

    if (loaded) {
      const platformList = {
        [PLATFORM_TYPE.instagram]: finalList,
        [PLATFORM_TYPE.tiktok]: tiktokFinalList,
      }[selectedPlatform]

      if (platformList.length !== 0) {
        const preppedList = prepareFinalList(platformList, selectedPlatform)
        const filteredList = filterFinalList({
          accounts: preppedList,
          ...stateObject[selectedPlatform],
        })
        newState.currentList = [...filteredList]
      }
    }
    return { ...newState }
  }

  getFinalList = () => {
    switch (this.state.selectedPlatform) {
      case PLATFORM_TYPE.instagram:
        return this.props.finalList
      case PLATFORM_TYPE.tiktok:
        return this.props.tiktokFinalList
      default:
        return []
    }
  }

  updateYesFilters = firstCheckVotesRaw => {
    const firstCheckVotes =
      firstCheckVotesRaw.length === 0 ? ['YES', 'MAYBE', 'MANUAL'] : firstCheckVotesRaw
    this.setState(oldState => ({
      instagram: { ...oldState.instagram, firstCheckVotes },
    }))
  }

  updateVoteFilters = checkedVotesRaw => {
    const { selectedPlatform } = this.state
    const checkedVotes = checkedVotesRaw.length === 0 ? ['YES', 'NO', 'UNDECIDED'] : checkedVotesRaw
    this.setState(oldState => ({
      [selectedPlatform]: { ...oldState[selectedPlatform], checkedVotes },
    }))
  }

  closeDrawer = drawerType => {
    this.setState({
      [drawerType]: false,
    })
  }

  showDrawer = drawerType => {
    this.setState({
      [drawerType]: true,
    })
  }

  setCurrentRecord = record => {
    this.setState({
      currentRecord: record,
    })
  }

  showUserDetailsDrawer = () => {
    this.showDrawer('showUserDetails')
    this.closeDrawer('showCombinedDeepdive')
  }

  showCombinedDeepdive = () => {
    this.showDrawer('showCombinedDeepdive')
    this.closeDrawer('showUserDetails')
  }

  closeUserDetailsDrawer = () => {
    this.setState({
      currentRecord: {},
    })
    this.closeDrawer('showUserDetails')
  }

  closeCombinedDeepdiveDrawer = () => {
    this.setState({
      currentRecord: {},
    })
    this.closeDrawer('showCombinedDeepdive')
  }

  calculateContainerWidth = () => {
    const { showUserDetails, showCombinedDeepdive } = this.state
    if (showUserDetails || showCombinedDeepdive) return '66.5%'
    return '100%'
  }

  commentChanged = () => {
    this.setState({ commentIsDirty: true })
  }

  saveFinalListComment = async (row, e, commentType) => {
    e.preventDefault()
    const { commentIsDirty } = this.state

    if (commentIsDirty) {
      const { editFinalListRecord, user, updateTiktokFinalListAccount } = this.props
      const { value } = e.target
      const data = {
        value: { text: value, writtenBy: user },
        userId: row.userId,
        field: commentType,
      }
      try {
        if (this.isInstagram()) await editFinalListRecord(data)
        if (this.isTiktok()) await updateTiktokFinalListAccount(data)
        notification.success({
          message: 'Comment was saved!',
          placement: 'topRight',
        })
        this.setState({ commentIsDirty: false })
      } catch (error) {
        notification.warning({
          className: styles.notification,
          message: <strong>Error occured, comment might not have been saved</strong>,
          placement: 'topRight',
        })
      }
    }
  }

  showAddModal = () => {
    this.setState(() => ({
      visibleAddModal: true,
    }))
  }

  closeAddModal = () => {
    this.setState({
      visibleAddModal: false,
    })
  }

  showDeleteModal = row => {
    this.setState(() => ({
      visibleDeleteModal: true,
      itemToDelete: row,
    }))
  }

  closeDeleteModal = () => {
    this.setState(() => ({
      visibleDeleteModal: false,
    }))
  }

  openCategoriesModal = () => {
    this.setState({ showCategoriesModal: true })
  }

  closeCategoriesModal = () => {
    this.setState({ showCategoriesModal: false })
  }

  handleManualUsersRefresh = async e => {
    e.preventDefault()
    const { currentList } = this.state
    const { createRefreshJob } = this.props
    const message = await createRefreshJob(currentList.map(user => user.userId))
    notification.success({
      message,
      placement: 'topRight',
    })
  }

  handleOkDelete = async () => {
    const { deleteFinalAccount, updateTiktokFinalListAccount } = this.props
    const { itemToDelete } = this.state

    const deleting = itemToDelete.loading
      ? { username: itemToDelete.username }
      : { userId: itemToDelete.userId }

    try {
      if (this.isInstagram()) {
        await deleteFinalAccount({ field: 'deletedAt', value: new Date(), ...deleting })
      }
      if (this.isTiktok()) {
        await updateTiktokFinalListAccount({ field: 'deletedAt', value: new Date(), ...deleting })
      }
      notification.success({
        message: 'Item was deleted',
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        className: styles.notification,
        message: <strong>Error occured, comment might not have been deleted</strong>,
        placement: 'topRight',
      })
    } finally {
      this.closeDeleteModal()
    }
  }

  finalCall = async (decision, row, e) => {
    if (e) {
      e.preventDefault()
      const { updateFinalList, updateTiktokFinalListAccount } = this.props
      const { accountDisplayTime } = this.state
      const decisionMadeTime = new Date()
      const decisionTime = decisionMadeTime - accountDisplayTime

      try {
        if (this.isInstagram()) {
          updateFinalList({
            userId: row.userId,
            status: decision,
            round: 'final',
            decisionTime,
          })
        }
        if (this.isTiktok()) {
          await updateTiktokFinalListAccount({
            field: 'comoDecision',
            value: decision,
            userId: row.userId,
          })
        }
        notification.success({
          message: 'Decision was saved!',
          placement: 'topRight',
        })
      } catch (error) {
        notification.warning({
          className: styles.notification,
          message: <strong>Error occured. Decision might not have been saved</strong>,
          placement: 'topRight',
        })
      } finally {
        this.setState({ accountDisplayTime: null, savingDecision: true })
      }
    }
  }

  // Updates YES/NO/UNDECIDED totals
  calculateVoteTypes = () => {
    const voteCounts = { YES: 0, NO: 0, UNDECIDED: 0 }
    const finalList = this.getFinalList()

    finalList.forEach(account => {
      const accountDecision = account.decision ? account.decision : 'UNDECIDED'
      voteCounts[accountDecision] += 1
    })
    return voteCounts
  }

  // on key press down and up scroll through table records
  scrollTableRecords = e => {
    const { currentList, showUserDetails } = this.state
    if (showUserDetails) {
      const { currentRecord } = this.state
      const recordIndex = currentList.indexOf(currentRecord)
      if (e.keyCode === 40 && recordIndex !== currentList.length - 1) {
        this.setState({ currentRecord: currentList[recordIndex + 1] })
      } else if (e.keyCode === 38 && recordIndex !== 0) {
        this.setState({ currentRecord: currentList[recordIndex - 1] })
      }
    }
  }

  getCombinedDeepdive = async () => {
    const { getCombinedInstagramDeepdive, getCombinedTiktokDeepdive } = this.props
    const { currentList } = this.state
    const userIds = currentList
      .filter(item => {
        return !item.loading
      })
      .map(user => user.userId)

    try {
      let data
      if (this.isInstagram()) {
        data = await getCombinedInstagramDeepdive(userIds)
      }

      if (this.isTiktok()) {
        data = await getCombinedTiktokDeepdive(userIds)
      }
      const { totalFollowers } = data.payload

      if (totalFollowers) {
        this.showCombinedDeepdive('showCombinedDeepdive')
      } else {
        notification.warning({
          className: styles.notification,
          message: <strong>User List is empty</strong>,
          placement: 'topRight',
        })
      }
    } catch (ex) {
      notification.warning({
        className: styles.notification,
        message: <strong>An error happened while calculating Combined Deepdive</strong>,
        description: (
          <div>
            <code style={{ display: 'block', padding: '20px 0' }}>{ex.message}</code>
          </div>
        ),
        placement: 'topRight',
      })
    }
  }

  addUserManually = async usernames => {
    const { addUsersToFinalList, addTiktokAccounts } = this.props
    const finalList = this.getFinalList()
    const newUsernames = []
    const existingUsernames = []

    // separate newly added usernames into new and existing
    usernames.forEach(username => {
      if (finalList.some(account => account.username === username)) existingUsernames.push(username)
      else newUsernames.push(username)
    })

    if (existingUsernames.length > 0) {
      notification.warning({
        message: `"${existingUsernames.join(', ')}" - already on the list`,
        placement: 'topRight',
      })
    }

    if (newUsernames.length > 0) {
      try {
        let data
        if (this.isInstagram()) {
          data = await addUsersToFinalList({ usernames: newUsernames })
        }
        if (this.isTiktok()) {
          await addTiktokAccounts({ usernames: newUsernames })
        }
        if (data && data.payload.existingUsernames.length > 0) {
          notification.warning({
            message: `"${data.payload.existingUsernames.join(', ')}" - already on the list`,
            placement: 'topRight',
          })
        }
        notification.success({
          message: 'Usernames was saved',
          placement: 'topRight',
        })
      } catch (error) {
        notification.warning({
          message: 'Error occured. Users might not have been saved',
          placement: 'topRight',
        })
      }
    }
  }

  saveInfluencerCategories = async categories => {
    const { editInfluencerCategories } = this.props
    const { currentRecord } = this.state

    if (!currentRecord.userId) return

    await editInfluencerCategories({ userId: currentRecord.userId, categories })
  }

  onSelectChange = selectedRows => {
    this.setState({ selectedRows })
  }

  openDropdown = menuVisible => {
    this.setState({ menuVisible })
  }

  addBulkCategories = () => {
    this.setState({ showBulkCategoriesModal: true })
  }

  closeBulkCategories = () => {
    this.setState({ showBulkCategoriesModal: false })
  }

  saveBulkCategories = async categories => {
    const { editCategoriesForManyUsers } = this.props
    const { selectedRows } = this.state

    if (!selectedRows.length) return

    await editCategoriesForManyUsers({ userIds: selectedRows, categories })
  }

  getPlatformButtonType = platform => {
    return this.isSelectedPlatform(platform) ? 'primary' : 'default'
  }

  isSelectedPlatform = platform => {
    return this.state.selectedPlatform === platform
  }

  selectPlatform = async selectedPlatform => {
    const { clearCombinedDeepdiveState } = this.props

    await clearCombinedDeepdiveState()
    this.closeUserDetailsDrawer()
    this.closeCombinedDeepdiveDrawer()
    this.setState({ selectedPlatform })
  }

  isInstagram = () => {
    const { selectedPlatform } = this.state
    return selectedPlatform === PLATFORM_TYPE.instagram
  }

  isTiktok = () => {
    const { selectedPlatform } = this.state
    return selectedPlatform === PLATFORM_TYPE.tiktok
  }

  render() {
    const { loaded } = this.state
    if (!loaded) {
      return (
        <div className={styles.loading}>
          <LoadingMessage
            fullscreen
            text="Fetching info for you from the database. Hang on... "
            icon="loading"
          />
        </div>
      )
    }
    const { selectedProject, match, followerCriteria } = this.props
    const {
      currentList,
      currentRecord,
      itemToDelete,
      visibleAddModal,
      visibleDeleteModal,
      showUserDetails,
      savingDecision,
      commentIsDirty,
      showCombinedDeepdive,
      showCategoriesModal,
      selectedRows,
      menuVisible,
      addingCategories,
      showBulkCategoriesModal,
      selectedPlatform,
    } = this.state
    const { firstCheckVotes } = this.state[selectedPlatform] // eslint-disable-line react/destructuring-assignment
    const pageName = 'final_check'
    const decisionFilters = [
      { label: 'YES', value: 'YES' },
      { label: 'NO', value: 'NO' },
      { label: 'TO DO', value: 'UNDECIDED' },
    ]
    const firstCheckVoteFilters = [
      { label: 'YES', value: 'YES' },
      { label: 'MAYBE', value: 'MAYBE' },
      { label: 'MANUAL', value: 'MANUAL' },
    ]

    const columns = finalListColumns({
      selectedPlatform,
      savingDecision,
      drawerOpen: showUserDetails,
      finalCall: this.finalCall,
      showDeleteModal: this.showDeleteModal,
      saveFinalListComment: this.saveFinalListComment,
      commentChanged: this.commentChanged,
      openCategoriesModal: this.openCategoriesModal,
    })
    const projectName = selectedProject.name.toLowerCase().replace(/\./g, '')
    const groupsCountArr = getGroupsCountArray(followerCriteria, currentList)
    const finalListFilterTitle = 'Final List'
    const containerWidth = this.calculateContainerWidth()
    const noAccountsSelected = !selectedRows.length
    const selectedAccountsCount = selectedRows.length
    return (
      <Fragment>
        {commentIsDirty && <Beforeunload onBeforeunload={event => event.preventDefault()} />}

        <UserTimeTracking match={match} pageName={pageName} />
        <div>
          <div className={styles.headerTableContainer} style={{ width: containerWidth }}>
            <div className={styles.pageTop}>
              <div className={styles.headings}>
                <div className={styles.header}>
                  {selectedProject.name}&nbsp;-&nbsp;
                  {'Final List [Internal working version]'}
                </div>
                <div className={styles.subHeader}>Make sure to double-check all Influencers</div>
                <div className={styles.platformToggle}>
                  <ButtonGroup size='"large"'>
                    {Object.values(PLATFORM_TYPE).map(platform => (
                      <Button
                        key={platform}
                        type={this.getPlatformButtonType(PLATFORM_TYPE[platform])}
                        onClick={() => this.selectPlatform(PLATFORM_TYPE[platform])}
                      >
                        {PLATFORM_NAME[platform]}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
                <div className={styles.addButtonContainer}>
                  {this.isInstagram() && (
                    <div className={styles.addButtonActions}>
                      <Dropdown
                        preselectedItem={{
                          text: 'Bulk Assign Categories',
                          disabled: addingCategories || noAccountsSelected,
                          onClick: () => {
                            this.setState({ menuVisible: false })
                            this.addBulkCategories()
                          },
                        }}
                        menuVisible={menuVisible}
                        setMenuVisibility={this.openDropdown}
                      />
                      ({selectedAccountsCount} accounts selected)
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.dashboardContainer}>
                <BorderedButton
                  className={styles.bigPinkButton}
                  text={
                    <p>
                      Add {PLATFORM_NAME[selectedPlatform]}
                      <br />
                      Accounts
                    </p>
                  }
                  onClick={this.showAddModal}
                />
                <AddUserModal
                  visible={visibleAddModal}
                  handleCancel={this.closeAddModal}
                  addUsersToFinalList={this.addUserManually}
                />
                <Form>
                  <div className={styles.userConsole}>
                    {this.isInstagram() && (
                      <div className={styles.checkboxContainer}>
                        <h4 className={styles.filtersHeader}>First Check</h4>
                        <h4 className={styles.filtersHeader}>Filters</h4>
                        <CheckmarkGroup
                          groupClassName={styles.checkmarksNoOfYes}
                          options={firstCheckVoteFilters}
                          onChange={this.updateYesFilters}
                          checkedValues={firstCheckVotes}
                        />
                      </div>
                    )}
                    <div className={styles.checkboxContainer}>
                      <h4 className={styles.filtersHeader}>{finalListFilterTitle}</h4>
                      <h4 className={styles.filtersHeader}>Filters</h4>
                      <CheckmarkGroup
                        groupClassName={styles.checkmarksDecisions}
                        options={decisionFilters}
                        onChange={this.updateVoteFilters}
                      />
                    </div>
                    <div className={styles.decisionsTable}>
                      <p className={styles.yesVotes}>{String(this.calculateVoteTypes().YES)}</p>
                      <p className={styles.noVotes}>{String(this.calculateVoteTypes().NO)}</p>
                      <p>{String(this.calculateVoteTypes().UNDECIDED)}</p>
                    </div>
                  </div>
                </Form>
                <div className={styles.iconButtonsContainer}>
                  <div className={styles.csvFollowerContainer}>
                    <FollowerGroups groupsCountArr={groupsCountArr} />
                    <CSVLink
                      headers={finalListHeaders}
                      data={currentList}
                      filename={`${projectName}_final_list.csv`}
                    >
                      <Tooltip
                        arrowPointAtCenter
                        placement="bottomRight"
                        title="Download as .csv"
                        overlayClassName={styles.tooltip}
                        autoAdjustOverflow
                        mouseEnterDelay={0.2}
                        mouseLeaveDelay={0.2}
                      >
                        <img
                          text="Download"
                          src={downloadIcon}
                          alt="download icon"
                          className={styles.downloadIcon}
                        />
                      </Tooltip>
                    </CSVLink>
                  </div>
                  <div className={styles.csvFollowerContainer}>
                    <Tooltip
                      placement="bottomRight"
                      title="View combined details & audience insights of list"
                      mouseEnterDelay={0.2}
                      mouseLeaveDelay={0.2}
                    >
                      <div>
                        <BorderedButton
                          onClick={() => this.getCombinedDeepdive()}
                          className={styles.detailsBtn}
                        />
                      </div>
                    </Tooltip>
                    {this.isInstagram() && (
                      <Tooltip
                        arrowPointAtCenter
                        placement="bottomRight"
                        title="Refresh all accounts in list"
                        overlayClassName={styles.tooltip}
                        autoAdjustOverflow
                        mouseEnterDelay={0.2}
                        mouseLeaveDelay={0.2}
                      >
                        <Button type="link" onClick={this.handleManualUsersRefresh}>
                          <img
                            text="Refresh"
                            src={refreshIcon}
                            alt="refresh icon"
                            className={styles.refreshIcon}
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <FinalListTable
                selectedPlatform={selectedPlatform}
                columns={columns}
                filteredList={currentList}
                scroll={{
                  y: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 350,
                }}
                setCurrentRecord={this.setCurrentRecord}
                showDrawer={this.showUserDetailsDrawer}
                currentRecord={currentRecord}
                closeDrawer={this.closeUserDetailsDrawer}
                selectedRows={selectedRows}
                onSelectChange={this.onSelectChange}
              />
            </div>
          </div>
          <UserDetailsDrawer
            visibleDrawer={showUserDetails}
            currentRecord={currentRecord}
            onClose={this.closeUserDetailsDrawer}
          />
          <CombinedDeepdiveDrawer
            visibleDrawer={showCombinedDeepdive}
            onClose={this.closeCombinedDeepdiveDrawer}
          />
          {itemToDelete.username && (
            <ConfirmDeleteModal
              visible={visibleDeleteModal}
              confirmText={`This will permanently delete "${itemToDelete.username}".`}
              handleCancel={this.closeDeleteModal}
              handleOk={this.handleOkDelete}
            />
          )}
          {!currentRecord.loading && showCategoriesModal && (
            <CategoriesListModal
              user={{ userId: currentRecord.userId }}
              usedCategories={currentRecord.categories}
              visible={showCategoriesModal}
              handleCancel={this.closeCategoriesModal}
              onSave={this.saveInfluencerCategories}
              title={`Categories: @${currentRecord.username}`}
            />
          )}
          {showBulkCategoriesModal && (
            <CategoriesListModal
              visible={showBulkCategoriesModal}
              handleCancel={this.closeBulkCategories}
              onSave={this.saveBulkCategories}
              title={
                <>
                  <div>Bulk Assign Categories:</div>
                  <div className={styles.accountsCount}>
                    ( {selectedAccountsCount} accounts selected )
                  </div>
                </>
              }
            />
          )}
        </div>
      </Fragment>
    )
  }
}

FinalListPage.propTypes = {
  match: PropTypes.object.isRequired,
  addUsersToFinalList: PropTypes.func,
  fetchAccounts: PropTypes.func.isRequired,
  fetchTiktokAccounts: PropTypes.func.isRequired,
  loadCategories: PropTypes.func.isRequired,
  editInfluencerCategories: PropTypes.func.isRequired,
  editCategoriesForManyUsers: PropTypes.func.isRequired,
  updateTiktokFinalListAccount: PropTypes.func.isRequired,
  clearCombinedDeepdiveState: PropTypes.func.isRequired,
  getCombinedInstagramDeepdive: PropTypes.func.isRequired,
  getCombinedTiktokDeepdive: PropTypes.func.isRequired,
  addTiktokAccounts: PropTypes.func.isRequired,
  finalList: PropTypes.array.isRequired,
  tiktokFinalList: PropTypes.array.isRequired,
  clearState: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
  updateFinalList: PropTypes.func,
  editFinalListRecord: PropTypes.func,
  deleteFinalAccount: PropTypes.func,
  followerCriteria: PropTypes.array,
  createRefreshJob: PropTypes.func,
  user: PropTypes.string.isRequired,
}

FinalListPage.defaultProps = {
  followerCriteria: [],
  addUsersToFinalList: null,
  selectedProject: null,
  updateFinalList: null,
  editFinalListRecord: null,
  deleteFinalAccount: null,
  createRefreshJob: null,
}

const WrappedFinalListPage = Form.create({})(FinalListPage)

export default WrappedFinalListPage
