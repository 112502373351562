export const finalListHeaders = [
  { label: 'profilePicture', key: 'report.profilePicture' },
  { label: 'username', key: 'username' },
  { label: 'userId', key: 'userId' },
  { label: 'name', key: 'name' },
  { label: 'Emails', key: 'report.emails' },
  { label: 'City', key: 'report.city' },
  { label: 'Country', key: 'report.country' },
  { label: 'Followers', key: 'followers' },
  { label: 'Following', key: 'following' },
  { label: 'Average Likes', key: 'report.avgLikes' },
  { label: 'Average Comments', key: 'report.avgComments' },
  { label: 'Eng. Score', key: 'engagementScore' },
  { label: 'Posts Count', key: 'numPosts' },
  { label: 'Other Social Profile', key: 'report.socialProfiles' },
  { label: 'Business Account', key: 'isBusinessAccount' },

  { label: 'Follower Audience Credibility', key: 'report.followers.audienceCredibility' },
  { label: 'Follower Gender Female', key: 'report.followers.audienceGendersFEMALE' },
  { label: 'Follower Gender Male', key: 'report.followers.audienceGendersMALE' },
  { label: 'Follower Age 13 - 17', key: 'report.followers.audienceAges13-17' },
  { label: 'Follower Age 18 - 24', key: 'report.followers.audienceAges18-24' },
  { label: 'Follower Age 25 - 34', key: 'report.followers.audienceAges25-34' },
  { label: 'Follower Age 35 - 44', key: 'report.followers.audienceAges35-44' },
  { label: 'Follower Age 45 - 64', key: 'report.followers.audienceAges45-64' },

  { label: 'Follower Country 1', key: 'report.followers.countries1.name' },
  { label: 'Follower Country 1 Percent', key: 'report.followers.countries1.percent' },
  { label: 'Follower Country 2', key: 'report.followers.countries2.name' },
  { label: 'Follower Country 2 Percent', key: 'report.followers.countries2.percent' },
  { label: 'Follower Country 3', key: 'report.followers.countries3.name' },
  { label: 'Follower Country 3 Percent', key: 'report.followers.countries3.percent' },

  { label: 'Follower City 1', key: 'report.followers.cities1.name' },
  { label: 'Follower City 1 Percent', key: 'report.followers.cities1.percent' },
  { label: 'Follower City 2', key: 'report.followers.cities2.name' },
  { label: 'Follower City 2 Percent', key: 'report.followers.cities2.percent' },
  { label: 'Follower City 3', key: 'report.followers.cities3.name' },
  { label: 'Follower City 3 Percent', key: 'report.followers.cities3.percent' },

  { label: 'Follower Audience Interest 1', key: 'report.followers.audienceInterests1.name' },
  {
    label: 'Follower Audience Interest 1 Percent',
    key: 'report.followers.audienceInterests1.percent',
  },
  { label: 'Follower Audience Interest 2', key: 'report.followers.audienceInterests2.name' },
  {
    label: 'Follower Audience Interest 2 Percent',
    key: 'report.followers.audienceInterests2.percent',
  },
  { label: 'Follower Audience Interest 3', key: 'report.followers.audienceInterests3.name' },
  {
    label: 'Follower Audience Interest 3 Percent',
    key: 'report.followers.audienceInterests3.percent',
  },
  { label: 'Follower Audience Interest 4', key: 'report.followers.audienceInterests4.name' },
  {
    label: 'Follower Audience Interest 4 Percent',
    key: 'report.followers.audienceInterests4.percent',
  },
  { label: 'Follower Audience Interest 5', key: 'report.followers.audienceInterests5.name' },
  {
    label: 'Follower Audience Interest 5 Percent',
    key: 'report.followers.audienceInterests5.percent',
  },

  { label: 'Brand Affinity Audience 1', key: 'report.followers.audienceBrand1.name' },
  { label: 'Brand Affinity Audience 1 Percent', key: 'report.followers.audienceBrand1.percent' },
  { label: 'Brand Affinity Audience 2', key: 'report.followers.audienceBrand2.name' },
  { label: 'Brand Affinity Audience 2 Percent', key: 'report.followers.audienceBrand2.percent' },
  { label: 'Brand Affinity Audience 3', key: 'report.followers.audienceBrand3.name' },
  { label: 'Brand Affinity Audience 3 Percent', key: 'report.followers.audienceBrand3.percent' },
  { label: 'Brand Affinity Audience 4', key: 'report.followers.audienceBrand4.name' },
  { label: 'Brand Affinity Audience 4 Percent', key: 'report.followers.audienceBrand4.percent' },
  { label: 'Brand Affinity Audience 5', key: 'report.followers.audienceBrand5.name' },
  { label: 'Brand Affinity Audience 5 Percent', key: 'report.followers.audienceBrand5.percent' },

  { label: 'Likes not from Followers', key: 'report.likesFromNonFollowers' },
  { label: 'Influencer Interests 1', key: 'report.interests1.name' },
  { label: 'Influencer Interests 2', key: 'report.interests2.name' },
  { label: 'Influencer Interests 3', key: 'report.interests3.name' },
  { label: 'Influencer Interests 4', key: 'report.interests4.name' },
  { label: 'Influencer Interests 5', key: 'report.interests5.name' },

  { label: 'Followers (Month-4)', key: 'report.likeHistory[4].month' },
  { label: 'Followers (Month-4) Count', key: 'report.likeHistory[4].count' },
  { label: 'Followers (Month-3)', key: 'report.followerHistory[3].month' },
  { label: 'Followers (Month-3) Count', key: 'report.followerHistory[3].count' },
  { label: 'Followers (Month-2)', key: 'report.followerHistory[2].month' },
  { label: 'Followers (Month-2) Count', key: 'report.followerHistory[2].count' },
  { label: 'Followers (Month-1)', key: 'report.followerHistory[1].month' },
  { label: 'Followers (Month-1) Count', key: 'report.followerHistory[1].count' },
  { label: 'Followers', key: 'report.followerHistory[0].month' },
  { label: 'Followers Count', key: 'report.followerHistory[0].count' },

  { label: 'Avg Likes (Month-4)', key: 'report.likeHistory[4].month' },
  { label: 'Avg Likes (Month-4) Count', key: 'report.likeHistory[4].count' },
  { label: 'Avg Likes (Month-3)', key: 'report.likeHistory[3].month' },
  { label: 'Avg Likes (Month-3) Count', key: 'report.likeHistory[3].count' },
  { label: 'Avg Likes (Month-2)', key: 'report.likeHistory[2].month' },
  { label: 'Avg Likes (Month-2) Count', key: 'report.likeHistory[2].count' },
  { label: 'Avg Likes (Month-1)', key: 'report.likeHistory[1].month' },
  { label: 'Avg Likes (Month-1) Count', key: 'report.likeHistory[1].count' },
  { label: 'Avg Likes', key: 'report.likeHistory[0].month' },
  { label: 'Avg Likes Count', key: 'report.likeHistory[0].count' },

  { label: 'Brand Affinity 1', key: 'report.brandAffinity1.name' },
  { label: 'Brand Affinity 2', key: 'report.brandAffinity2.name' },
  { label: 'Brand Affinity 3', key: 'report.brandAffinity3.name' },
  { label: 'Brand Affinity 4', key: 'report.brandAffinity4.name' },
  { label: 'Brand Affinity 5', key: 'report.brandAffinity5.name' },

  { label: 'Notable Follower Ratio', key: 'report.notableUserRatio' },
  { label: 'Notable Follower', key: 'report.notableUsers' },

  { label: 'Top1 Hashtag', key: 'report.topHashtags1.name' },
  { label: 'Top1 Hashtag Percent', key: 'report.topHashtags1.percent' },
  { label: 'Top2 Hashtag', key: 'report.topHashtags2.name' },
  { label: 'Top2 Hashtag Percent', key: 'report.topHashtags2.percent' },
  { label: 'Top3 Hashtag', key: 'report.topHashtags3.name' },
  { label: 'Top3 Hashtag Percent', key: 'report.topHashtags3.percent' },
  { label: 'Top4 Hashtag', key: 'report.topHashtags4.name' },
  { label: 'Top4 Hashtag Percent', key: 'report.topHashtags4.percent' },
  { label: 'Top5 Hashtag', key: 'report.topHashtags5.name' },
  { label: 'Top5 Hashtag Percent', key: 'report.topHashtags5.percent' },

  { label: 'Top1 Mention', key: 'report.topMentions1.name' },
  { label: 'Top1 Mention Percent', key: 'report.topMentions1.percent' },
  { label: 'Top2 Mention', key: 'report.topMentions2.name' },
  { label: 'Top2 Mention Percent', key: 'report.topMentions2.percent' },
  { label: 'Top3 Mention', key: 'report.topMentions3.name' },
  { label: 'Top3 Mention Percent', key: 'report.topMentions3.percent' },
  { label: 'Top4 Mention', key: 'report.topMentions4.name' },
  { label: 'Top4 Mention Percent', key: 'report.topMentions4.percent' },
  { label: 'Top5 Mention', key: 'report.topMentions5.name' },
  { label: 'Top5 Mention Percent', key: 'report.topMentions5.percent' },

  { label: 'Like Audience Credibility', key: 'report.likers.audienceCredibility' },
  { label: 'Like Gender Female', key: 'report.likers.audienceGendersFEMALE' },
  { label: 'Like Gender Male', key: 'report.likers.audienceGendersMALE' },
  { label: 'Like Age 13 - 17', key: 'report.likers.audienceAges13-17' },
  { label: 'Like Age 18 - 24', key: 'report.likers.audienceAges18-24' },
  { label: 'Like Age 25 - 34', key: 'report.likers.audienceAges25-34' },
  { label: 'Like Age 35 - 44', key: 'report.likers.audienceAges35-44' },
  { label: 'Like Age 45 - 64', key: 'report.likers.audienceAges45-64' },

  { label: 'Like Country 1', key: 'report.likers.countries1.name' },
  { label: 'Like Country 1 Percent', key: 'report.likers.countries1.percent' },
  { label: 'Like Country 2', key: 'report.likers.countries2.name' },
  { label: 'Like Country 2 Percent', key: 'report.likers.countries2.percent' },
  { label: 'Like Country 3', key: 'report.likers.countries3.name' },
  { label: 'Like Country 3 Percent', key: 'report.likers.countries3.percent' },

  { label: 'Like City 1', key: 'report.likers.cities1.name' },
  { label: 'Like City 1 Percent', key: 'report.likers.cities1.percent' },
  { label: 'Like City 2', key: 'report.likers.cities2.name' },
  { label: 'Like City 2 Percent', key: 'report.likers.cities2.percent' },
  { label: 'Like City 3', key: 'report.likers.cities3.name' },
  { label: 'Like City 3 Percent', key: 'report.likers.cities3.percent' },

  { label: 'Like Audience Interest 1', key: 'report.likers.audienceInterests1.name' },
  { label: 'Like Audience Interest 1 Percent', key: 'report.likers.audienceInterests1.percent' },
  { label: 'Like Audience Interest 2', key: 'report.likers.audienceInterests2.name' },
  { label: 'Like Audience Interest 2 Percent', key: 'report.likers.audienceInterests2.percent' },
  { label: 'Like Audience Interest 3', key: 'report.likers.audienceInterests3.name' },
  { label: 'Like Audience Interest 3 Percent', key: 'report.likers.audienceInterests3.percent' },
  { label: 'Like Audience Interest 4', key: 'report.likers.audienceInterests4.name' },
  { label: 'Like Audience Interest 4 Percent', key: 'report.likers.audienceInterests4.percent' },
  { label: 'Like Audience Interest 5', key: 'report.likers.audienceInterests5.name' },
  { label: 'Like Audience Interest 5 Percent', key: 'report.likers.audienceInterests5.percent' },

  { label: 'Voted On', key: 'decisionTakenAt' },
]
export const customerListHeaders = [
  { label: 'Profile Picture', key: 'report.profilePicture' },
  { label: 'Username', key: 'username' },
  { label: 'User Id', key: 'userId' },
  { label: 'Emails', key: 'report.emails' },
  { label: 'Biography', key: 'biography' },
  { label: 'Followers', key: 'followers' },
  { label: 'Following', key: 'following' },
  { label: 'Eng. Score', key: 'engagementScore' },
  { label: 'Follower Gender Female', key: 'report.followers.audienceGendersFEMALE' },
  { label: 'Follower Gender Male', key: 'report.followers.audienceGendersMALE' },
  { label: 'Follower Age 13 - 17', key: 'report.followers.audienceAges13-17' },
  { label: 'Follower Age 18 - 24', key: 'report.followers.audienceAges18-24' },
  { label: 'Follower Age 25 - 34', key: 'report.followers.audienceAges25-34' },
  { label: 'Follower Age 35 - 44', key: 'report.followers.audienceAges35-44' },
  { label: 'Follower Age 45 - 64', key: 'report.followers.audienceAges45-64' },
  { label: 'Follower Country 1', key: 'report.followers.countries1.name' },
  { label: 'Follower Country 1 Percent', key: 'report.followers.countries1.percent' },
  { label: 'Follower Country 2', key: 'report.followers.countries2.name' },
  { label: 'Follower Country 2 Percent', key: 'report.followers.countries2.percent' },
  { label: 'Follower Country 3', key: 'report.followers.countries3.name' },
  { label: 'Follower Country 3 Percent', key: 'report.followers.countries3.percent' },
  { label: 'Follower City 1', key: 'report.followers.cities1.name' },
  { label: 'Follower City 1 Percent', key: 'report.followers.cities1.percent' },
  { label: 'Follower City 2', key: 'report.followers.cities2.name' },
  { label: 'Follower City 2 Percent', key: 'report.followers.cities2.percent' },
  { label: 'Follower City 3', key: 'report.followers.cities3.name' },
  { label: 'Follower City 3 Percent', key: 'report.followers.cities3.percent' },
  { label: 'Decision', key: 'decision' },
  { label: 'COMO Comment', key: 'comoComment' },
  { label: 'Client Comment', key: 'customerComment' },

  { label: 'Voted On', key: 'decisionTakenAt' },
]
