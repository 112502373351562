import { ActionTypes } from '../actions/selectedProject'

const initialState = {}
export const selectedProject = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SELECT_PROJECT: {
      const { data, briefings, criterias, ...selectedProjectDetails } = action.payload
      data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      return { ...selectedProjectDetails }
    }

    case ActionTypes.DESELECT_PROJECT:
      return { ...initialState }

    case ActionTypes.CRITERIA_ADDED: {
      const { difference = {} } = action.payload
      if (difference.addedIds && difference.addedIds.length > 0) {
        return { ...state, metadata: { ...state.metadata, openUpdates: difference.addedIds } }
      }

      return state
    }

    case ActionTypes.FETCH_FINAL_LIST_DEEPDIVE: {
      const { data } = action.payload
      return { ...state, finalListDeepdive: data }
    }

    default:
      return state
  }
}
