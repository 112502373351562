/* eslint-disable react/display-name */
import React from 'react'
import { Input } from 'antd'

import styles from '../index.module.css'

const Comment = ({ drawerOpen, commentType, commentChanged, saveComment, title, isReadOnly }) => {
  const { TextArea } = Input

  return {
    title,
    key: commentType,
    render: row => {
      return (
        <TextArea
          defaultValue={row[commentType]}
          onChange={commentChanged}
          placeholder="Please add your comments here"
          className={styles.addCommentTextarea}
          disabled={isReadOnly}
          onBlur={event => {
            if (!saveComment) return
            saveComment(row, event, commentType)
          }}
        />
      )
    },
    className: drawerOpen ? styles.commentDrawerOpen : styles.comment,
  }
}
export default Comment
