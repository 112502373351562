/* eslint-disable react/display-name */
import { PLATFORM_TYPE } from './CustomerListPage'
import Column from '../columnOptions'

const columns = ({
  customerListComoView = false,
  drawerOpen = false,
  finalCall,
  saveComment,
  savingDecision,
  saveTopPick,
  commentChanged,
  selectedPlatform,
}) => {
  const isInstagram = selectedPlatform === PLATFORM_TYPE.instagram
  const isTiktok = selectedPlatform === PLATFORM_TYPE.tiktok
  const drawerClosed = !drawerOpen

  const comoCommentOptions = {
    drawerOpen,
    commentChanged,
    saveComment,
    commentType: 'comoComment',
    title: 'Como Comment',
    isReadOnly: !customerListComoView,
  }

  const customerCommentOptions = {
    drawerOpen,
    commentChanged,
    saveComment,
    commentType: 'customerComment',
    title: 'Customer Comment',
    isReadOnly: customerListComoView,
  }

  if (isInstagram && drawerClosed) {
    return [
      Column.avatar({ drawerOpen, customerListComoView, saveTopPick }),
      Column.username({ drawerOpen }),
      Column.followers(),
      Column.engagementScore(),
      Column.topCountryOfAudience(),
      Column.topGenderOfAudience(),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: customerListComoView }),
      Column.comment(comoCommentOptions),
      Column.comment(customerCommentOptions),
    ]
  }

  if (isInstagram && drawerOpen) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: customerListComoView }),
      Column.comment(comoCommentOptions),
      Column.comment(customerCommentOptions),
    ]
  }

  if (isTiktok && drawerClosed) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.followers(),
      Column.medianViews(),
      Column.medianEngagement(),
      Column.topCountryOfAudience(),
      Column.topGenderOfAudience(),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: customerListComoView }),
      Column.comment(comoCommentOptions),
      Column.comment(customerCommentOptions),
    ]
  }

  if (isTiktok && drawerOpen) {
    return [
      Column.avatar({ drawerOpen }),
      Column.username({ drawerOpen }),
      Column.finalCall({ drawerOpen, finalCall, savingDecision, isReadOnly: customerListComoView }),
      Column.comment(comoCommentOptions),
      Column.comment(customerCommentOptions),
    ]
  }
  return []
}

export default columns
