import React from 'react'
import FormattedNumber from '../components/base/FormattedNumber'
import { PLATFORM_TYPE } from '../pages/Project/FinalList/FinalListPage'
import getProfileUrlByPlatform from './getProfileUrlByPlatform'

const convertScore = score => `${(score * 100).toFixed(2)}%`

const prepareFinalList = (list, platform) => {
  list.forEach(account => {
    // eslint-disable-next-line no-param-reassign
    account.userDetails = {
      biography: account.biography,
      profilePicture: platform === PLATFORM_TYPE.tiktok ? account.avatar : account.profilePicture,
      data: {
        username: account.username,
        followers: <FormattedNumber number={account.followers} />,
        following: Number.isInteger(account.following) ? (
          <FormattedNumber number={account.following} />
        ) : null,
        medianViews: account.medianViews ? <FormattedNumber number={account.medianViews} /> : null,
        medianEngagement: account.medianEngagement ? (
          <FormattedNumber number={account.medianEngagement} />
        ) : null,
        engagementScore: account.engagementScore ? (
          <>
            <FormattedNumber number={Number.parseFloat(account.engagementScore)} fraction={1} />%
          </>
        ) : null,
      },
      linkToProfile: getProfileUrlByPlatform(platform) + account.username,
    }
    // eslint-disable-next-line no-param-reassign
    account.credibilityScore = account.loading ? '' : convertScore(account.deepdive.credibility)
  })
  return list
}

export default prepareFinalList
