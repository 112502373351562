export const ActionTypes = {
  FETCH_DATA: 'TIKTOK_FINAL_LIST_FETCH_DATA',
  TIKTOK_FINAL_LIST_ADD_ACCOUNT: 'TIKTOK_FINAL_LIST_ADD_ACCOUNT',
  TIKTOK_FINAL_LIST_UPDATE_ACCOUNT: 'TIKTOK_FINAL_LIST_UPDATE_ACCOUNT',
}

export const fetchTiktokFinalAccounts = projectId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'tiktokFinal',
      type: ActionTypes.FETCH_DATA,
      params: { projectId },
    },
  })

export const updateTiktokFinalListAccount = data => async (dispatch, getState) =>
  dispatch({
    API: {
      method: 'PUT',
      endpoint: 'tiktokFinalUpdate',
      type: ActionTypes.TIKTOK_FINAL_LIST_UPDATE_ACCOUNT,
      payload: data,
      params: { projectId: getState().selectedProject.id, userId: data.userId },
    },
  })

export const addTiktokAccounts = data => async (dispatch, getState) =>
  dispatch({
    API: {
      method: 'POST',
      endpoint: 'addTiktokUsers',
      type: ActionTypes.TIKTOK_FINAL_LIST_ADD_ACCOUNT,
      payload: data,
      params: { projectId: getState().selectedProject.id, userId: data.userId },
    },
  })
