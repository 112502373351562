import { ActionTypes } from '../actions/authentication'
import { ActionTypes as AppActionTypes } from '../actions/app'

const initialState = {
  id: 'anonymous',
  isLoggedIn: false,
  metadata: {},
  roles: [],
}

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_INIT_CONFIG:
      return {
        ...state,
        ...action.payload.user,
        isLoggedIn: !!action.payload.user,
      }

    case ActionTypes.AUTHENTICATION_LOGIN: {
      return { ...state, ...action.payload, isLoggedIn: !!action.payload }
    }

    case ActionTypes.AUTHENTICATION_LOGOUT: {
      return { ...initialState }
    }

    case ActionTypes.AUTHENTICATION_METADATA: {
      return { ...state, metadata: action.payload }
    }

    default:
      return state
  }
}
