import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import styles from './index.module.css'

const TooltipTag = ({ text }) => {
  return (
    <Tooltip
      arrowPointAtCenter
      placement="top"
      title={text}
      overlayClassName={styles.tooltip}
      autoAdjustOverflow
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0.2}
    >
      <div className={styles.categoryTagBox}>
        <div className={styles.categoryTag}>{text}</div>
      </div>
    </Tooltip>
  )
}

TooltipTag.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TooltipTag
