import React from 'react'
import PropTypes from 'prop-types'

const DataDisplay = ({ weight, code }) => (
  <div>
    <b>{code}: </b>
    {weight}
  </div>
)

DataDisplay.propTypes = {
  weight: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}
export default DataDisplay
