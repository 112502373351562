/* eslint-disable react/display-name */
import React from 'react'

import IconButton from '../../../../components/base/buttons/IconButton'
import styles from '../index.module.css'

const DeleteButton = ({ showDeleteModal }) => {
  return {
    title: <div />,
    className: styles.deleteButton,
    key: 'deleteButton',
    render: row => {
      return <IconButton type="delete" onClick={() => showDeleteModal(row)} />
    },
  }
}

export default DeleteButton
