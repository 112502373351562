export const closableErrorWithOverlay = data => async dispatch =>
  dispatch({
    type: 'CLOSABLE_ERROR_WITH_OVERLAY',
    payload: data.error,
    message: data.message,
  })

export const notClosableErrorWithOverlay = data => async dispatch =>
  dispatch({
    type: 'NOT_CLOSABLE_ERROR_WITH_OVERLAY',
    payload: data.error,
    message: data.message,
  })

export const closableErrorNoOverlay = data => async dispatch =>
  dispatch({
    type: 'CLOSABLE_ERROR_NO_OVERLAY',
    payload: data.error,
    message: data.message,
  })

export const resetSilentError = () => async dispatch =>
  dispatch({
    type: 'RESET_SILENT_ERROR',
  })
