import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './index.module.css'

const AccountProjects = ({ projects }) => {
  return (
    <div className={styles.collapsibleSubcontainer}>
      <div className={styles.dataContainer}>
        <div className={classNames(styles.dataSubcol, styles.colTitle, styles.projectSubcol)}>
          Client Name:
        </div>
        <div className={classNames(styles.dataSubcol, styles.colTitle, styles.projectSubcol)}>
          Project Name:
        </div>
        <div className={classNames(styles.dataSubcol, styles.colTitle, styles.dataItemSubcol)}>
          Decision:
        </div>
      </div>
      {projects.map(project => (
        <div key={project.projectId} className={styles.dataContainer}>
          <div
            key={`${project.projectId}_client`}
            className={classNames(styles.dataSubcol, styles.projectSubcol)}
          >
            <Link
              to={`/client/${project.clientId}/projects`}
              target="_blank"
              className={styles.link}
            >
              {project.clientName}
            </Link>
          </div>
          <div
            key={`${project.projectId}_project`}
            className={classNames(styles.dataSubcol, styles.projectSubcol)}
          >
            <Link
              to={`/project/${project.projectId}/final-list`}
              target="_blank"
              className={styles.link}
            >
              {project.projectName}
            </Link>
          </div>
          <div
            key={`${project.projectId}_decision`}
            className={classNames(styles.dataSubcol, styles.dataItemSubcol)}
          >
            {project.finalRoundDecision}
          </div>
        </div>
      ))}
    </div>
  )
}

AccountProjects.propTypes = {
  projects: PropTypes.array.isRequired,
}
export default AccountProjects
