import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { PLATFORM_TYPE } from '../../pages/Project/FinalList/FinalListPage'
import styles from './index.module.css'

const FinalListTable = ({
  columns,
  filteredList,
  scroll,
  setCurrentRecord,
  showDrawer,
  currentRecord,
  closeDrawer,
  onSelectChange,
  selectedRows,
  selectedPlatform,
}) => {
  const getClassName = record => {
    if (record.deepdive && !record.loading) {
      if (record.deepdive.score < 100) return styles.lowDeepdiveScore
      if (record.userId === currentRecord.userId) return styles.tableRowSelected
    }
    return styles.tableRow
  }

  const rowSelection = {
    ...(selectedPlatform === PLATFORM_TYPE.instagram && {
      selectedRows: selectedRows.map(user => user.username),
      onChange: onSelectChange,
    }),
  }

  return (
    <div>
      <Table
        className={styles.mainContainer}
        dataSource={filteredList}
        columns={columns}
        rowClassName={record => getClassName(record)}
        rowSelection={rowSelection}
        pagination={false}
        onRow={record => ({
          onClick: event => {
            event.preventDefault(event)
            setCurrentRecord(record)
            if (!['IMG', 'BUTTON', 'TEXTAREA'].includes(event.target.nodeName) && !record.loading) {
              if (currentRecord !== record) {
                showDrawer(record)
              } else {
                closeDrawer('showUserDetails')
              }
            }
          },
        })}
        rowKey={record => record.userId}
        scroll={scroll}
      />
    </div>
  )
}

FinalListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  scroll: PropTypes.object.isRequired,
  filteredList: PropTypes.array.isRequired,
  showDrawer: PropTypes.func.isRequired,
  currentRecord: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  setCurrentRecord: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  selectedPlatform: PropTypes.string.isRequired,
}

FinalListTable.defaultProps = {
  currentRecord: null,
}

export default FinalListTable
