import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Footer from './Footer'
import { tryGoogle } from '../actions/authentication'

import styles from './AuthLayout.module.css'
import logo from '../assets/images/square_logo.svg'

function AuthLayout({ children, google }) {
  const footer = (
    <button type="button" onClick={google} className={styles.googleLogin}>
      {' '}
      Login
    </button>
  )
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.comonaut} />
        <div className={styles.subContainer}>
          <img alt="COMO" className={styles.logo} src={logo} />
          <div className={styles.title}>
            <span>THE</span>
            <span>IDENTIFICATOR</span>
          </div>
        </div>
        <div className={classNames(styles.subContainer, styles.contentFooterContainer)}>
          {children}
          <Footer content={footer} />
        </div>
      </div>
    </Fragment>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  google: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    google: tryGoogle,
  }
)(AuthLayout)
