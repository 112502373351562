import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Input, Icon } from 'antd'

import styles from './index.module.css'

const SearchBar = ({ word, onClear, onChange, className }) => (
  <Input
    value={word}
    className={classNames(styles.searchBar, className)}
    onChange={onChange}
    placeholder="Search"
    prefix={
      <Icon
        type="search"
        theme="outlined"
        className={classNames(styles.iconSize, styles.searchIcon)}
      />
    }
    suffix={
      <Icon type="close-circle" theme="outlined" onClick={onClear} className={styles.iconSize} />
    }
  />
)

SearchBar.propTypes = {
  word: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

SearchBar.defaultProps = {
  className: '',
}

export default SearchBar
