import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Legend, LabelList } from 'recharts'
import classNames from 'classnames'

import CustomizedAxisTick from '../ChartTickLabels'
import { renderCustomizedLabel } from '../../../../util/methods'
import styles from '../charts.module.css'

const SplitValueBarChart = ({ data, className }) => {
  if (data.length === 0) {
    return <div className={classNames(styles.noDataPlaceholder, styles.barChart)}> NO DATA </div>
  }

  return (
    <BarChart
      className={className}
      width={320}
      height={200}
      data={data}
      margin={{ top: 10, left: -30 }}
      align="left"
    >
      <XAxis
        dataKey="name"
        tick={
          <CustomizedAxisTick
            textStyle={{ fontSize: 12, color: 'white', fontWeight: 'bold', dy: 8, dx: 17.5 }}
          />
        }
      />
      <YAxis
        tick={
          <CustomizedAxisTick
            textStyle={{ fontSize: 12, color: 'white', fontWeight: 'bold', dy: 5 }}
          />
        }
      />
      <Bar dataKey="male" fill="#fff" barSize={19}>
        <LabelList
          dataKey="maleLabel"
          position="top"
          fill="white"
          className="ageGenderListLabels"
          textStyle={{ fontSize: 9, color: 'white', fontWeight: 'bold' }}
          content={renderCustomizedLabel}
        />
      </Bar>
      <Bar dataKey="female" fill="#f06" barSize={18}>
        <LabelList
          className="ageGenderListLabels"
          dataKey="femaleLabel"
          position="end"
          fill="white"
          textStyle={{ fontSize: 9, color: 'white', fontWeight: 'bold' }}
          content={renderCustomizedLabel}
        />
      </Bar>
      <Legend verticalAlign="top" align="right" height={35} />
    </BarChart>
  )
}
SplitValueBarChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
}

SplitValueBarChart.defaultProps = {
  className: '',
}
export default SplitValueBarChart
