/* eslint-disable react/display-name */
import styles from '../index.module.css'

const sortCredibilityScore = (first, last) => {
  // removes % sign
  const firstCredScore = first.credibilityScore.slice(0, -1)
  const lastCredScore = last.credibilityScore.slice(0, -1)
  if (Number(firstCredScore) > Number(lastCredScore)) {
    return 1
  }
  return -1
}

const CredibilityScore = ({ drawerOpen }) => {
  return {
    title: 'Cred. Score',
    dataIndex: 'credibilityScore',
    key: 'credibilityScore',
    className: drawerOpen ? styles.credScoreDrawerOpen : styles.credScore,
    sorter: (first, last) => sortCredibilityScore(first, last),
  }
}
export default CredibilityScore
