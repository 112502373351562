import { ActionTypes } from '../actions/client'

const initialState = []

export const client = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_CREATE: {
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload]
      }

      return [...state, action.payload]
    }

    case ActionTypes.CLIENT_ADD:
      if (Array.isArray(action.payload)) {
        return [...action.payload]
      }

      return [...action.payload]

    case ActionTypes.CLIENT_EDIT:
      return state.map(item => {
        if (item.id !== action.payload.id) {
          return item
        }

        return { ...item, ...action.payload }
      })

    case ActionTypes.CLIENT_SORT_BY_NAME:
      return state.slice(0).sort((client1, client2) => {
        if (client1.name.toLowerCase() < client2.name.toLowerCase()) return -1
        return 1
      })

    case ActionTypes.CLIENT_SORT_BY_CREATED_AT:
      return state.slice(0).sort((client1, client2) => {
        if (client1.createdAt > client2.createdAt) return -1
        return 1
      })

    case ActionTypes.CLIENT_SORT_BY_UPDATED_AT:
      return state.slice(0).sort((client1, client2) => {
        if (client1.updatedAt > client2.updatedAt) return -1
        return 1
      })

    case ActionTypes.CLIENT_DELETE:
      return state.filter(item => action.payload.id !== item.id)

    default:
      return state
  }
}
