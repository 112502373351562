/* eslint-disable react/display-name */
import React from 'react'
import FormattedNumber from '../../../../components/base/FormattedNumber'

import styles from '../index.module.css'

const Followers = () => {
  return {
    title: 'Followers',
    dataIndex: 'followers',
    key: 'followers',
    className: styles.followers,
    width: '100px',
    render: value => <FormattedNumber number={value} />,
    sorter: (first, last) => first.followers - last.followers,
  }
}

export default Followers
