import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './index.module.css'

import projects from '../../../assets/images/projects.svg'
import briefing from '../../../assets/images/files.svg'
import dataConfig from '../../../assets/images/data-config.svg'
import firstCheck from '../../../assets/images/choose.svg'
import winners from '../../../assets/images/winners.svg'
import gift from '../../../assets/images/gift.svg'
import idents from '../../../assets/images/idents.svg'
import outlinedStar from '../../../assets/images/starOutline.svg'
import filledStar from '../../../assets/images/starFilled.svg'
import inflPool from '../../../assets/images/inf-pool.svg'
import showMore from '../../../assets/images/ellipses_circled.svg'
import close from '../../../assets/images/close.svg'

export const TopPickSelected = () => (
  <img alt="TopPick" className={classNames('anticon', styles.star)} src={filledStar} />
)
export const TopPickUnselected = () => (
  <img alt="TopPick" className={classNames('anticon', styles.star)} src={outlinedStar} />
)
export const Projects = () => (
  <img alt="Projects" className={classNames('anticon', styles.menuOption)} src={projects} />
)
export const Idents = () => (
  <img alt="Idents" className={classNames('anticon', styles.menuOption)} src={idents} />
)
export const Briefing = () => (
  <img alt="Briefing" className={classNames('anticon', styles.menuOption)} src={briefing} />
)
export const DataInput = () => (
  <img alt="Data Input" className={classNames('anticon', styles.menuOption)} src={dataConfig} />
)
export const FirstCheck = () => (
  <img alt="FirstCheck" className={classNames('anticon', styles.menuOption)} src={firstCheck} />
)
export const FinalList = () => (
  <img alt="Winners" className={classNames('anticon', styles.menuOption)} src={winners} />
)
export const CustomerList = () => (
  <img alt="CustomerList" className={classNames('anticon', styles.menuOption)} src={gift} />
)
export const InfluencerPool = () => (
  <img alt="InfluencerPool" className={classNames('anticon', styles.menuOption)} src={inflPool} />
)
export const ShowMore = () => (
  <img alt="ShowMore" className={classNames('anticon', styles.showMore)} src={showMore} />
)
export const Close = ({ onClick }) => (
  <div role="button" onClick={onClick}>
    <img alt="Close" className={classNames('anticon', styles.close)} src={close} />
  </div>
)

Close.propTypes = {
  onClick: PropTypes.func,
}

Close.defaultProps = {
  onClick: null,
}
