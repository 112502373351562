import React from 'react'
import PropTypes from 'prop-types'

import SplitValueBarChart from '../base/charts/SplitValueBarChart'
import PieChart from '../base/charts/PieChart'
import VerticalBarChart from '../base/charts/VerticalBarChart'
import UnderlinedTitle from '../base/UnderlinedTitle'

import styles from './index.module.css'

const Deepdive = ({ deepdive }) => {
  // formats data for genderAgeSplit chart
  function ageSplitData() {
    if (deepdive && deepdive.ageSplit) {
      const { ageSplit } = deepdive
      const ageSplitPreped = ageSplit.map(item => ({
        name: `${item.code}`,
        male: Math.round(item.male * 100),
        female: Math.round(item.female * 100),
        maleLabel:
          Math.round(item.male * 100) !== 0 ? `${String(Math.round(item.male * 100))}%` : '',
        femaleLabel:
          Math.round(item.female * 100) !== 0 ? `${String(Math.round(item.female * 100))}%` : '',
      }))
      return ageSplitPreped
    }
    return []
  }

  // formats data for country chart
  function verticalGeoChartData(geoList, numEntries, decimals = 0) {
    if (deepdive) {
      const topEntries = geoList
        .sort((place1, place2) => place1.weight < place2.weight)
        .slice(0, numEntries)
      const namesPreped = topEntries.map(item => {
        // preps long names
        let name = item.name // eslint-disable-line
        if (name.length > 8) {
          if (name.substring(0, 4) === 'The ') {
            name = name.substring(4)
          }
        }
        return {
          name,
          audience: Math.round(item.weight * 100).toFixed(decimals),
          label: `${(item.weight * 100).toFixed(decimals)}%`,
        }
      })
      return namesPreped
    }
    return []
  }

  // formats data for gender chart
  function pieChartData(data) {
    const genderPreped = data.map(item => {
      return {
        name: item.code.toLowerCase(),
        value: Math.round(item.weight * 100),
      }
    })
    return genderPreped
  }

  const { cities = [], countries = [], gender = [] } = deepdive || {}
  gender.sort((gender1, gender2) => {
    if (gender1.code > gender2.code) {
      return -1
    }
    return 1
  })

  return (
    <>
      <UnderlinedTitle title="Age-gender split" className={styles.chartHeader} />
      <SplitValueBarChart data={ageSplitData()} className={styles.ageGenderChart} />
      <div className={styles.genderCountriesContainer}>
        <div className={styles.genderSplit}>
          <UnderlinedTitle title="Gender split" className={styles.chartHeader} />
          <PieChart data={pieChartData(gender)} />
        </div>
        <div>
          <UnderlinedTitle title="Countries of followers" className={styles.chartHeader} />
          <VerticalBarChart data={verticalGeoChartData(countries, 5)} />
        </div>
        <div>
          <UnderlinedTitle title="Cities of followers" className={styles.chartHeader} />
          <VerticalBarChart
            data={verticalGeoChartData(cities, 3, 2)}
            height={140}
            leftMargin={50}
            cities
          />
        </div>
      </div>
    </>
  )
}

Deepdive.propTypes = {
  deepdive: PropTypes.object.isRequired,
}

export default Deepdive
