import React from 'react'
import { Drawer as AntDrawer, Button, Icon } from 'antd'
import PropTypes from 'prop-types'
import FormattedNumber from '../base/FormattedNumber'
import Deepdive from '../Deepdive'

import styles from './index.module.css'

const CombinedDeepdiveDrawer = ({ deepdive, visibleDrawer, onClose }) => {
  if (!deepdive) return <div />

  const { totalFollowers, userCount } = deepdive

  return (
    <AntDrawer
      title=""
      placement="right"
      onClose={() => onClose()}
      visible={visibleDrawer}
      width="30%"
      closable={false}
      mask={false}
      className={styles.drawer}
    >
      <div className={styles.details}>
        <div className={styles.drawerSectionHeaders}>
          Details
          <Button ghost className={styles.close} onClick={() => onClose()}>
            <Icon type="right" />
          </Button>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailEntry}>
            <div className={styles.userDetails}>Total Number of Influencers</div>
            <div>{userCount}</div>
          </div>
          <div className={styles.detailEntry}>
            <div className={styles.userDetails}>Total Followers</div>
            <FormattedNumber number={totalFollowers} />
          </div>
        </div>
      </div>
      <div className={styles.drawerSectionHeaders}>Combined Audience Insights</div>
      <Deepdive deepdive={deepdive} />
    </AntDrawer>
  )
}

CombinedDeepdiveDrawer.propTypes = {
  deepdive: PropTypes.object,
  visibleDrawer: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

CombinedDeepdiveDrawer.defaultProps = {
  deepdive: {},
}

export default CombinedDeepdiveDrawer
