export const ActionTypes = {
  CUSTOMER_FETCH_DATA: 'CUSTOMER_FETCH_DATA',
  ADD_COMMENT: 'ADD_COMMENT',
}

export const fetchCustomerList = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'matchCustomer',
      type: ActionTypes.CUSTOMER_FETCH_DATA,
      params: { id },
    },
  })

export const addComment = data => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'saveComment',
      method: 'POST',
      payload: data,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.ADD_COMMENT,
    },
  })
