import React, { useState } from 'react'
import { Table, Button, notification } from 'antd'
import PropTypes from 'prop-types'
import ConfirmationModal from '../ConfirmationModal'

import { samePrimitivesInArrays } from '../../../util/samePrimitivesInArrays'
import SelectModal from '../SelectModal'
import { Close } from '../../base/IconButtonsRepository'
import SearchBar from '../../base/SearchBar'

import styles from './index.module.css'

const CategoriesList = ({
  visible,
  handleCancel,
  user,
  usedCategories,
  allCategories,
  onSave,
  title,
}) => {
  const originalUserCategories = usedCategories.map(name => name)
  const [selectedCategories, setSelectedCategories] = useState(originalUserCategories)
  const [isDirty, setIsDirty] = useState(false)
  const [showConfirmCancel, setConfirmCancel] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [categories, setCategories] = useState(allCategories)
  const [userCategories, setUserCategories] = useState(usedCategories)

  const columns = [
    {
      key: 'category',
      dataIndex: 'name',
      className: styles.category,
    },
  ]

  // returns selected categories to the top and unselected to the bottom of the list
  const collectCategories = () => {
    const parsedUsedCategories = userCategories.map(userCat => ({ name: userCat }))

    const availableCategories = categories.filter(
      category => !userCategories.includes(category.name)
    )

    return [...parsedUsedCategories, ...availableCategories]
  }

  const updateUserCategories = async () => {
    if (samePrimitivesInArrays(selectedCategories, originalUserCategories)) {
      handleCancel()
      return
    }

    try {
      await onSave(selectedCategories)
      notification.success({
        message: 'Categories were updated',
        placement: 'topRight',
      })
      handleCancel()
    } catch (e) {
      notification.warning({
        message: 'An error has occured',
        placement: 'topRight',
      })
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedCategories,
    onChange: keys => {
      setIsDirty(!samePrimitivesInArrays(keys, originalUserCategories))
      setSelectedCategories(keys)
    },
  }

  const closeModal = () => {
    if (isDirty) {
      setConfirmCancel(true)
    } else {
      handleCancel()
    }
  }

  const closeConfirmation = () => {
    setConfirmCancel(false)
  }

  const setFilteredCategories = string => {
    const filteredCategories = allCategories.filter(category =>
      category.name.toLowerCase().includes(string.toLowerCase())
    )
    const filteredUsedCategories = usedCategories.filter(category =>
      category.toLowerCase().includes(string.toLowerCase())
    )

    setSearchString(string)
    setCategories(filteredCategories)
    setUserCategories(filteredUsedCategories)
  }

  return (
    <SelectModal
      visible={visible}
      title={
        <div className={styles.modalTitleContainer}>
          <div className={styles.titleSearchContainer}>
            <div className={styles.modalTitle}>{title}</div>
            <SearchBar
              word={searchString}
              onClear={() => setFilteredCategories('')}
              onChange={item => setFilteredCategories(item.target.value)}
              className={styles.searchBar}
            />
          </div>
          <Close onClick={closeModal} />
        </div>
      }
      handleCancel={closeModal}
      style={styles.categoriesModal}
      width="30%"
      footer={
        <div>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            disabled={samePrimitivesInArrays(selectedCategories, originalUserCategories)}
            className={styles.saveButton}
            onClick={updateUserCategories}
          >
            Save
          </Button>
        </div>
      }
    >
      <Table
        className={styles.mainContainer}
        rowClassName={styles.row}
        dataSource={user ? collectCategories() : categories}
        columns={columns}
        pagination={false}
        showHeader={false}
        rowSelection={rowSelection}
        rowKey={item => item.name}
      />
      <ConfirmationModal
        visible={showConfirmCancel}
        handleCancel={closeConfirmation}
        message="You haven't saved your changes. Cancel changes?"
        onConfirm={handleCancel}
      />
    </SelectModal>
  )
}

CategoriesList.propTypes = {
  allCategories: PropTypes.array.isRequired,
  usedCategories: PropTypes.array,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  user: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
CategoriesList.defaultProps = {
  usedCategories: [],
  user: null,
  title: '',
}

export default CategoriesList
