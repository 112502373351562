export const ActionTypes = {
  FETCH_COMBINED_TIKTOK_DEEPDIVE: 'TIKTOK_FETCH_COMBINED_DEEPDIVE',
  FETCH_INSTAGRAM_COMBINED_DEEPDIVE: 'FETCH_INSTAGRAM_COMBINED_DEEPDIVE',
  CLEAR_STATE: 'CLEAR_COMBINED_DEEPDIVE_STATE',
}

export const getCombinedTiktokDeepdive = userIds => async (dispatch, getState) => {
  return dispatch({
    API: {
      type: ActionTypes.FETCH_COMBINED_TIKTOK_DEEPDIVE,
      endpoint: 'getCombinedTiktokDeepdive',
      query: { userIds: JSON.stringify(userIds) },
      params: { projectId: getState().selectedProject.id },
    },
  })
}

export const getCombinedInstagramDeepdive = query => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'getCombinedDeepdive',
      method: 'GET',
      type: ActionTypes.FETCH_INSTAGRAM_COMBINED_DEEPDIVE,
      params: { id: getState().selectedProject.id },
      query: { userIds: JSON.stringify(query) },
    },
  })

export const clearCombinedDeepdiveState = () => async dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_STATE,
  })
}
