import { ActionTypes } from '../actions/finalList'
import { ActionTypes as CustomerActionTypes } from '../actions/customerFinalList'
import { ActionTypes as InfluencerCategoriesActionTypes } from '../actions/influencerCategories'

const initialState = []

export const finalList = (state = initialState, action) => {
  switch (action.type) {
    case CustomerActionTypes.CUSTOMER_FETCH_DATA:
    case ActionTypes.FETCH_DATA:
      return action.payload.data

    case ActionTypes.UPDATE_DECISION: {
      const { decision } = action.payload
      const updated = state.findIndex(record => record.userId === decision.userId)
      const updatedRow = {
        ...state[updated],
        decision: decision.decision,
        decisionMetadata: {
          ...state[updated].decisionMetadata,
          comment: decision.metadata.comment,
        },
        topPick: decision.topPick,
      }

      const newState = [...state]
      newState[updated] = updatedRow

      return newState
    }

    case ActionTypes.EDIT_RECORD: {
      const { record } = action.payload
      const updated = state.findIndex(item => item.userId === record.userId)
      const updatedRow = {
        ...state[updated],
        ...record,
      }

      const newState = [...state]
      newState[updated] = updatedRow

      return newState
    }

    case ActionTypes.FINAL_LIST_ADD_USERS_MANUALLY: {
      const newState = [...state]
      return [...newState, ...action.payload.usernames]
    }
    case ActionTypes.REFRESH_ACCOUNT_SOURCES:
    case ActionTypes.UPDATE_ACCOUNT_SOURCES: {
      const { sources, matchUserProjectId } = action.payload
      const updatedIdx = state.findIndex(record => record.matchUserProjectId === matchUserProjectId)
      const newState = [...state]

      newState[updatedIdx].sources = sources

      return [...newState]
    }
    case ActionTypes.CLEAR:
      return [...initialState]

    case ActionTypes.ACCOUNT_DELETE:
      return state.filter(account => {
        const { record } = action.payload
        if (record.userId) return record.userId !== account.userId

        return !account.loading || record.username !== account.username
      })

    case InfluencerCategoriesActionTypes.FINAL_LIST_EDIT_INFLUENCER_CATEGORIES: {
      if (!state.length) return state

      const { userId, categories } = action.payload
      const newState = [...state]
      const idx = newState.findIndex(user => user.userId === userId)
      const user = newState[idx]
      user.categories = categories
      newState.splice(idx, user)

      return newState
    }

    case InfluencerCategoriesActionTypes.FINAL_LIST_EDIT_BULK_INFLUENCER_CATEGORIES: {
      if (!state.length) return state
      const newState = [...state]

      action.payload.forEach(item => {
        const idx = newState.findIndex(user => user.userId === item.userId)
        const user = newState[idx]
        user.categories = item.categories
        newState.splice(idx, user)
      })

      return newState
    }

    default:
      return state
  }
}
