import React from 'react'

// Allowes to change styling for svg lables used in Recharts
export const renderCustomizedLabel = properties => {
  const { x, y, width, value, textStyle, transform } = properties

  const dx = value.length * 2.7
  return (
    <g>
      <text
        x={transform ? x + width + 5 + dx : x + width / 2}
        y={transform ? y + 8 : y - 5}
        fill={textStyle.color}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight={textStyle.fontWeight}
        fontSize={textStyle.fontSize}
      >
        {value}
      </text>
    </g>
  )
}
export default renderCustomizedLabel
