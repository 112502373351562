export const ActionTypes = {
  FETCH_DATA: 'CUSTOMER_LIST_FETCH_DATA',
  UPDATE: 'CUSTOMER_LIST_UPDATE',
  CLEAR: 'CUSTOMER_LIST_CLEAR',
  ADD_USERS_MANUALLY: 'CUSTOMER_LIST_ADD_USERS_MANUALLY',
  ACCOUNT_DELETE: 'CUSTOMER_LIST_ACCOUNT_DELETE',
  EDIT_RECORD: 'CUSTOMER_LIST_EDIT_RECORD',
  UPDATE_ACCOUNT_SOURCES: 'CUSTOMER_LIST_UPDATE_ACCOUNT_SOURCES',
  REFRESH_ACCOUNT_SOURCES: 'CUSTOMER_LIST_REFRESH_ACCOUNT_SOURCES',
  REFRESH_USERS_MANUALLY: 'CUSTOMER_LIST_REFRESH_USERS_MANUALLY',
}

export const fetchFinalAccounts = id => async dispatch =>
  dispatch({
    API: {
      endpoint: 'matchFinal',
      type: ActionTypes.FETCH_DATA,
      params: { id },
    },
  })

export const updateCustomerList = record => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'matchDecision',
      method: 'POST',
      payload: record,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.UPDATE,
    },
  })

export const addUsersManually = usernames => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'projectAddUserManually',
      method: 'POST',
      payload: usernames,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.ADD_USERS_MANUALLY,
    },
  })

export const clearCustomerListState = () => async dispatch =>
  dispatch({
    type: ActionTypes.CLEAR,
  })

export const editFinalListRecord = data => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'editFinalListRecord',
      method: 'POST',
      payload: data,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.EDIT_RECORD,
    },
  })

export const updateSources = data => async dispatch =>
  dispatch({
    API: {
      endpoint: 'updateSources',
      method: 'POST',
      payload: data,
      type: ActionTypes.UPDATE_ACCOUNT_SOURCES,
    },
  })

export const refreshSources = matchUserProjectId => async dispatch =>
  dispatch({
    API: {
      endpoint: 'refreshSources',
      method: 'GET',
      params: { matchUserProjectId },
      type: ActionTypes.REFRESH_ACCOUNT_SOURCES,
    },
  })

export const deleteAccount = userId => async (dispatch, getState) =>
  dispatch({
    API: {
      endpoint: 'projectDeleteUserManually',
      method: 'DELETE',
      params: { id: getState().selectedProject.id, userId },
      type: ActionTypes.ACCOUNT_DELETE,
    },
  })

export const createRefreshJob = userIds => async (dispatch, getState) => {
  const { payload } = await dispatch({
    API: {
      endpoint: 'projectRefreshUserManually',
      method: 'POST',
      payload: userIds,
      params: { id: getState().selectedProject.id },
      type: ActionTypes.REFRESH_USERS_MANUALLY,
    },
  })
  return payload.msg
}
