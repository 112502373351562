import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from 'antd'
import classNames from 'classnames/bind'
import styles from './index.module.css'

const css = classNames.bind(styles)

function LoadingMessage({ icon, text, fullscreen, children }) {
  const containerClass = css({ container: !fullscreen, fullscreen })
  return (
    <div className={containerClass}>
      <div className={styles.message}>
        <Icon type={icon} theme="outlined" />
        <h2>{text}</h2>
      </div>
      {children && children}
    </div>
  )
}

LoadingMessage.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  fullscreen: PropTypes.bool,
  children: PropTypes.node,
}

LoadingMessage.defaultProps = {
  text: 'Loading ...',
  icon: 'loading',
  fullscreen: false,
  children: null,
}

export default LoadingMessage
