import React from 'react'
import { Drawer as AntDrawer, Button, Icon } from 'antd'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Thumbnail from '../Thumbnail'
import CollapsableSection from '../base/CollapsableSection'
import FailedChecks from './FailedChecks'
import AccountProjects from './AccountProjects'
import Sources from './Sources/container'
import Deepdive from '../Deepdive'
import UserData from './UserData'
import styles from './index.module.css'

const MANUAL_DECISION = 'MANUAL'

const UserDetailsDrawer = ({
  visibleDrawer,
  currentRecord,
  onClose,
  userIsCustomer,
  customerList,
  showSourcesToCustomer,
  customerListWorkerView,
}) => {
  const { deepdive, timelineMedia, sources, prevDecision } = currentRecord || null
  if (!deepdive) {
    return <div />
  }

  const oddPosts = timelineMedia && !!timelineMedia.length % 2

  const showFailedChecks = currentRecord.deepdive.score && currentRecord.deepdive.score !== 100

  const customerListGuestView = customerList && !customerListWorkerView
  let showSourcesComponent

  if (customerListGuestView) {
    showSourcesComponent = showSourcesToCustomer && sources.some(src => src.checked)
  } else {
    showSourcesComponent = !!sources
  }
  return (
    <AntDrawer
      title=""
      placement="right"
      onClose={onClose}
      visible={visibleDrawer}
      width="30%"
      closable={false}
      mask={false}
      className={styles.drawer}
    >
      <div className={styles.mainContainer}>
        <div className={styles.drawerSectionHeaders}>
          Details
          <Button ghost className={styles.close} onClick={onClose}>
            <Icon type="right" />
          </Button>
        </div>
        <UserData userDetails={currentRecord.userDetails} />
        <div>
          {!userIsCustomer && currentRecord.error && (
            <CollapsableSection
              title="This data is not up-to-date because we experienced an error while fetching details"
              containerClassName={styles.failedCheckContainer}
              titleClassName={styles.failedCheckTitle}
            >
              {currentRecord.error.message ||
                currentRecord.error.error_message ||
                currentRecord.error.data}
            </CollapsableSection>
          )}
          {!userIsCustomer && showFailedChecks && (
            <CollapsableSection
              title="This account did not pass Deepdive check"
              containerClassName={styles.failedCheckContainer}
              titleClassName={styles.failedCheckTitle}
            >
              <FailedChecks checks={currentRecord.deepdive.checks} />
            </CollapsableSection>
          )}
          {currentRecord.otherProjects && (
            <CollapsableSection
              title="Projects with this account in Final List"
              containerClassName={styles.otherProjectsContainer}
              titleClassName={styles.otherProjectsTitle}
            >
              <AccountProjects projects={currentRecord.otherProjects.projects} />
            </CollapsableSection>
          )}
          {showSourcesComponent && (
            <CollapsableSection
              title="Influencer Source"
              containerClassName={styles.otherProjectsContainer}
              titleClassName={styles.otherProjectsTitle}
            >
              <Sources
                sources={sources}
                customerList={customerList}
                addedManually={prevDecision === MANUAL_DECISION}
                matchUserProjectId={currentRecord.matchUserProjectId}
              />
            </CollapsableSection>
          )}
          <div className={styles.drawerSectionHeaders}>Audience Insights</div>
          <Deepdive deepdive={deepdive} />
        </div>
        {timelineMedia && (
          <div className={styles.section}>
            <div className={styles.drawerSectionHeaders}>Feed</div>
            <div className={styles.timeline}>
              {timelineMedia.map((photoInfo, id) => (
                <Thumbnail
                  className={classNames(styles.thumbnail, oddPosts && styles.addBorder)}
                  photoInfo={photoInfo}
                  shortcode={photoInfo.shortcode}
                  photo={photoInfo.media}
                  key={String(id)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </AntDrawer>
  )
}

UserDetailsDrawer.propTypes = {
  visibleDrawer: PropTypes.bool.isRequired,
  currentRecord: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  userIsCustomer: PropTypes.bool,
  customerList: PropTypes.bool,
  showSourcesToCustomer: PropTypes.bool,
  customerListWorkerView: PropTypes.bool,
}
UserDetailsDrawer.defaultProps = {
  customerList: false,
  userIsCustomer: false,
  showSourcesToCustomer: false,
  customerListWorkerView: false,
}
export default UserDetailsDrawer
