import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Layout, Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'

import styles from './index.module.css'
import { Logo } from '../../components/base/Logo'

import {
  Projects,
  Briefing,
  DataInput,
  FirstCheck,
  FinalList,
  CustomerList,
  Idents,
  InfluencerPool,
} from '../../components/base/IconButtonsRepository'

function Sidebar({ collapsed, user, projectId, toggleSidebar, clientId }) {
  const { roles } = user
  const isExternal = roles[0] === 'ROLE_EXTERNAL'
  const disabledItem = projectId === null
  const pathEntries = window.location.pathname.split('/')
  const lastItem = pathEntries[pathEntries.length - 1]

  const selectedKeys = []
  if (lastItem === 'edit') {
    selectedKeys.push(pathEntries[1])
  } else {
    selectedKeys.push(lastItem)
  }

  const cursorStyle = disabledItem ? styles.cursorRestricted : styles.pointerCursor
  const menuItemsData = [
    {
      key: 'client',
      link: '/client',
      icon: <Projects />,
      name: 'Projects',
      disabled: false,
      cursorStyle: styles.pointerCursor,
      restrictions: false,
    },
    {
      key: 'projects',
      link: `/client/${clientId}/projects`,
      icon: <Idents />,
      name: 'Idents',
      disabled: !clientId,
      cursorStyle: clientId ? styles.pointerCursor : styles.cursorRestricted,
      restrictions: false,
    },
    {
      key: 'briefing',
      link: `/project/${projectId}/briefing`,
      icon: <Briefing />,
      name: 'Briefings',
      disabled: disabledItem,
      cursorStyle,
      restrictions: isExternal,
    },
    {
      key: 'data',
      link: `/project/${projectId}/data`,
      icon: <DataInput />,
      name: 'Data Input',
      disabled: disabledItem,
      cursorStyle,
      restrictions: isExternal,
    },
    {
      key: 'first-check',
      link: `/project/${projectId}/first-check`,
      icon: <FirstCheck />,
      name: 'First check',
      disabled: disabledItem,
      cursorStyle,
      restrictions: false,
    },
    {
      key: 'final-list',
      link: `/project/${projectId}/final-list`,
      icon: <FinalList />,
      name: 'Final List',
      disabled: disabledItem,
      cursorStyle,
      restrictions: isExternal,
    },
    {
      key: 'customer-list',
      link: `/project/${projectId}/customer-list`,
      icon: <CustomerList />,
      name: 'Customer List',
      disabled: disabledItem,
      cursorStyle,
      restrictions: isExternal,
    },
  ]
  const menuItems = menuItemsData.map(item => {
    if (!item.restrictions) {
      return (
        <Menu.Item key={item.key} disabled={item.disabled} className={item.cursorStyle}>
          <Link to={item.link}>
            {item.icon}
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      )
    }
    return ''
  })
  return (
    <Layout.Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      className={classNames(styles.fixedSidebar, !collapsed && styles.width)}
    >
      <div className={styles.Logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={collapsed ? styles.expandMenu : styles.expandedMenu}>
        <Icon
          className={styles.trigger}
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggleSidebar}
        />
      </div>

      <Menu theme="dark" mode="inline" className={styles.mainMenu} selectedKeys={selectedKeys}>
        <Menu.Item
          key="InfluencerPool"
          className={classNames(styles.pointerCursor, styles.influencerPool)}
        >
          <Link to="/influencer-pool">
            <InfluencerPool />
            <span>Infl. Pool</span>
          </Link>
        </Menu.Item>
        {menuItems}
      </Menu>
    </Layout.Sider>
  )
}

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  projectId: PropTypes.string,
  toggleSidebar: PropTypes.func,
  clientId: PropTypes.string,
}

Sidebar.defaultProps = {
  collapsed: false,
  projectId: null,
  clientId: '',
  toggleSidebar: null,
}

export default Sidebar
