import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Input, Tooltip, notification } from 'antd'

import Dropdown from '../../SelectCriteria'
import { Checkmark } from '../../base/Checkmark'
import BorderedButton from '../../base/buttons/BorderedButton'
import IconButton from '../../base/buttons/IconButton'

import styles from './index.module.css'

const DROPDOWN_OPTIONS = [
  { value: 'USERFOLLOWER', text: 'USERFOLLOWER' },
  { value: 'USERFOLLOWING', text: 'USERFOLLOWING' },
  { value: 'LOCATION', text: 'LOCATION ID' },
  { value: 'USERTAGS', text: 'USERTAGS' },
  { value: 'HASHTAG', text: 'HASHTAG' },
  { value: 'POSTCOMMENTS', text: 'POST COMMENTS' },
  { value: 'POSTLIKES', text: 'POST LIKES' },
]
const SOURCE_TYPE = { USERNAME: 'USERNAME' }
const NUM_SRC_DISPLAYED = 20
const MANUAL_SOURCE_TEMPLATE = { name: '', type: SOURCE_TYPE.USERNAME, checked: true }

const sortByCheckedSources = sourceArr => {
  const sorted = [...sourceArr]
    .sort(src1 => {
      if (src1.checked) return -1
      return 1
    })
    .splice(0, NUM_SRC_DISPLAYED)

  return sorted
}

class Sources extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      matchUserProjectId: '',
      loaded: false,
      manualSource: {},
      dirty: false,
      sources: [],
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    const { customerList, sources, matchUserProjectId } = this.props
    const sortedSources = customerList
      ? sources.filter(src => src.checked)
      : sortByCheckedSources(sources)
    this.setState({
      matchUserProjectId,
      sources: sortedSources,
      loaded: true,
      manualSource: { ...MANUAL_SOURCE_TEMPLATE, matchUserProjectId },
    })
  }

  static getDerivedStateFromProps(newProps, oldState) {
    const { sources, customerList, matchUserProjectId } = newProps

    const newState = { ...oldState }
    const userChanged =
      oldState.matchUserProjectId && matchUserProjectId !== oldState.matchUserProjectId
    const sourcesChanged = sources.length !== oldState.sources.length
    if (userChanged || sourcesChanged) {
      const sortedSources = customerList
        ? sources.filter(src => src.checked)
        : sortByCheckedSources(sources)
      newState.manualSource = { ...MANUAL_SOURCE_TEMPLATE, matchUserProjectId }
      newState.sources = sortedSources
      newState.matchUserProjectId = matchUserProjectId
    }
    return newState
  }

  handleCheck = src => {
    const { sources } = this.state
    const idx = sources.findIndex(source => src.id === source.id)
    sources[idx].checked = !sources[idx].checked

    this.setState({ sources, dirty: true })
  }

  addManualSourceName = e => {
    const { value } = e.target
    const { manualSource } = this.state
    const newSource = { ...manualSource }
    newSource.name = value

    this.setState({ manualSource: newSource, dirty: true })
  }

  addManualSourceType = type => {
    const { manualSource } = this.state
    const newSource = { ...manualSource }
    let dirty = false
    newSource.type = type

    if (manualSource.name) dirty = true

    this.setState({ manualSource: newSource, dirty })
  }

  refreshSources = async () => {
    const { refreshSources, matchUserProjectId } = this.props
    try {
      await refreshSources(matchUserProjectId)
      notification.success({
        className: classNames(styles.notification, styles.success),
        duration: 3,
        message: 'Sources were refreshed',
        placement: 'topRight',
      })
    } catch (e) {
      notification.warning({
        className: classNames(styles.notification, styles.warning),
        duration: 4,
        message: 'The sources were not refreshed',
        placement: 'topRight',
      })
    }
  }

  saveSources = async () => {
    const { saveSources, matchUserProjectId } = this.props
    const { manualSource, sources } = this.state
    const { name } = manualSource

    if (name) {
      if (manualSource.type === SOURCE_TYPE.USERNAME) manualSource.name = name.toLowerCase()

      const srcIdx = sources.findIndex(
        item => item.name === name && item.type === manualSource.type
      )
      if (srcIdx !== -1) {
        sources[srcIdx].checked = true
      } else {
        sources.push(manualSource)
      }
    }

    try {
      await saveSources({ sources, matchUserProjectId })
      notification.success({
        className: classNames(styles.notification, styles.success),
        duration: 3,
        message: 'The sources were saved',
        placement: 'topRight',
      })
      this.inputRef.current.state.value = ''
      this.setState({ dirty: false })
    } catch (e) {
      notification.warning({
        className: classNames(styles.notification, styles.warning),
        duration: 4,
        message: 'The sources were not saved',
        placement: 'topRight',
      })
    }
    this.setState({ ...MANUAL_SOURCE_TEMPLATE, matchUserProjectId })
  }

  render() {
    const { loaded } = this.state

    if (!loaded) return <div>Loading...</div>

    const { customerList, addedManually } = this.props
    const { sources, dirty } = this.state

    return (
      <div className={styles.collapsibleSubcontainer}>
        {!customerList && (
          <div className={styles.instructionsContainer}>
            <p className={styles.sourceInstructions}>
              Select which sources to display in Customer List
            </p>
            {addedManually && (
              <Tooltip
                overlayClassName={styles.tooltip}
                placement="bottomLeft"
                arrowPointAtCenter
                title="Refresh sources (this only applies to manually added accounts)"
              >
                <div>
                  <IconButton
                    type="sync"
                    onClick={this.refreshSources}
                    className={styles.refreshButton}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
        <div className={styles.dataContainer}>
          <div className={classNames(styles.typeSubcol, styles.colTitle)}>Source Type:</div>
          <div className={classNames(styles.nameSubcol, styles.colTitle)}>Source Name:</div>
        </div>
        {sources.map(source => (
          <div key={`${source.id}_type_name`} className={styles.dataContainer}>
            <div key={`${source.id}_type`} className={styles.typeSubcol}>
              {source.type}
            </div>
            <div key={`${source.id}_name`} className={styles.nameSubcol}>
              {source.name}
              {!customerList && (
                <Checkmark
                  key={source.id + source.checked}
                  checked={source.checked}
                  onChange={() => {
                    this.handleCheck(source)
                  }}
                />
              )}
            </div>
          </div>
        ))}
        {!customerList && (
          <>
            <div className={styles.manualSourceTitle}>Add Source Manually</div>
            <div className={styles.dataContainer}>
              <div className={styles.typeSubcol}>Select Source Type:</div>
              <div className={styles.nameSubcol}>Enter Source Name:</div>
            </div>
            <div className={styles.dataContainer}>
              <div className={styles.typeSubcol}>
                <Dropdown
                  selectOptions={DROPDOWN_OPTIONS}
                  onChange={type => this.addManualSourceType(type)}
                  styleSelect="optionSelectSources"
                  defaultValue={DROPDOWN_OPTIONS[0].value}
                  styleDropdown="srcDropdown"
                />
              </div>
              <div className={styles.nameSubcol}>
                <Input
                  ref={this.inputRef}
                  placeholder="Source Name"
                  onChange={e => this.addManualSourceName(e)}
                  className={styles.input}
                />
              </div>
            </div>
          </>
        )}
        {!customerList && (
          <div className={styles.buttonContainer}>
            <BorderedButton
              text="Save"
              onClick={this.saveSources}
              className={styles.button}
              disabled={!dirty}
            />
          </div>
        )}
      </div>
    )
  }
}

Sources.propTypes = {
  sources: PropTypes.array.isRequired,
  customerList: PropTypes.bool.isRequired,
  addedManually: PropTypes.bool.isRequired,
  saveSources: PropTypes.func.isRequired,
  refreshSources: PropTypes.func.isRequired,
  matchUserProjectId: PropTypes.string.isRequired,
}
export default Sources
