import { ActionTypes } from '../actions/finalListTiktok'

const initialState = []

export const tiktokFinalList = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DATA:
      return action.payload.data

    case ActionTypes.TIKTOK_FINAL_LIST_ADD_ACCOUNT: {
      const newState = [...state]

      return [...newState, ...action.payload.accounts]
    }
    case ActionTypes.TIKTOK_FINAL_LIST_UPDATE_ACCOUNT: {
      const { username, ...field } = action.payload

      const fieldName = Object.keys(field)[0]
      const stateDummy = [...state]
      const idx = stateDummy.findIndex(item => {
        return item.username === username
      })
      const item = stateDummy[idx]
      item[fieldName] = field[fieldName]

      // if item was deleted, remove from state.
      if (action.payload.comoDecision) {
        item.decision = field[fieldName]
      }

      if (action.payload.deletedAt) {
        stateDummy.splice(idx, 1)

        return stateDummy
      }

      // if item was not deleted, update
      stateDummy.splice(idx, 1, item)

      return stateDummy
    }

    default:
      return state
  }
}
