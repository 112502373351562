import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import Avatar from '../Avatar/index'

import styles from './index.module.css'

function UserMenu({ username, logout }) {
  const menu = (
    <Menu style={{ padding: '5px', border: '1px solid rgba(255, 255, 255, 0.3)' }}>
      <Menu.Item key="0" className={styles.menuTitle} disabled>
        Welcome {username}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" className={styles.menuItem}>
        <Link to="/user">User Management</Link>
      </Menu.Item>
      <Menu.Item key="2" className={styles.menuItem}>
        <Link to="/categories">Manage Categories</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" className={styles.menuItem}>
        <a href="/" onClick={logout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar username={username} />
      </div>
    </Dropdown>
  )
}

UserMenu.propTypes = {
  username: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
}

export default UserMenu
