import React from 'react'
import classNames from 'classnames'

import styles from './index.module.css'

const prepPercentage = num => {
  return `${(num * 100).toFixed(2)}%`
}

const prepData = check => {
  let data = ''
  switch (check.criteria) {
    case 'AUDIENCE_COUNTRY': {
      const { countries } = check.wanted
      const gotCountriesRaw = check.got.countries
      const gotPercentage = prepPercentage(check.got.percentage)
      const wantedPercentage = prepPercentage(check.wanted.percentage)
      const wantedCountries = countries.map(country => (
        <div key={country} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {country}
        </div>
      ))
      const gotCountries = gotCountriesRaw.map(country => (
        <div key={country.name} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {country.name}
        </div>
      ))
      data = (
        <div className={styles.dataCol}>
          <div className={styles.dataSubcol}>
            <div className={styles.dataItem}>{wantedPercentage}</div>
            {wantedCountries}
          </div>
          <div className={styles.dataSubcol}>
            <div className={styles.dataItem}>{gotPercentage}</div>
            {gotCountries}
          </div>
        </div>
      )
      break
    }
    case 'AUDIENCE_GENDER': {
      const { gender } = check.wanted
      const gotGender = check.got.gender
      const gotPercentage = prepPercentage(check.got.percentage)
      const wantedPercentage = prepPercentage(check.wanted.percent)
      data = (
        <div className={styles.dataCol}>
          <div className={styles.dataSubcol}>
            <div className={styles.dataItem}>{wantedPercentage}</div>
            <div className={classNames(styles.dataItem, styles.dataItemColor)}>{gender}</div>
          </div>
          <div className={styles.dataSubcol}>
            <div className={styles.dataItem}>{gotPercentage}</div>
            <div className={classNames(styles.dataItem, styles.dataItemColor)}>{gotGender}</div>
          </div>
        </div>
      )
      break
    }
    case 'AUDIENCE_CITY': {
      const { cities } = check.wanted
      const wantedCities = cities.map(city => (
        <div key={city} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {city}
        </div>
      ))
      const wantedSlice = check.wanted.slice
      const gotCitiesRaw = check.got
      const gotCities = gotCitiesRaw.map(city => (
        <div key={city} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {city}
        </div>
      ))
      data = (
        <div className={styles.dataCol}>
          <div className={styles.dataSubcol}>
            {wantedCities}
            <div className={styles.dataItem}>In top {wantedSlice}</div>
          </div>
          <div className={styles.dataSubcol}>{gotCities}</div>
        </div>
      )
      break
    }
    case 'AUDIENCE_AGE': {
      const { ages } = check.wanted
      const gotAgesRaw = check.got.ages
      const gotPercentage = prepPercentage(check.got.percentage)
      const wantedPercentage = prepPercentage(check.wanted.percentage)
      const wantedAges = ages.map(age => (
        <div key={age} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {age} y.o.
        </div>
      ))
      const gotAges = gotAgesRaw.map(age => (
        <div key={age.code} className={classNames(styles.dataItem, styles.dataItemColor)}>
          {age.code} y.o.
        </div>
      ))
      data = (
        <div className={styles.dataCol}>
          <div className={styles.dataSubcol}>
            <div>{wantedPercentage}</div>
            {wantedAges}
          </div>
          <div className={styles.dataSubcol}>
            <div>{gotPercentage}</div>
            {gotAges}
          </div>
        </div>
      )
      break
    }
    default:
      return ''
  }
  return data
}

export default prepData
