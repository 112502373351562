import React from 'react'
import { Avatar } from 'antd'
import PropTypes from 'prop-types'

import styles from '../index.module.css'

const UserData = ({ userDetails: { profilePicture, data, linkToProfile, biography } }) => {
  // generate user details, if item does not exist, skip
  const dataPoints = Object.entries(data).map(([title, value]) => {
    if (!value) return <></>
    return (
      <div key={linkToProfile + title} className={styles.userDetails}>
        <div>{title}</div>
        <div className={styles.data}>{value}</div>
      </div>
    )
  })

  return (
    <div className={styles.section}>
      <a
        className={styles.linkToInstagram}
        href={linkToProfile}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.infoBoxHorizontal}>
          <Avatar src={profilePicture} className={styles.avatar} size={110} />
          <div className={styles.details}>{dataPoints}</div>
        </div>
        {biography && (
          <div className={styles.bioContainer}>
            <div>Biography</div>
            <div className={styles.bio}>{biography}</div>
          </div>
        )}
      </a>
    </div>
  )
}

UserData.propTypes = {
  userDetails: PropTypes.object,
}

UserData.defaultProps = {
  userDetails: {},
}

export default UserData
