/* eslint-disable react/display-name */
import styles from '../index.module.css'

const EngagementScore = () => {
  return {
    title: 'Eng. Score',
    dataIndex: 'engagementScore',
    key: 'engagement',
    className: styles.engScore,
    width: '90px',
    sorter: (first, last) => first.engagementScore - last.engagementScore,
  }
}

export default EngagementScore
