import React from 'react'
import { PieChart as Chart, Pie, Cell, Legend } from 'recharts'
import PropTypes from 'prop-types'

import styles from '../charts.module.css'

const COLORS = ['#fff', '#f06']

const RADIAN = Math.PI / 180

const PieChart = ({ className, data }) => {
  if (data.length === 0) {
    return <div className={styles.noDataPlaceholder}> NO DATA </div>
  }
  // eslint-disable-next-line
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="#15181c"
        fontWeight="bold"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  return (
    <Chart width={150} height={170} className={className}>
      <Pie
        data={data}
        cx={60}
        cy={60}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          // eslint-disable-next-line
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend align="left" layout="horizontal" />
    </Chart>
  )
}
PieChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
}

PieChart.defaultProps = {
  className: '',
}
export default PieChart
