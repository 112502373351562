import React from 'react'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import UserMenu from '../containers/base/UserMenu'
import { BigLogo } from '../components/base/Logo'
import Breadcrumb from '../components/base/Breadcrumb'

import styles from './Header.module.css'

function HeaderView({ isCustomer, clientName, clientId, projectName, projectId }) {
  return (
    <Layout.Header className={isCustomer ? styles.fixedHeaderCustomerList : styles.fixedHeader}>
      <div className={styles.logo}>{isCustomer && <BigLogo />}</div>
      {!isCustomer && (
        <div className={styles.breadcrumb}>
          <Breadcrumb
            clientName={clientName}
            clientId={clientId}
            projectName={projectName}
            projectId={projectId}
          />
        </div>
      )}
      <div className={styles.MenuRight}>
        <UserMenu />
      </div>
    </Layout.Header>
  )
}

HeaderView.propTypes = {
  isCustomer: PropTypes.bool,
  clientName: PropTypes.string,
  clientId: PropTypes.string,
  projectName: PropTypes.string,
  projectId: PropTypes.string,
}
HeaderView.defaultProps = {
  isCustomer: false,
  clientId: '',
  clientName: '',
  projectName: '',
  projectId: '',
}

export default HeaderView
