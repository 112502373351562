import React from 'react'

import PropTypes from 'prop-types'
import className from 'classnames'
import styles from './index.module.css'

const StatusIndicator = ({ style }) => (
  <div alt="logo" className={className(styles.online, style)} />
)

StatusIndicator.propTypes = {
  style: PropTypes.string,
}

StatusIndicator.defaultProps = {
  style: null,
}
export default StatusIndicator
