import React from 'react'
import PropTypes from 'prop-types'

import { Select as AntSelect } from 'antd'

import './index.css'
import styles from './index.module.css'

const { Option } = AntSelect

const DROPDOWN_OPTIONS = [
  { value: 'USERNAME', text: 'USERNAME' },
  { value: 'USERTAGS', text: 'USERTAGS' },
  { value: 'LOCATION', text: 'LOCATION ID' },
  { value: 'HASHTAG', text: 'HASHTAG' },
  { value: 'POSTCOMMENTS', text: 'POST COMMENTS' },
  { value: 'POSTLIKES', text: 'POST LIKES' },
]

class Select extends React.PureComponent {
  render() {
    const {
      onChange,
      disabled,
      defaultValue,
      styleSelect,
      styleDropdown,
      selectOptions,
    } = this.props
    return (
      <AntSelect
        onChange={onChange}
        disabled={disabled}
        className={disabled ? styles.disabled : styleSelect}
        defaultValue={defaultValue}
        dropdownClassName={styleDropdown}
      >
        {selectOptions.map(data => (
          <Option key={data.value} value={data.value}>
            {data.text}
          </Option>
        ))}
      </AntSelect>
    )
  }
}

Select.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  styleSelect: PropTypes.string,
  styleDropdown: PropTypes.string,
  selectOptions: PropTypes.string,
}

Select.defaultProps = {
  onChange: null,
  disabled: false,
  defaultValue: 'USERNAME',
  styleSelect: 'optionSelect',
  styleDropdown: '.normalDropdown',
  selectOptions: DROPDOWN_OPTIONS,
}

export default Select
