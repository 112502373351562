import { ActionTypes } from '../actions/selectedProject'

const initialState = {}
export const projectStatusQuo = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.STATUS_QUO_FETCH:
      return { ...action.payload }
    default:
      return state
  }
}
