/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'
import { Button, Icon } from 'antd'

import styles from '../index.module.css'

const FinalCall = ({ drawerOpen, savingDecision, finalCall, isReadOnly }) => {
  const sortDecisions = (first, last) => {
    if (first.decision === 'YES' || last.decision === 'NO') {
      return -1
    }
    return 1
  }

  // Provides correct classname to NO button depending on which Final List they are used at
  const noButtonClassName = decision => {
    let style
    if (decision !== 'NO') {
      style = classNames(styles.no)
      return style
    }
    style = classNames(styles.noClicked)
    return style
  }

  // Provides correct classname to YES button depending on which Final List they are used at
  const yesButtonClassName = decision => {
    let style
    if (decision !== 'YES') {
      style = classNames(styles.yes)
      return style
    }
    style = classNames(styles.yesClicked)
    return style
  }

  return {
    title: <div className={styles.finalCallHeader}>Final Call</div>,
    key: 'finalCall',
    render: row => {
      if (row.loading) {
        return <span />
      }
      return (
        <div className={styles.buttons}>
          <Button
            className={classNames(styles.votesButton, yesButtonClassName(row.decision))}
            onClick={
              isReadOnly || savingDecision
                ? null
                : event => finalCall(row.decision === 'YES' ? 'UNDECIDED' : 'YES', row, event)
            }
          >
            {savingDecision ? <Icon type="sync" spin /> : 'yes'}
          </Button>
          <Button
            className={classNames(styles.votesButton, noButtonClassName(row.decision))}
            onClick={
              isReadOnly || savingDecision
                ? null
                : event => finalCall(row.decision === 'NO' ? 'UNDECIDED' : 'NO', row, event)
            }
          >
            {savingDecision ? <Icon type="sync" spin /> : 'no'}
          </Button>
        </div>
      )
    },
    className: drawerOpen ? styles.finalCallDrawerOpen : styles.finalCall,
    sorter: (first, last) => sortDecisions(first, last),
  }
}

export default FinalCall
