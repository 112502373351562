import { ActionTypes } from '../actions/firstCheck'
import { ActionTypes as SelectedProjectActionTypes } from '../actions/selectedProject'

const initialState = {}
export const identificationStatusFirst = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIRST_CHECK_ADD_MORE_ACCOUNTS:
      return { ...state, ...action.payload.status }
    case ActionTypes.FIRST_CHECK_ACCOUNT_DECISION:
      return { ...initialState, ...action.payload.status }
    case SelectedProjectActionTypes.DESELECT_PROJECT:
      return { ...initialState }

    default:
      return state
  }
}
