import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import prepData from './util'
import styles from './index.module.css'

const FailedChecks = ({ checks }) => {
  const failedChecks = checks && checks.filter(check => !check.passed)
  return (
    <div className={styles.collapsibleSubcontainer}>
      <div className={styles.dataContainer}>
        <div className={styles.dataName} />
        <div className={styles.dataCol}>
          <div className={classNames(styles.dataSubcol, styles.colTitle)}>Wanted:</div>
          <div className={classNames(styles.dataSubcol, styles.colTitle)}>Got:</div>
        </div>
      </div>
      {failedChecks.map(check => (
        <div key={check.criteria} className={styles.dataContainer}>
          <div className={styles.dataName}>{check.criteria}:</div>
          {prepData(check)}
        </div>
      ))}
    </div>
  )
}

FailedChecks.propTypes = {
  checks: PropTypes.array.isRequired,
}
export default FailedChecks
